<template>
  <div>
    <app-bar-simple :title="$store.state.paths[$route.path].title"></app-bar-simple>
    <v-container fluid>
      <!-- Autocomplete -->
      <v-toolbar dark color="blue darken-4" dense>
        <v-toolbar-title>Prosjekt</v-toolbar-title>
        <v-autocomplete
          class="mx-4"
          dense
          hide-no-data
          hide-details
          label="Velg prosjekt"
          solo-inverted
          :items="autocompleteitems"
          :filter="customFilter"
          color="black"
          v-model="projectid"
        ></v-autocomplete>
        <v-btn
          raised
          :disabled="projectid == null"
          @click="hentProsjekt"
          color="blue"
          class="mr-2"
          >Hent prosjekt<v-icon right light
            >mdi-cursor-default-click</v-icon
          ></v-btn
        >
      </v-toolbar>

      <!-- Tabs -->
      <v-card class="mt-3">
        <v-tabs
          v-model="tab"
          background-color="primary"
          dark
          slider-size="5"
          show-arrows
        >
          <v-tabs-slider color="purple lighten-3"></v-tabs-slider>
          <v-tab :href="`#Tilbud`">Tilbud</v-tab>
          <v-tab :href="`#Kundetilfredshet`">Kundetilfredshet</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" v-if="gridready">
          <v-tab-item :key="0" value="Tilbud" class="pb-1">
            <v-card class="mx-auto my-4" max-width="600">
              <v-img
                height="250"
                src="/photo-1473073899705-e7b1055a7419.jpg"
              ></v-img>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="headline"
                    >Tilbudet</v-list-item-title
                  >
                  <v-divider></v-divider>
                  <v-list-item-subtitle
                    >Informasjon fra tilbudsbase</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list class="transparent">
                <v-list-item
                  v-for="item in projectHeader"
                  :key="item.field"
                  style="min-height: 26px"
                >
                  <v-list-item-subtitle>{{ item.title }}</v-list-item-subtitle>
                  <v-list-item-title class="text-left">{{
                    item.value
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="1" value="Kundetilfredshet">
            <v-sheet elevation="1" class="pa-4">
              <v-row>
                <v-col>
                  <v-card>
                  <v-card-title>På en skala fra 0-10<br>Hvor sannsynlig er det at du vil anbefale {{ this.$store.state.activecompanyname }}<br>til en venn eller kollega?</v-card-title>
                  <v-card-text>
                  <v-btn-toggle v-model="score" color="purple" background-color="purple lighten-5" mandatory>
                    <v-btn>0</v-btn>
                    <v-btn>1</v-btn>
                    <v-btn>2</v-btn>
                    <v-btn>3</v-btn>
                    <v-btn>4</v-btn>
                    <v-btn>5</v-btn>
                    <v-btn>6</v-btn>
                    <v-btn>7</v-btn>
                    <v-btn>8</v-btn>
                    <v-btn>9</v-btn>
                    <v-btn>10</v-btn>
                  </v-btn-toggle>
                  <v-textarea class="mt-3" outlined name="input-7-4" label="Vil du oppgi årsak til poengsum"></v-textarea>              
                  <v-textarea outlined name="input-7-4" label="Hvordan kan vi gjøre opplevelsen din bedre"></v-textarea>              
                  <div>{{ score }}</div>
                  </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card>
                    <v-card-title>Kundens navn, kontaktpersoner, sist forespurt, kontakt forespurt.</v-card-title>
                    <v-card-text>
                      Alle prosjekter som er ferdigstilt får spørsmål (tilbake til januar 2021). Kunder på anlegg og xylan forespørres. Den som ringer bør ha anonymt forhold til kunden (Nic, Marianne).
                      https://hbr.org/2003/12/the-one-number-you-need-to-grow
                      https://www.netpromoter.com/know/
                      https://www.netigate.net/no/articles/kundetilfredshet/slik-fungerer-net-promoter-score-og-hvorfor-det-er-en-god-metode-a-male-kundelojalitet/
                      https://en.wikipedia.org/wiki/Net_Promoter
                      https://www.qualtrics.com/uk/experience-management/customer/net-promoter-score/?rid=ip&prevsite=en&newsite=uk&geo=NO&geomatch=uk
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-sheet>
          </v-tab-item>

        </v-tabs-items>
      </v-card>

      <!-- ****************** -->

      <!-- https://www.jenniferbland.com/how-to-add-charts-and-graphs-to-a-vue-application/ -->
    </v-container>
  </div>
</template>

<style scoped>
  .tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row:nth-child(even) {
    background-color: #ede7f6;
  }
</style>
<style lang='scss'>
@import "@/assets/tabulator_simple_gd";
</style>

<script>
/*eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import requestData from "@/mixins/requestData.js";
import appBarSimple from "@/components/AppBarSimple.vue";

export default {
  mixins: [requestData],
  components: {
    "app-bar-simple": appBarSimple,
  },
  watch: {
    projectid: function (val, oldVal) {
      if (val !== oldVal) {
        this.hentetProsjekt = false;
        this.projectHeader = {};
        this.gridready = false;
        this.widgets = {};
      }
    },
  },
  mounted: function () {
    this.$options.sockets.onmessage = function (msg) {
      //console.log("received in component: ", msg.data)
      var obj = JSON.parse(msg.data);
      if (obj.status !== "ok") {
        this.$showMessage({ content: obj.status, color: "error"})
      }
    };

    var self = this;
    this.requestJSON2('/api/project/component', { id: "bqcbqsjipt3aimtdrtp0", scope: "keyval",})
    .then(function(e) { self.autocompleteitems = e[Object.keys(e)[0]]});      
  },
  beforeDestroy: function () {
    delete this.$options.sockets.onmessage;
  },
  data() {
    return {
      score: null,
      // Autocomplete search
      autocompleteitems: [],

      // Prosjektdetaljer
      hentetProsjekt: false,
      projectHeader: {},

      // Tabs
      tab: null,

      // Selected project
      projectid: null,

      // Page
      gridready: false,

      // Tabulator
      widgets: {},
    };
  },
  methods: {
    customFilter(item, queryText) {
      const textOne = item.text.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    hentProsjekt() {
      
      var self = this;
      this.requestJSON2('/api/project/component', { id: "bqcbqsjipt3aimtdrtp0", scope: "project", projectid: this.projectid})
      .then(function(e) {
        self.projectHeader = e[Object.keys(e)[0]]
        self.hentetProsjekt = true;
        self.requestTabulator({ projectid: self.projectid });
      });
       
    },
  },
};

</script>