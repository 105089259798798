<template>
  <div>
    <v-container fluid>
      <v-card
        class="mx-auto"
        style="
          background: rgba(255, 255, 255, 0.5);
          backdrop-filter: blur(8px);
          display: block;
          overflow: auto;
        "
      >
        <ToolbarLeftNavTitle :title="$store.state.paths[$route.path].title" />

        <v-sheet class="mx-2 mb-2" style="background: transparent">
          <!-- Chips -->
          <v-row no-gutters>
            <v-chip-group
              active-class="deep-purple white--text"
              v-model="selectedCategory"
              class=""
            >
              <v-chip
                label
                color="#E1BEE7"
                v-for="tag in equipmentCategory"
                :key="tag"
                :value="tag"
              >
                {{ tag }}
              </v-chip>
            </v-chip-group>
          </v-row>

          <v-row no-gutters>
            <!-- First col -->
            <v-col cols="3">
              <v-card class="ma-1" flat>
                <v-card-title class="grey--text text--darken-1 py-2">{{
                  selectedCategory
                }}</v-card-title>
                <v-divider></v-divider>
                <v-list dense>
                  <v-list-item-group v-model="selectedItem" color="primary">
                    <v-list-item
                      v-for="(item, i) in selectedEquipment"
                      :key="i"
                      @click="getitem(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>

            <!-- Second col -->
            <v-col cols="4">
              <v-card class="ma-1" flat>
                <v-card-title class="grey--text text--darken-1 py-2">{{
                  selectedText
                }}</v-card-title>
                <v-divider></v-divider>
                <v-img v-if="imageShow" :src="imageSrc" @error="imageLoadError"></v-img>
                <v-card-text v-if="equipmentid">
                  <v-row>
                    <v-col>
                      ID: {{ extractObjectKeyValue("equipment", "shortid") }}
                      <br />
                      Beskrivelse:
                      {{ extractObjectKeyValue("equipment", "description") }}
                      <br />
                      Serienr:
                      {{ extractObjectKeyValue("equipment", "serialno") }}
                      <br />
                      Kategori:
                      {{ extractObjectKeyValue("equipment", "category") }}
                      <br />
                      Underkategori:
                      {{ extractObjectKeyValue("equipment", "subcategory") }}
                      <br />
                      Fast lokasjon:
                      {{ extractObjectKeyValue("equipment", "location") }}
                      <br />
                      Leverandør:
                      {{ extractObjectKeyValue("equipment", "sup_id") }} <br />
                    </v-col>
                    <v-col>
                      Årsmodell:
                      {{ extractObjectKeyValue("equipment", "yearmodelint") }}
                      <br />
                      Dimensjoner:
                      {{
                        extractObjectKeyValue("equipment", "attr_dimensions")
                      }}
                      <br />
                      Kilowatt:
                      {{ extractObjectKeyValue("equipment", "attr_kwint") }}
                      <br />
                      Kubikk:
                      {{ extractObjectKeyValue("equipment", "attr_m3int") }}
                      <br />
                      Volt:
                      {{ extractObjectKeyValue("equipment", "attr_voltage") }}
                      <br />
                      Vekt:
                      {{
                        extractObjectKeyValue("equipment", "attr_weightint")
                      }}
                      <br />
                    </v-col>
                  </v-row>
                </v-card-text>
                <div v-if="showUpload">
                  <upload-image section="equipment" autoreload="false" title="Mangler bilde - laste opp?" :id="equipmentid" />
                </div>
              </v-card>
            </v-col>

            <!-- Third col -->
            <v-col cols="5">
              <v-card class="ma-1" flat>
                <div v-for="widget in layout" :key="widget.i" class="px-2">
                  <tabulator-edit
                    :equipmentid="equipmentid"
                    :wid="widget.i"
                    :widget="widgets[widget.i]"
                    :options="fromDataObj(widget.i)"
                  />
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-sheet>
      </v-card>
    </v-container>
  </div>
</template>

<style scoped>
</style>

<script>
/*eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import requestData from "@/mixins/requestData.js";
import TabulatorEditComponent from "@/components/TabulatorEditComponent.vue";
import UploadImage from "@/components/UploadImage.vue";

export default {
  mixins: [requestData],
  components: {
    "tabulator-edit": TabulatorEditComponent,
    "upload-image": UploadImage,
  },
  data() {
    return {
      //loading: false,
      imageSrc: "",
      imageShow: false,
      widgets: {},
      items: [],
      selectedItem: null,
      selectedCategory: undefined,
      selectedEquipment: [],
      selectedText: "",
      equipment: {},
      equipmentid: null,
      showUpload: false,
    };
  },
  computed: {
    equipmentCategory() {
      return this.uniqueCategoryByClassification("category");
    },
  },
  watch: {
    selectedCategory: function (val, oldVal) {
      if (val === undefined) {
        this.reset();
        return;
      }
      if (val !== oldVal) {
        this.reset();
        var newArray = this.items.filter(function (el) {
          return el.category == val;
        });
        this.selectedEquipment = newArray;
      }
    },
  },
  created: function () {
    this.layout =
      JSON.parse(
        JSON.stringify(this.$store.getters.navbylink(this.$route.path).layout)
      ) || [];
  },
  mounted: function () {
    this.$options.sockets.onmessage = function (msg) {
      var obj = JSON.parse(msg.data);
      //this.loading = false;
      if (obj.status !== "ok") {
        this.$showMessage({ content: obj.status, color: "error" });
      }
    };
    this.getItems();
  },
  beforeDestroy: function () {
    delete this.$options.sockets.onmessage;
  },
  methods: {
    getitem(item) {
      //this.imageSrc = "/api/imagedownload?company=bqf0kajipt37o10v29jg&id=fsdfcxklvjxlkvjxc&scope=equipment"
      this.showUpload = false;
      this.imageShow = true
      this.imageSrc =
        "/api/imagedownload?company=" +
        this.$store.state.activecompany +
        "&id=" +
        item.value +
        "&scope=equipment";
      var self = this;
      setTimeout(function () {
        if (self.selectedItem === undefined) {
          self.reset(true);
          return;
        }
        self.equipmentid = item.value;
        self.selectedText = item.text;
        self
          .requestJSON2("/api/equipment/component", {
            id: "c29uihmcie6uhkkl6hk0",
            scope: "equipment",
            equipmentid: item.value,
          })
          .then(function (e) {
            var data = e[Object.keys(e)][0];
            self.equipment = data;
          });
        self.requestTabulator({ equipmentid: item.value });
      }, 100);
    },
    uniqueCategoryByClassification(field) {
      const array = this.items;
      const result = [];
      const map = new Map();
      for (const item of array) {
        if (!map.has(item[field])) {
          map.set(item[field], true); // set any value to Map
          result.push(item[field]);
        }
      }
      return result;
    },
    getItems: function () {
      var self = this;
      this.requestJSON2("/api/equipment/component", {
        id: "c29uihmcie6uhkkl6hk0",
        scope: "keyval",
      }).then(function (e) {
        self.items = e[Object.keys(e)[0]] || [];
      });
    },
    reset: function (part = false) {
      this.widgets = {};
      this.equipment = {};
      this.equipmentid = null;
      this.selectedItem = null;
      this.selectedText = "";
      this.imageSrc = "";
      this.imageShow = false;
      this.showUpload = false;
      if (part !== true) {
        this.selectedEquipment = [];
      }
    },
    imageLoadError() {
      var self = this;
      setTimeout(function () {
        if (self.equipmentid !== null) {
          console.log("Image failed to load");
          self.showUpload = true;
          self.imageShow = false
        } else {
          console.log("Image loaded");
          self.showUpload = false;
          self.imageShow = true
        }
      }, 200);
    },
  },
};
</script>