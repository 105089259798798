<template>
  <div>
    <div v-if="progressInfos">
      <div
        class="mb-2"
        v-for="(progressInfo, index) in progressInfos"
        :key="index"
      >
        <span>{{ progressInfo.fileName }}</span>
        <v-progress-linear
          v-model="progressInfo.percentage"
          color="light-blue"
          height="25"
          reactive
        >
          <strong>{{ progressInfo.percentage }} %</strong>
        </v-progress-linear>
      </div>
    </div>

    <v-row no-gutters justify="center" align="center">
      <v-col cols="8">
        <v-file-input
          accept="image/*"
          multiple
          show-size
          label="Select Images"
          @change="selectFiles"
        ></v-file-input>
      </v-col>

      <v-col cols="4" class="pl-2">
        <v-btn color="success" dark small @click="uploadFiles">
          Upload
          <v-icon right dark>mdi-cloud-upload</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-alert
      v-if="message"
      border="left"
      color="teal"
      outlined
      class="multi-line"
    >
      {{ message }}
    </v-alert>

    <v-card v-if="fileInfos.length > 0" class="mx-auto">
      <v-list>
        <v-subheader>List of Images</v-subheader>
        <v-list-item-group color="primary">
          <v-list-item v-for="(file, index) in fileInfos" :key="index">
            <v-list-item-content>
              <v-list-item-title class="mb-3">
                <a :href="file.url">{{ file.name }}</a>
              </v-list-item-title>
              <v-list-item-subtitle>
                <img :src="file.url" :alt="file.name" height="80px" />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<script>
// https://bezkoder.com/vuetify-multiple-image-upload/
//import UploadService from "../services/UploadFilesService";
export default {
  //name: "upload-files",
  data() {
    return {
      selectedFiles: undefined,
      progressInfos: [],
      message: "",
      fileInfos: [],
      currentFile: undefined,
      progress: 0,
      id: 'sdfkljsfek23kjds', // replace with props from parent ..
      section: 'project' // replace with props from parent ..
    };
  },
  methods: {
    selectFiles(files) {
      this.progressInfos = [];
      this.selectedFiles = files;
    },
    uploadFiles() {
      this.message = "";

      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.upload(i, this.selectedFiles[i]);
      }
    },
    upload(idx, file) {
      this.progressInfos[idx] = { percentage: 0, fileName: file.name };

      this.FilesServiceUpload(file, (event) => {
        this.progressInfos[idx].percentage = Math.round(
          (100 * event.loaded) / event.total
        );
      })
        .then((response) => {
          let prevMessage = this.message ? this.message + "\n" : "";
          this.message = prevMessage + response.data.message;
          //return this.FilesServiceGetFiles();
          return
        })
        .then((files) => {
          this.fileInfos = files.data;
        })
        .catch(() => {
          this.progressInfos[idx].percentage = 0;
          this.message = "Could not upload the file:" + file.name;
        });
    },

    FilesServiceUpload(file, onUploadProgress) {

      let formData = new FormData();
      
      // Company ID
      if (this.$store.state.activecompany.length < 10) {
        this.$showMessage({
          content: "Feil - mangler selskap-id",
          color: "error",
        });
        return;
      } else {
        formData.append("company", this.$store.state.activecompany);
      }

      // Object ID
      if (this.id.length < 10) {
        this.$showMessage({
          content: "Feil - mangler objekt-id",
          color: "error",
        });
        return;
      } else {
        formData.append("id", this.id);
      }

      // Seksjon
      if (this.section.length < 3) {
        this.$showMessage({
          content: "Feil - mangler seksjon",
          color: "error",
        });
        return;
      } else {
        formData.append("scope", this.section);
      }

      // File
      formData.append("file", file);

      return this.axios.post("/api/imageupload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      });
    },
    FilesServiceGetFiles() {
      return this.axios.get("/files");
    },
  },
  mounted() {
    /*
    this.FilesServiceGetFiles().then((response) => {
      this.fileInfos = response.data;
    });
    */
  },
};
</script>