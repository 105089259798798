<template>
    <v-simple-table fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="(item, y) in options"
              :key="y"
              :class="'text-' + item.align"
              style="max-width: 250px"
            >
              {{ item.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in items_arranged" :key="i">
            <td>{{ item.h1 }}</td>
            <td>
              <v-chip v-if="item.h2 != ''" class="ma-1" color="purple" outlined small>{{
                item.h2
              }}</v-chip>
            </td>
            <td>
              <span v-for="(elem, i2) in item.ar" :key="i2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      :color="getColor(elem.color)"
                      label
                      small
                      style="margin: 2px; height: 40px; font-weight: 700"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ elem.ah1 }}<br />
                      {{ elem.ah2 }}
                    </v-chip>
                  </template>
                  <span v-html="elem.at1"></span>
                </v-tooltip>
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
    },
    options: {
      type: Array,
    },
  },
  methods: {
    getColor(color) {
      if (color === undefined || color === "") {
        return "green lighten-1";
      } else {
        return color + " lighten-1";
      }
    },
  },
  computed: {
    headers() {
      return this.options;
    },
    items_arranged() {
      // Get unique items and set up array
      var ia = {};
      if (this.data !== undefined && this.data.length > 0) {
        this.data.forEach(function (item) {
          if (!ia.hasOwnProperty(item.xid)) {
            ia[item.xid] = { ar: [] };
          }
          ia[item.xid].h1 = item.h1;
          ia[item.xid].h2 = item.h2;
          ia[item.xid].ar.push({
            ah1: item.ah1,
            ah2: item.ah2,
            at1: item.at1,
            color: item.color,
          });
        });
      }
      return ia;
    },
  },
};
</script>