<template>
  <div ref="parentcontainer" style="width: 100%; height: 100% position: relative">

    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" :height="dataparser.svgheight" >
      <!--:height="svgheight" style="border: 1px solid black" -->
      <defs>
        <marker id="triangle" viewBox="0 0 10 10" refX="1" refY="5" markerUnits="strokeWidth" markerWidth="10" markerHeight="10" orient="auto">
          <path d="M 0 0 L 10 5 L 0 10 z" fill="#000" />
        </marker>
      </defs>

      <!-- Title -->
      <g v-if="heading1" text-anchor="left" dominant-baseline="middle">
        <text style="font-weight: bold; font-size: 20px" :x="defaults.title.x" :y="defaults.title.y">
          {{ heading1 }}
        </text>
      </g>

      <!-- Header -->
      <g text-anchor="middle" dominant-baseline="middle">
        <!-- Rect full <rect :width="item.width" height="18" :y="item.y - 14" rx="3" :x="defaults.title.x" style="fill: rgba(239, 83, 80, 0.2);" stroke="rgba(239, 83, 80, 0.5)" stroke-width="1"> -->
        <text :x="defaults.textblock.daystart" :y="defaults.textblock.offsetTop - 25">Dg</text>
        <text :x="defaults.drawing.startX+20" :y="defaults.textblock.offsetTop - 25">{{ $formatDate(minmaxdate.min, 'dd.mm') }}</text>
        <text :x="this.defaults.drawing.startX + (this.defaults.dayscale * this.minmaxdate.days) + 20" :y="defaults.textblock.offsetTop - 25">{{ $formatDate(minmaxdate.max, 'dd.mm') }}</text>
      </g>

      <!-- Gridlines -->
      <!--
      <template v-for="(item, index2) in dates">
      <g :key="index2" >
        <line :x1="defaults.drawing.startX + (defaults.dayscale * index2)" y1="0" :x2="defaults.drawing.startX + (defaults.dayscale * index2)" y2="618" stroke="#EEEEEE" stroke-width="1" />
        <text  :x="defaults.drawing.startX + (defaults.dayscale * index2)" :y="defaults.textblock.offsetTop - 25">{{ item }}</text>
      </g>
      </template>
      -->

        <template v-for="(item, index) in dataparser.tasks">
          <g :key="index">

          <!-- Workpackages -->
          <template v-if="item.wpkg">
            <!-- Divider ln --><line x1="3" :y1="item.y - 17" :x2="defaults.drawing.startX + defaults.drawing.widthX" :y2="item.y - 17" stroke="#E0E0E0"></line>
            <!-- Text title --><text :x="item.x" :y="item.y"><tspan font-weight="700" fill="#424242" font-size="14">{{ item.title }}</tspan></text>
            <!-- Text days  --><text text-anchor="middle" v-if="item.days" :y="item.y" :x="defaults.textblock.daystart">{{ item.days }}</text>
            <!-- Rect full --><rect :width="item.width" :height="defaults.textblock.linespace - 6" :y="item.y - 14" rx="3" :x="item.start" style="fill: rgba(239, 83, 80, 0.4);" stroke="rgba(239, 83, 80, 0.5)" stroke-width="1"></rect><!--<title>{{ item.dataTooltip }}</title>-->            
            <!-- Text progress --><text v-if="item.days" :y="item.y" :x="item.start + 6">..</text>
          </template>

          <!-- Tasks -->
          <template v-if="item.task">
            <!-- Text title    --><text :x="item.x" :y="item.y">{{ item.title }}</text>
            <!-- Text days     --><text text-anchor="middle" v-if="item.days" :y="item.y" :x="defaults.textblock.daystart">{{ item.days }}</text>
            <!-- Rect full     --><rect :width="item.width" :height="defaults.textblock.linespace - 6" :y="item.y - 14" rx="3" :x="item.start" style="fill: rgba(0, 188, 212, 0.4);" ></rect>
            <!-- Rect progress --><rect :width="0.5 * item.width" :height="defaults.textblock.linespace - 6" :y="item.y - 14" rx="3" :x="item.start" style="fill: rgba(0, 188, 212, 0.8);"></rect>

            <!-- Line prog. end  -->
            <!-- <line :x1="(item.start + item.progress / item.count * item.width) || 0" :y1="item.y - 14" :x2="(item.start + item.progress / item.count * item.width) || 0" :y2="item.y + 4" stroke="red"></line>-->
            <!-- Text progress   --><!--<text text-anchor="end" :x="item.start - 5 + item.progress / item.count * item.width" :y="item.y ">{{ Math.round(item.progress / item.count * 100 * 10) / 10  }} %</text>-->
            <!-- Text full end   --><text :x="item.start + item.width + 10" :y="item.y"><tspan fill="grey" font-size="0.9em">{{ $formatDate(item.date_from, 'dd.mm') }} - {{ $formatDate(item.date_to, 'dd.mm') }}</tspan><tspan font-weight="700">{{ item.count }}</tspan> {{ item.measure }}</text>
          </template>

      </g>
        </template>

    </svg>
    <div v-if="debug">
      <v-divider></v-divider>
      <code style="font-size: 0.8em">
        {{ translate }} - {{ translate.date_from }}<br />
      </code>
      <v-divider></v-divider>
      <code style="font-size: 0.8em" v-for="(item, i1) in task" :key="i1">
        {{ item }}<br />
      </code>
      <v-divider></v-divider>
    </div>
  </div>
</template>

<style scoped>
</style>

<script>
export default {
  props: {
    translate: { type: Object },
    task: { type: Array },
    measure: { type: Array },
    minmaxdate: { type: Object },
    heading1: { type: String },
    initwidth: {type: Number}
  },
  mounted: function() {
    //this.svgheight = this.initheight
    //this.defaults.drawing.widthX = this.$refs.parentcontainer.clientWidth-this.defaults.drawing.startX;
    this.defaults.drawing.widthX = this.initwidth - this.defaults.drawing.startX - 70 || 800;
  },
  watch: {
    minmaxdate: function() {
      // watch it
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.defaults.dayscale = isFinite(this.defaults.drawing.widthX / this.minmaxdate.days) ? this.defaults.drawing.widthX / this.minmaxdate.days : 0;
      this.dates = []
      const d = new Date(this.minmaxdate.min);
      for (let i = 0; i < this.minmaxdate.days; i++) {
        this.dates.push(d.setDate(d.getDate() + i))
      }
    }
  },
  computed: {
    dataparser: function() {

      //console.log("task::");
      //console.log(this.task);
      /*
      console.log(this.translate);
      console.log("Key exists:", checkNested(this.translate, 'wpkg', 'key'));
      */

      // Check if translate keys are used
      const hasWpkg = checkNested(this.translate, 'wpkg', 'key')
      const hasTask = checkNested(this.translate, 'task', 'key')

      var ret = { tasks: [], svgheight: 0 };
      var tasks = [];
      var arrmap = {};

      if (this.task === undefined) {
        return ret;
      }

      // Add tasks to array - based on translate values
      for (let i = 0; i < this.task.length; i++) {
        const isWpkg = hasWpkg && this.task[i][this.translate.wpkg.key] === this.translate.wpkg.val ? true : false
        const isTask = hasTask && this.task[i][this.translate.task.key] === this.translate.task.val ? true : false
        
        // Create a map with task ID
        if (isWpkg) {arrmap[this.task[i].xid] = { orderint: this.task[i].orderint };}

        tasks.push({
          wpkg: isWpkg,
          task: isTask,
          days: this.$func.NumberOfDays(
            this.task[i][this.translate.date_from],
            this.task[i][this.translate.date_to]
          ) || 0,
          date_from: this.task[i][this.translate.date_from],
          date_to: this.task[i][this.translate.date_to],
          title: this.task[i][this.translate.title],
          id: this.task[i].xid,
          orderint: this.task[i].orderint,
          parent: hasTask ? this.task[i][this.translate.task.parent] : undefined,
        });
      }

      // First sort by sort orderint
      for (let i = 0; i < tasks.length; i++) {
          tasks[i].srt = tasks[i].wpkg ? String(tasks[i].orderint).padStart(2, '0') + '-' + tasks[i].id : String(arrmap[tasks[i].parent].orderint).padStart(2, '0') + '-' + tasks[i].parent + '-' + String(tasks[i].orderint).padStart(2, '0')
      }
      tasks.sort((a,b)=> (a.srt > b.srt ? 1 : -1))



      // Calc position for tasks
      var ypos = this.defaults.textblock.offsetTop;
      for (let i = 0; i < tasks.length; i++) {
        tasks[i].x = tasks[i].task ? this.defaults.textblock.indent + this.defaults.textblock.taskLeftPad : this.defaults.textblock.indent;
        tasks[i].y = ypos;
        if ( this.minmaxdate !== undefined && this.minmaxdate.min !== undefined ) {
          tasks[i].stroke = "#E0E0E0";
          tasks[i].start =
            this.defaults.drawing.startX + this.$func.NumberOfDays(this.minmaxdate.min, tasks[i].date_from) * this.defaults.dayscale || 0;
          tasks[i].width = (tasks[i].days || 0) * this.defaults.dayscale;
        }
        ypos =
          ypos +
          this.defaults.textblock.linespace;
          ret.svgheight = ypos;
        // console.log(tasks[i].y, tasks[i].measure.width)
      }

      ret.tasks = tasks;
      return ret;
    }
  },
  data() {
    return {
      debug: false,
      dates: [],
      defaults: {
        dayscale: 20,
        title: {x: 1, y: 10},
        drawing: {
          startX: 300,
          widthX: 500,
        },
        textblock: {
          offsetTop: 50,
          indent: 20,
          linespace: 24,
          taskLeftPad : 20,
          daystart: 250,
        },
      },
      /*
      task: [
        {id: 1, level: 1, title: "Rør 1",       progress: "10%", days: 17},
        {id: 2, level: 2, title: "Sandblåsing", progress: "10%", days: 25},
        {id: 3, level: 2, title: "Maling",      progress: "10%", days: 10},
        {id: 4, level: 1, title: "Rør 2",       progress: "10%", days: 12},
        {id: 5, level: 2, title: "Sandblåsing", progress: "10%", days: 45},
        ],
      measure: [
        {parent: 2, type: "progress", title: "Kvadrat",  count: 300, progress: 150, measure: "m2", stroke: "red"},
        {parent: 2, type: "days",     title: "Dager",    count: 25,  progress: 8, measure: "dg"},
        {parent: 2, type: "hours",    title: "Timer",    count: 700, progress: 250, measure: "hrs"},
        {parent: 2, type: "consume",  title: "Sand",     count: 2000,progress: 1800, measure: "kg"},
        {parent: 3, type: "progress", title: "Kvadrat (<-dailylog)",  count: 200, progress: 150, measure: "m2", stroke: "red"},
        {parent: 3, type: "days",     title: "Dager (<-teller f.start)",    count: 10,  progress: 17, measure: "dg"},
        {parent: 3, type: "hours",    title: "Timer (<-tidsbanken)",    count: 500, progress: 900, measure: "hrs"},
        {parent: 3, type: "consume",  title: "Maling (<-dailylog)",   count: 170, progress: 80, measure: "ltr"},
        {parent: 5, type: "progress", title: "Kvadrat",  count: 300, progress: 150, measure: "m2", stroke: "red"},
        {parent: 5, type: "days",     title: "Dager",    count: 17,  progress: 8, measure: "dg"},
        {parent: 5, type: "hours",    title: "Timer",    count: 700, progress: 250, measure: "hrs"},
        {parent: 5, type: "consume",  title: "Sand",     count: 2000,progress: 1800, measure: "kg"},
      ],  */
      svgheight: 500,
      chartdata: {
        title: { text: "." }
        //ylabels: [{pos: "high", title: "Høy"}, {pos: "low", title: "Lav"}, {pos: "middle", title: "Hvor attraktivt er markedet"}],
        //xlabels: [{pos: "low", title: "Høy"}, {pos: "high", title: "Lav"}, {pos: "middle", title: "Konkurransestyrke"}],
      },
      chartoptions: {
        boxx: 3,
        boxy: 3,
        height: 500,
        pad: { l: 50, r: 10, t: 50, b: 50 }
      }
    };
  }
};

function checkNested(obj, level, ...rest) {
  if (obj === undefined) return false;
  if (rest.length == 0 && obj.hasOwnProperty(level)) return true;
  return checkNested(obj[level], ...rest)
}
</script>