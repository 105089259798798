<template>
  <div>
      <!-- Autocomplete -->
      <v-toolbar dark rounded color="blue darken-4" dense>
        <v-toolbar-title>Prosjekt</v-toolbar-title>
        <v-autocomplete
          class="mx-4"
          dense
          hide-no-data
          hide-details
          label="Velg prosjekt"
          solo-inverted
          :items="autocompleteitems"
          :filter="customFilter"
          color="black"
          v-model="projectid"
        ></v-autocomplete>
        <v-btn
          raised
          :disabled="projectid == null"
          @click="hentProsjekt"
          color="blue"
          class="mr-2"
          >Hent prosjekt<v-icon right light
            >mdi-cursor-default-click</v-icon
          ></v-btn
        >
      </v-toolbar>

      <!-- Tabs -->
      <v-card flat class="mt-3">
        <v-tabs
          v-model="tab"
          background-color="primary"
          dark
          slider-size="5"
          show-arrows
        >
          <v-tabs-slider color="purple lighten-3"></v-tabs-slider>
          <v-tab :href="`#Tilbud`">Tilbud</v-tab>
          <v-tab :href="`#Kalkyle`">Kalkyle</v-tab>
          <v-tab :href="`#Kontrakt`">Kontrakt</v-tab>
          <v-tab :href="`#Risiko`">Risiko</v-tab>
          <v-tab :href="`#Muligheter`">Muligheter</v-tab>
          <v-tab :href="`#Krav`">Krav</v-tab>
          <v-tab :href="`#Utførelse`">Utførelse</v-tab>
          <v-tab :href="`#Utstyr`">Utstyr</v-tab>
          <!--<v-tab :href="`#Milepæl`">Milepæl</v-tab>-->
          <v-tab :href="`#Forecast`">Forecast</v-tab>
          <v-tab :href="`#Evaluering`">Evaluering</v-tab>
          <v-tab :href="`#Logg`">Logg</v-tab>
          <v-tab :href="`#Rapport`"
            >Rapport <v-icon>mdi-file-document-multiple-outline</v-icon></v-tab
          >
        </v-tabs>

        <v-tabs-items v-model="tab" v-if="gridready">
          <v-tab-item :key="0" value="Tilbud" class="pb-1">
            <v-card class="mx-auto my-4" max-width="600">
              <v-img
                height="250"
                src="/photo-1473073899705-e7b1055a7419.jpg"
              ></v-img>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="headline"
                    >Tilbudet</v-list-item-title
                  >
                  <v-divider></v-divider>
                  <v-list-item-subtitle
                    >Informasjon fra tilbudsbase</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list class="transparent">
                <v-list-item
                  v-for="item in projectHeader"
                  :key="item.field"
                  style="min-height: 26px"
                >
                  <v-list-item-subtitle>{{ item.title }}</v-list-item-subtitle>
                  <v-list-item-title class="text-left">{{
                    item.value
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="1" value="Kalkyle">
            <card-with-tabulator
              section="Kalkyle"
              :projectid="projectid"
              wid="btaen2ug10l1gqvkunbg"
              :widget="widgets['btaen2ug10l1gqvkunbg']"
              :options="fromDataObj('btaen2ug10l1gqvkunbg')"
            />
            <v-sheet elevation="1" color="blue lighten-5" class="pa-4">
              <div>Fra "Tilbud" har prosjektet oppstart den {{ extractFieldValue('projectHeader', 'startdate').value }} og avslutning den {{ extractFieldValue('projectHeader', 'enddate').value }}. Omsetning er estimert til {{ new Intl.NumberFormat('de-DE').format(extractFieldValue('projectHeader', 'revenueam').value) }} og dekningsgrad på {{ extractFieldValue('projectHeader', 'cmint').value }}%. Timer er estimert til {{ extractFieldValue('projectHeader', 'hoursam').value }}.</div>
            </v-sheet>
          </v-tab-item>

          <v-tab-item :key="2" value="Kontrakt">
            <card-with-tabulator
              section="Kontrakt"
              :projectid="projectid"
              wid="bsm6t96g10l2bc3fpsig"
              :widget="widgets['bsm6t96g10l2bc3fpsig']"
              :options="fromDataObj('bsm6t96g10l2bc3fpsig')"
            />
          </v-tab-item>

          <v-tab-item :key="3" value="Risiko">
            <card-with-tabulator
              section="Risiko"
              :projectid="projectid"
              wid="btciauug10l8nc86anog"
              :widget="widgets['btciauug10l8nc86anog']"
              :options="fromDataObj('btciauug10l8nc86anog')"
            />
          </v-tab-item>

          <v-tab-item :key="4" value="Muligheter">
            <card-with-tabulator
              section="Muligheter"
              :projectid="projectid"
              wid="btcil0eg10l8u8ea0qv0"
              :widget="widgets['btcil0eg10l8u8ea0qv0']"
              :options="fromDataObj('btcil0eg10l8u8ea0qv0')"
            />
          </v-tab-item>

          <v-tab-item :key="5" value="Krav">
            <card-with-tabulator
              section="Krav"
              :projectid="projectid"
              wid="btcinoeg10l903vhlf0g"
              :widget="widgets['btcinoeg10l903vhlf0g']"
              :options="fromDataObj('btcinoeg10l903vhlf0g')"
            />
          </v-tab-item>

          <v-tab-item :key="6" value="Utførelse">
            <card-with-tabulator
              section="Utførelse"
              :projectid="projectid"
              wid="btcir4eg10l92vsrh0eg"
              :widget="widgets['btcir4eg10l92vsrh0eg']"
              :options="fromDataObj('btcir4eg10l92vsrh0eg')"
            />
          </v-tab-item>

          <v-tab-item :key="7" value="Utstyr">
            <card-with-tabulator
              section="Utstyr"
              :projectid="projectid"
              wid="btcjkdmg10l9ocj9uk7g"
              :widget="widgets['btcjkdmg10l9ocj9uk7g']"
              :options="fromDataObj('btcjkdmg10l9ocj9uk7g')"
            />
          </v-tab-item>

          <!--
          <v-tab-item :key="8" value="Milepæl">
            <card-with-tabulator section="Milepæl" :projectid="projectid" wid="btcisceg10l94q0pa060" :widget="widgets['btcisceg10l94q0pa060']" :options="fromDataObj('btcisceg10l94q0pa060')" />            
          </v-tab-item>
          -->

          <v-tab-item :key="8" value="Forecast">
            <card-with-tabulator
              section="Forecast"
              :projectid="projectid"
              wid="bvqqfumvvhfkprsi1feg"
              :widget="widgets['bvqqfumvvhfkprsi1feg']"
              :options="fromDataObj('bvqqfumvvhfkprsi1feg')"
            />
            <v-sheet elevation="1" color="blue lighten-5" class="pa-4">
              <div>Fra "Tilbud" har prosjektet oppstart den {{ extractFieldValue('projectHeader', 'startdate').value }} og avslutning den {{ extractFieldValue('projectHeader', 'enddate').value }}. Omsetning er estimert til {{ new Intl.NumberFormat('de-DE').format(extractFieldValue('projectHeader', 'revenueam').value) }} og dekningsgrad på {{ extractFieldValue('projectHeader', 'cmint').value }}%. Timer er estimert til {{ extractFieldValue('projectHeader', 'hoursam').value }}.</div>
            </v-sheet>
          </v-tab-item>

          <v-tab-item :key="9" value="Evaluering">
            <card-with-tabulator
              section="Evaluering"
              :projectid="projectid"
              wid="btciue6g10l95vk9qp20"
              :widget="widgets['btciue6g10l95vk9qp20']"
              :options="fromDataObj('btciue6g10l95vk9qp20')"
            />
          </v-tab-item>

          <v-tab-item :key="10" value="Logg">
            <card-with-tabulator
              section="Logg"
              :projectid="projectid"
              wid="btcj2ceg10l996ejbif0"
              :widget="widgets['btcj2ceg10l996ejbif0']"
              :options="fromDataObj('btcj2ceg10l996ejbif0')"
            />
          </v-tab-item>

          <v-tab-item :key="11" value="Rapport">
            <v-card>
              <v-card-subtitle></v-card-subtitle>
              <v-card-actions>
                <v-btn
                  outlined
                  color="deep-purple"
                  small
                  @click="
                    requestMedia($route.path, { media: 'pdf', scope: 'pdfreport', id: $route.params.page })
                  "
                  class="ml-2"
                  >Last ned fullstendig PDF rapport
                  <v-icon color="red darken-2"
                    >mdi-file-pdf-box-outline</v-icon
                  ></v-btn
                >
              </v-card-actions>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>

      <!-- https://www.jenniferbland.com/how-to-add-charts-and-graphs-to-a-vue-application/ -->
  </div>
</template>

<style scoped>
  .tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row:nth-child(even) {
    background-color: #ede7f6;
  }
</style>
<style lang='scss'>
@import "@/assets/tabulator_simple_gd";
</style>

<script>
/*eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import requestData from "@/mixins/requestData.js";
import requestmedia from "@/mixins/requestMedia.js";
import CardWithTabulator from "@/components/TabulatorEditCard.vue";

export default {
  mixins: [requestData, requestmedia],
  components: {
    "card-with-tabulator": CardWithTabulator,
  },
  watch: {
    projectid: function (val, oldVal) {
      if (val !== oldVal) {
        this.hentetProsjekt = false;
        this.projectHeader = {};
        this.gridready = false;
        this.widgets = {};
      }
    },
  },
  mounted: function () {
    this.$options.sockets.onmessage = function (msg) {
      //console.log("received in component: ", msg.data)
      var obj = JSON.parse(msg.data);
      if (obj.status !== "ok") {
        this.$showMessage({ content: obj.status, color: "error"})
      }
    };

    var self = this;
    this.requestJSON2('/api/project/component', { id: "bqcbqsjipt3aimtdrtp0", scope: "keyval",})
    .then(function(e) { self.autocompleteitems = e[Object.keys(e)[0]] || []});      
  },
  beforeDestroy: function () {
    delete this.$options.sockets.onmessage;
  },
  data() {
    return {
      // Autocomplete search
      autocompleteitems: [],

      // Prosjektdetaljer
      hentetProsjekt: false,
      projectHeader: {},

      // Tabs
      tab: null,

      // Selected project
      projectid: null,

      // Page
      gridready: false,

      // Tabulator
      widgets: {},
    };
  },
  computed: {
    /*
    <div v-if="searchVal('erp_projectid')">{{searchVal}}</div>
    searchVal (index) {
      return this.projectHeader.find(function(post, index) { if(post.field == index && post.value.length > 0 ) {console.log(index); return post.value} })
    },
    */    
  },  
  methods: {
    customFilter(item, queryText) {
      const textOne = item.text.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    hentProsjekt() {      
      var self = this;
      this.requestJSON2('/api/project/component', { id: "bqcbqsjipt3aimtdrtp0", scope: "project", projectid: this.projectid})
      .then(function(e) {
        self.projectHeader = e[Object.keys(e)[0]]
        self.hentetProsjekt = true;
        self.requestTabulator({ projectid: self.projectid });
      });
    },
  },
};
/*
      https://github.com/olifolkerd/tabulator/issues/519
      https://github.com/olifolkerd/tabulator/issues/550
      console.log("component")
      console.log(a.getData())
      console.log(a.getElement())
      console.log(a.getRow())
      console.log(a.getRow().getData())
      console.log(this.widgets)
      console.log(Object.keys(this.widgets)[0])
      console.log(this.$refs.tabulator)
      console.log(this.$refs.tabulator[0].getInstance())
      */

/*
    downloadCSV() {
      const tabulatorInstance = this.$refs.tabulator[0].getInstance();
      tabulatorInstance.download("csv", "data.csv");
    },
    */
</script>