<template>
  <div>

    <!-- DIALOG for å sette inn linjer -->
    <v-dialog v-model="dialog" scrollable max-width="700px" @click:outside="closeDialog">
      <v-card>
        <v-card-title>Sett inn beregningslinjer</v-card-title>
        <v-divider></v-divider>

        <v-expansion-panels flat focusable accordion v-model="panel">
          <v-expansion-panel v-for="(item, i) in dialogprice[tab]" :key="i">
            <v-expansion-panel-header>{{item.category}}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-btn v-for="(row, j) in item.items" :key="j" @click="newAccountingRow(row)" class="justify-start" text small block color="info" dense>
                <v-icon color="grey" float-left>mdi-chevron-left</v-icon>{{ row.title }}
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- LEFT EXPANDING MENU -->
    <v-navigation-drawer style="background: rgba(255, 255, 255, 0.5);" v-model="drawer" :mini-variant.sync="mini" app floating expand-on-hover permanent>
      <v-list dense nav>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item v-for="item in tabs" :key="item.title" link @click="getTabSection(item.ref)">
            <v-list-item-icon>
              <v-icon :color="item.color">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <!-- MAIN SCREEN -->
    <v-main style="height: 100%; padding: 0px">
      <v-container elevation="1" fluid class="pt-2 pr-2" style="height: 100%" ref="width">

        <v-sheet width="100%" height="100%" rounded class="pb-2">

          <!-- TOP TOOLBAR -->
          <v-toolbar rounded dense flat>
            <v-toolbar-title>{{ sectionTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <template v-if="Object.keys( bidselected ).length != 0">
            <span class="text-right red--text font-weight-bold">{{ bidselected.erp_projectid + ' ' + bidselected.description }}</span>
            <span class="text-right grey--text pl-2">{{ bidselected.key + ' / ' + bidselected.client + ' / ' + bidselected.segment + ' / ' + bidselected.bidstatus  }}</span>
            </template>
          </v-toolbar>
          <v-divider></v-divider>

          <!-- TABS -->
          <v-tabs vertical v-model="tab" dark background-color="purple lighten-2" active-class="purple">

            <!-- Tab elementer -->
            <v-tabs-items v-model="tab">

              <!-- MAIN -->
              <v-tab-item value="main">
                <v-row>
                  <v-col>
                    <v-card class="ma-2" outlined>
                      <v-img height="150" src="/f1ac04a1dca29a2a1b352a0801dfdc78daf2540e.jpeg"></v-img>
                      <template v-if="Object.keys( bidselected ).length == 0">
                      <v-card-title>Hent tilbud eller prosjekt som skal kalkuleres</v-card-title>
                      <v-card-subtitle>Flere versjoner kan lagres</v-card-subtitle>
                      </template>
                      <template v-else>
                        <v-card-title class="red--text">{{ bidselected.erp_projectid + ' ' + bidselected.description }}</v-card-title>
                        <v-card-subtitle>{{ bidselected.key + ' / ' + bidselected.client + ' / ' + bidselected.segment + ' / ' + bidselected.bidstatus  }}</v-card-subtitle>
                      </template>
                      <v-divider class="mx-4"></v-divider>
                      <v-card-actions>
                        <SelectListToDialog @emitselected="bidSelected" title="Tilbud" :request="{componentid: 'c4dvgi86n88hi80i3mqg', scope: 'bids', url: '/api/bid/component'}" :tableHeaders="[{ text: 'Tilbudsnr', value: 'key' },{ text: 'Prosjektnr', value: 'erp_projectid' },{ text: 'Beskrivelse', value: 'description' },{ text: 'Status', value: 'bidstatus' },{ text: 'Kunde', value: 'client' },{ text: 'Segment', value: 'segment' },{ text: 'Oppstart', value: 'startdate' }]"/>
                        <template v-if="bidselected.xid !== undefined">
                          <v-btn disabled outlined color="light-blue" class="ml-5">Opprett ny beregning</v-btn>
                          <v-btn disabled outlined color="light-blue" class="ml-5">Hent lagret beregning</v-btn>
                        </template>
                          <v-spacer></v-spacer>
                        <v-btn outlined color="pink" @click="save" class="ml-5">Lagre denne beregning</v-btn>
                        <v-progress-circular :active="loading" :indeterminate="loading" color="red" width="5" size="50" class="ml-3"></v-progress-circular>
                      </v-card-actions>
                    </v-card>
                  </v-col>                  
                </v-row>    
              </v-tab-item>

              <!-- OPPSUMMERING -->
              <v-tab-item value="Oppsummering">
                <v-row>

                  <v-col cols="2">
                    <v-card flat>
                      <v-card-title class="pb-0">Jobbpakker</v-card-title>
                      <v-card-text class="pb-0">
                        <template v-for="(item, i) in accounting">
                          <div v-if="item.section == 'jobpkg'" :key=i>
                            {{ item.title }} ({{ item.daysint }} dg)
                          </div>
                        </template>                 
                      </v-card-text>
                      <v-card-title class="pb-0">Timer</v-card-title>
                      <v-card-text class="pb-0">{{ $formatNumber(totalHours) }}</v-card-text>

                      <v-card-title class="pb-0">Dager</v-card-title>
                      <v-card-text class="pb-0">{{ minmaxdate.days }}</v-card-text>

                      <v-card-title class="pb-0">Kvadrat</v-card-title>
                      <v-card-text class="pb-0">{{ $formatNumber(sumAccountingFilterReduce({filters: {section: ['activity'], lineid: ['maling']}, sum: ['countint']})) }}</v-card-text>

                      <v-card-title class="pb-0">Resultatmargin</v-card-title>
                      <v-card-text class="pb-0">{{ $formatNumber(-sumAccountingFilterReduce({filters: {section: ['ledger']}, sum: ['amount']}) / -sumAccountingFilterReduce({filters: {section: ['ledger'], classification: ['revenue']}, sum: ['amount']}) * 100, 1)  }}%</v-card-text>

                    </v-card>   
                  </v-col>

                  <v-col cols="5">

                    <v-card flat>
                      <v-card-title class="pb-0">Kalkyle</v-card-title>
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Beskrivelse</th>
                              <th class="text-right">Beløp</th>
                              <th class="text-right">Beløp pr time</th>
                              <th class="text-right">Beløp pr m2</th>
                              <th class="text-right">Co2 - kg</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in tabs" :key=index>
                              <template v-if="item.summary">
                                <td>{{item.title}}</td>
                                <td class="text-right">{{ $formatNumber(-sumAccountingFilterReduce({filters: {section: ['ledger'], classification: [item.ref.substring(1)]}, sum: ['amount']})) }}</td>
                                <td class="text-right">{{ $formatNumber(-sumAccountingFilterReduce({filters: {section: ['ledger'], classification: [item.ref.substring(1)]}, sum: ['amount']})/totalHours) }}</td>
                                <td class="text-right">{{ $formatNumber(-sumAccountingFilterReduce({filters: {section: ['ledger'], classification: [item.ref.substring(1)]}, sum: ['amount']}) / sumAccountingFilterReduce({filters: {section: ['activity'], lineid: ['maling']}, sum: ['countint']})) }}</td>
                                <td class="text-right">{{ $formatNumber(sumAccountingFilterReduce({filters: {section: ['ledger'], classification: [item.ref.substring(1)]}, sum: ['co2sumam']})) }}</td>
                              </template>
                            </tr>
                            <tr>
                                <td>Resultat</td>
                                <td class="text-right">{{ $formatNumber(-sumAccountingFilterReduce({filters: {section: ['ledger']}, sum: ['amount']})) }}</td>
                                <td class="text-right">{{ $formatNumber(-sumAccountingFilterReduce({filters: {section: ['ledger']}, sum: ['amount']})/totalHours) }}</td>
                                <td class="text-right">{{ $formatNumber(-sumAccountingFilterReduce({filters: {section: ['ledger']}, sum: ['amount']}) / sumAccountingFilterReduce({filters: {section: ['activity'], lineid: ['maling']}, sum: ['countint']})) }}</td>
                                <td class="text-right">{{ $formatNumber(sumAccountingFilterReduce({filters: {section: ['ledger']}, sum: ['co2sumam']})) }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>

                  </v-col>

                  <v-col cols="5">

                  <v-card-title class="pb-0">Resultatelementer</v-card-title>
                  <apex-charts width="500" type="donut" :options="apexoptions" :series="donutseries"></apex-charts>

                  </v-col>
                </v-row>

              </v-tab-item>

              <!-- FORUTSETNINGER -->
              <v-tab-item value="forutsetninger">
                <v-row>

                  <v-col>
                    <v-card flat>
                      <v-card-title>Parametre</v-card-title>

                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Tekst</th>
                              <th class="text-left">ID</th>
                              <th class="text-left">Verdi</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, i) in defaults" :key="i">
                              <td>{{ item.text }}</td>
                              <td>{{ item.id }}</td>
                              <td>
                                <v-text-field single-line style="height: 30px" dense v-model.number="item.val"></v-text-field>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <v-card-actions>
                        <v-btn @click="fillDefaults">
                          <v-icon>mdi-arrow-left</v-icon>
                          Re-calc
                        </v-btn>
                      </v-card-actions>

                    </v-card>
                  </v-col>
                  <v-col>
                  </v-col>

                </v-row>
              </v-tab-item>

              <!-- JOBBPAKKER -->
              <v-tab-item value="jobbpakker">
                <v-row>
                  <v-col>
                    <v-card flat>
                      <v-card-text>
                        <v-col class="text-right pt-0"><v-btn small color="blue" outlined @click="nyJobbpakke" >Opprett ny jobbpakke</v-btn></v-col>
                        <Jobpackages @emitmove="move" :header="['Rekkeflg', 'Jobb', 'Kommentar', 'Type', 'Fra dato', 'Til dato', 'Dager']" :items="jobpackages" :jobpackagecategory="jobpackagecategory" />
                      </v-card-text>
                      <v-card-subtitle class="pt-0">
                        <v-divider class="mb-2"></v-divider>
                        <v-chip dark class="mr-2" color="red accent-3" label>{{ minmaxdate.days }} dager</v-chip>
                        Oppstart den {{ $formatDate(minmaxdate.min) }} og avslutning den {{ $formatDate(minmaxdate.max) }}.
                      </v-card-subtitle>
                      <v-card-text>
                        <graphic-gantt :initwidth="width" :translate="{wpkg: {key: 'section', val: 'jobpkg'}, date_from: 'frdt', date_to: 'todt', title: 'title'}" :task="jobpackages" :measure="[]" heading1="" :minmaxdate="minmaxdate"></graphic-gantt>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>

              <!-- AKTIVITETER -->
              <v-tab-item value="activity">
                  <v-card flat>
                    <v-card-actions>
                      <v-alert dense color="red" class="mr-2 pr-1" icon="mdi-run" text type="info" width=220>Timer: {{ $formatNumber(sumAccountingFilterReduce({filters: {section: ['activity'], measure1: ['timer']}, sum: ['countint', '*', 'consume1am']})) }}</v-alert>
                      <v-alert dense color="red" class="mr-2 pr-1" icon="mdi-run" text type="info" width=220>Maling: {{ $formatNumber(sumAccountingFilterReduce({filters: {section: ['activity'], lineid: ['maling']}, sum: ['countint', '*', 'consume2am']})) }} ltr</v-alert>
                      <v-alert dense color="red" class="mr-2 pr-1" icon="mdi-run" text type="info" width=220>Sand:   {{ $formatNumber(sumAccountingFilterReduce({filters: {section: ['activity'], lineid: ['sand']}, sum: ['countint', '*', 'consume2am']})) }} kg</v-alert>
                      <v-alert dense color="red" class="mr-2 pr-1" icon="mdi-run" text type="info" width=220>Gel:    {{ $formatNumber(sumAccountingFilterReduce({filters: {section: ['activity'], lineid: ['gel']}, sum: ['countint', '*', 'consume2am']})) }} ltr</v-alert>
                      <v-alert dense color="red" class="mr-2 pr-1" icon="mdi-run" text type="info" width=220>Stillas: {{ $formatNumber(sumAccountingFilterReduce({filters: {section: ['activity'], lineid: ['stillas']}, sum: ['countint']})) }} m³</v-alert>
                      <v-alert dense color="red" class="mr-2 pr-1" icon="mdi-run" text type="info" width=220>Inndekking: {{ $formatNumber(sumAccountingFilterReduce({filters: {section: ['activity'], lineid: ['inndekking']}, sum: ['countint']})) }} m²</v-alert>
                    </v-card-actions>
                  </v-card>  

                <div v-for="(item, index) in jobpackages" :key=index>
                  <hr style="border: 2px solid #FFCDD2; margin-bottom: 15px"/>
                  <v-card flat>
                    <v-card-title class="py-1">
                      <v-chip dark class="mr-2" color="red accent-3" label>{{item.daysint}} dager</v-chip>
                      <span class="mr-4 red--text text--accent-3">{{item.title}}</span>
                      <span style="font-size: 0.7em" class="grey--text">{{item.comment}} {{ $formatDate(item.frdt) }} → {{ $formatDate(item.todt) }}</span>
                      <v-spacer></v-spacer>
                      <v-btn outlined small color="blue" @click="openDialog(item.xid)">Sett inn linjer</v-btn>
                    </v-card-title>
                    <v-card-text>
                      <Activities @deleterow="deleterow" @emitmove="move" :wpkg=true :header="tblheaderwpkg" :classification="item.xid" :items="accountingListFilterClassification(item.xid)" />
                    </v-card-text>
                  </v-card>
                </div>
              </v-tab-item>

              <!-- PROSJEKTPLAN -->
              <v-tab-item value="projectplan">
                  <v-card flat>
                    <v-card-text>
                      <!--<graphic-gantt :translate="{date_from: 'frdt', date_to: 'todt', title: 'title', parent: 'classification'}" :task="jobpackages" :measure="activities" heading1="Prosjektplan" :minmaxdate="minmaxdate"></graphic-gantt>-->
                      <graphic-gantt :initwidth="width" :translate="{wpkg: {key: 'section', val: 'jobpkg'}, task: {key: 'section', val: 'activity', parent: 'classification'},  date_from: 'frdt', date_to: 'todt', title: 'title'}" :task="gantt" heading1="" :minmaxdate="minmaxdate"></graphic-gantt>
                    </v-card-text>
                  </v-card>
              </v-tab-item>

              <!-- OMSETNING -->
              <v-tab-item value="revenue">          
                <v-card flat>
                  <v-card-actions>
                      <v-col cols="2" class="pa-0"><v-alert dense color="green" class="mr-2 mb-5" icon="mdi-information-variant" text type="info" width=220>Resultat: {{ $formatNumber(-sumAccountingFilterReduce({filters: {section: ['ledger']}, sum: ['amount']}))  }}</v-alert></v-col>
                      <v-col cols="2" class="pa-0"><v-alert dense color="green" class="mr-2 mb-5" icon="mdi-information-outline" text type="info" width=220>Margin: {{ $formatNumber(-sumAccountingFilterReduce({filters: {section: ['ledger']}, sum: ['amount']}) / -sumAccountingFilterReduce({filters: {section: ['ledger'], classification: ['revenue']}, sum: ['amount']}) * 100,1) }} %</v-alert></v-col>
                      <v-col cols="2" class="pa-0"><v-text-field class="float-left ml-5" dense v-model.number="margin" label="Margin (0-100)" hint="Beregn omsetning basert på gitt resultatmargin" outlined append-icon="mdi-send" @click:append="updateRevenueMargin"></v-text-field></v-col>
                      <v-col cols="2" class="pa-0"><v-text-field class="float-left ml-5" dense style="width: 200px !important" v-model.number="revenuefixed" label="Omsetning, kr" hint="Sett inn omsetning med beløp" outlined append-icon="mdi-send" @click:append="updateRevenueFixed"></v-text-field></v-col>                                             
                      <v-spacer></v-spacer>
                      <v-btn outlined small color="blue" @click="openDialog(undefined)">Sett inn linjer</v-btn>
                  </v-card-actions>
                  <v-card-text>
                    <Calculationtable @deleterow="deleterow" @emitmove="move" :header="tblheader" classification="revenue" :list="accountingListFilterClassification('revenue')" />
                  </v-card-text>
                </v-card>
              </v-tab-item>


              <!-- VAREKJØP -->
              <v-tab-item value="goods">          
                <v-card flat>
                  <v-card-actions>
                      <v-alert dense color="red" class="mr-2 mb-0 pr-1" icon="mdi-run" text type="info" width=220>Maling: {{ $formatNumber(sumAccountingFilterReduce({filters: {section: ['activity'], lineid: ['maling']}, sum: ['countint', '*', 'consume2am']})) }} ltr</v-alert>
                      <v-alert dense color="red" class="mr-2 mb-0 pr-1" icon="mdi-run" text type="info" width=220>Sand:   {{ $formatNumber(sumAccountingFilterReduce({filters: {section: ['activity'], lineid: ['sand']}, sum: ['countint', '*', 'consume2am']})) }} kg</v-alert>
                      <v-alert dense color="red" class="mr-2 mb-0 pr-1" icon="mdi-run" text type="info" width=220>Gel:    {{ $formatNumber(sumAccountingFilterReduce({filters: {section: ['activity'], lineid: ['gel']}, sum: ['countint', '*', 'consume2am']})) }} ltr</v-alert>
                      <v-spacer></v-spacer>
                      <v-btn outlined small color="blue" @click="openDialog(undefined)">Sett inn linjer</v-btn>
                  </v-card-actions>
                  <v-card-actions class="pt-0">
                      <v-alert dense color="cyan" class="mr-2 mb-0 pr-1" icon="mdi-format-paint" text type="info" width=220>Maling: {{ $formatNumber(sumAccountingFilterReduce({filters: {section: ['ledger'], classification: ['goods'], lineid: ['maling']}, sum: ['countint']})) }} ltr</v-alert>
                      <v-alert dense color="cyan" class="mr-2 mb-0 pr-1" icon="mdi-format-paint" text type="info" width=220>Sand:   {{ $formatNumber(sumAccountingFilterReduce({filters: {section: ['ledger'], classification: ['goods'], lineid: ['sand']}, sum: ['countint']})) }} kg</v-alert>
                      <v-alert dense color="cyan" class="mr-2 mb-0 pr-1" icon="mdi-format-paint" text type="info" width=220>Gel:    {{ $formatNumber(sumAccountingFilterReduce({filters: {section: ['ledger'], classification: ['goods'], lineid: ['gel']}, sum: ['countint']})) }} ltr</v-alert>
                  </v-card-actions>
                  <v-card-text>
                        
                    <Calculationtable @deleterow="deleterow" @emitmove="move" :header="tblheader" classification="goods" :list="accountingListFilterClassification('goods')" />

                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!-- TILKOMST -->
              <v-tab-item value="access">
                <v-card flat>
                  <v-card-actions>
                      <v-alert dense color="red" class="mr-2 mb-0 pr-1" icon="mdi-run" text type="info" width=220>Stillas:{{ $formatNumber(sumAccountingFilterReduce({filters: {section: ['activity'], lineid: ['stillas']}, sum: ['countint']})) }} m³</v-alert>
                      <v-alert dense color="red" class="mr-2 mb-0 pr-1" icon="mdi-run" text type="info" width=220>Inndekking:{{ $formatNumber(sumAccountingFilterReduce({filters: {section: ['activity'], lineid: ['inndekking']}, sum: ['countint']})) }} m²</v-alert>
                      <v-spacer></v-spacer>
                      <v-btn outlined small color="blue" @click="openDialog(undefined)">Sett inn linjer</v-btn>
                  </v-card-actions>
                  <v-card-actions class="pt-0">
                      <v-alert dense color="cyan" class="mr-2 mb-0 pr-1" icon="mdi-format-paint" text type="info" width=220>Stillas:    {{ $formatNumber(sumAccountingFilterReduce({filters: {section: ['ledger'], classification: ['access'], lineid: ['stillas']}, sum: ['countint']})) }} m³</v-alert>
                      <v-alert dense color="cyan" class="mr-2 mb-0 pr-1" icon="mdi-format-paint" text type="info" width=220>Inndekking:    {{ $formatNumber(sumAccountingFilterReduce({filters: {section: ['ledger'], classification: ['access'], lineid: ['inndekking']}, sum: ['countint']})) }} m²</v-alert>
                  </v-card-actions>
                  <v-card-text>
                    <Calculationtable @deleterow="deleterow" @emitmove="move" :header="tblheader" classification="access" :list="accountingListFilterClassification('access')" />
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!-- PERSONELL -->
              <v-tab-item value="personnel">
                <v-card flat>
                  <v-card-actions>
                    <v-alert dense color="red"  class="mr-2 mb-0 pr-1" text type="info" width=220 icon="mdi-run">Timer: {{ $formatNumber(sumAccountingFilterReduce({filters: {section: ['activity'], measure1: ['timer']}, sum: ['countint', '*', 'consume1am']})) }}</v-alert>
                    <v-alert dense color="cyan" class="mr-2 mb-0 pr-1" text type="info" width=220 icon="mdi-human-queue">Timer: {{ $formatNumber(sumAccountingFilterReduce({filters: {section: ['ledger'], classification: ['personnel'], measure1: ['timer']}, sum: ['countint', '*', 'durationam', '*', 'consume1am']})) }}</v-alert>
                    <v-spacer></v-spacer>
                    <v-btn outlined small color="blue" @click="openDialog(undefined)">Sett inn linjer</v-btn>
                  </v-card-actions>
                  <v-card-text>
                    <Calculationtable @deleterow="deleterow" @emitmove="move" :header="tblheader" classification="personnel" :list="accountingListFilterClassification('personnel')" />
                    <span style="visibility: hidden">{{ aga }} {{ otp }} {{ afp }} {{ fp }} {{ rot }} {{ rti }}</span>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!-- REISE OG OPPHOLD -->
              <v-tab-item value="travelacco">
                <v-card flat>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined small color="blue" @click="openDialog(undefined)">Sett inn linjer</v-btn>
                  </v-card-actions>
                  <v-card-text>
                    <Calculationtable @deleterow="deleterow" @emitmove="move" :header="tblheader" classification="travelacco" :list="accountingListFilterClassification('travelacco')" />
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!-- FORBRUK -->
              <v-tab-item value="consumable">
                <v-card flat>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined small color="blue" @click="openDialog(undefined)">Sett inn linjer</v-btn>
                  </v-card-actions>
                  <v-card-text>
                    <Calculationtable @deleterow="deleterow" @emitmove="move" :header="tblheader" classification="consumable" :list="accountingListFilterClassification('consumable')" />
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!-- TUNGT UTSTYR -->
              <v-tab-item value="tuut">
                <v-card flat>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined small color="blue" @click="openDialog(undefined)">Sett inn linjer</v-btn>
                  </v-card-actions>
                  <v-card-text>
                    <Calculationtable @deleterow="deleterow" @emitmove="move" :header="tblheader" classification="tuut" :list="accountingListFilterClassification('tuut')" />
                    Inkl. forbruk av diesel og strøm Co2 regnskap. List antall tonn
                    til transport.<br>Legg inn kommentarfelt for Rigg plass.
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!-- TRANSPORT -->
              <v-tab-item value="transport">
                <v-card flat>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined small color="blue" @click="openDialog(undefined)">Sett inn linjer</v-btn>
                  </v-card-actions>
                  <v-card-text>
                    <Calculationtable @deleterow="deleterow" @emitmove="move" :header="tblheader" classification="transport" :list="accountingListFilterClassification('transport')" />
                    Inkluder interntransport, helikopter, lastebil. Sum pr category bottom
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!-- GANTT -->
              <v-tab-item value="gantt">
                <v-card flat>
                  <v-card-text>
                    <graphic-gantt-v0 :task="task" :measure="measure" heading1="Jobber"></graphic-gantt-v0>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!-- DEBUG -->
              <v-tab-item  value="debug">
                <v-btn class="ma-2"  @click="debug = !debug">Debug - toggle</v-btn>
                <div v-if="debug">
                <v-card flat>

                      measure | x | y | stroke | start | width
                      <table class="debug" style="font-size: 0.6em">
                        <tr>
                          <th class="rotate"><div><span>xid</span></div></th>
                          <th class="rotate"><div><span>orderint</span></div></th>
                          <th class="rotate"><div><span>section</span></div></th>
                          <th class="rotate"><div><span>classification</span></div></th>
                          <th class="rotate"><div><span>parent</span></div></th>
                          <th class="rotate"><div><span>category</span></div></th>
                          <th class="rotate"><div><span>lineid</span></div></th>
                          <th class="rotate"><div><span>title</span></div></th>
                          <th class="rotate"><div><span>comment</span></div></th>
                          <th class="rotate"><div><span>frdt</span></div></th>
                          <th class="rotate"><div><span>todt</span></div></th>
                          <th class="rotate"><div><span>daysint</span></div></th>
                          <th class="rotate"><div><span>countint</span></div></th>
                          <th class="rotate"><div><span>calcpricepr</span></div></th>
                          <th class="rotate"><div><span>durationam</span></div></th>
                          <th class="rotate"><div><span>durationmeasure</span></div></th>
                          <th class="rotate"><div><span>consume1am</span></div></th>
                          <th class="rotate"><div><span>consume1measure</span></div></th>
                          <th class="rotate"><div><span>quantity1am</span></div></th>
                          <th class="rotate"><div><span>measure1</span></div></th>
                          <th class="rotate"><div><span>consume2am</span></div></th>
                          <th class="rotate"><div><span>consume2measure</span></div></th>
                          <th class="rotate"><div><span>quantity2am</span></div></th>
                          <th class="rotate"><div><span>measure2</span></div></th>
                          <th class="rotate"><div><span>priceam</span></div></th>
                          <th class="rotate"><div><span>pricemeasure</span></div></th>
                          <th class="rotate"><div><span>amount</span></div></th>
                          <th class="rotate"><div><span>co2am</span></div></th>
                          <th class="rotate"><div><span>co2sumam</span></div></th>
                          <th class="rotate"><div><span>calc</span></div></th>
                          <th class="rotate"><div><span>acnoint</span></div></th>
                          <th class="rotate"><div><span>sup_id</span></div></th>
                          <th class="rotate"><div><span>disable</span></div></th>
                          <th class="rotate"><div><span>ia-quantity</span></div></th>
                          <th class="rotate"><div><span>ia-isworkpkg</span></div></th>
                        </tr>
                        <tr v-for="(item, i) in accounting" :key="i">
                          <td style="max-width: 10px; overflow: hidden; text-overflow: elipsis; white-space: nowrap;">{{item.xid}}</td>
                          <td>{{item.orderint}}</td>
                          <td>{{item.section}}</td>
                          <td>{{item.classification}}</td>
                          <td>{{item.parent}}</td>
                          <td>{{item.category}}</td>
                          <td>{{item.lineid}}</td>
                          <td>{{item.title}}</td>
                          <td style="max-width: 20px; overflow: hidden; text-overflow: hidden; white-space: nowrap;">{{item.comment}}</td>
                          <td>{{item.frdt}}</td>
                          <td>{{item.todt}}</td>
                          <td>{{item.daysint}}</td>
                          <td>{{item.countint}}</td>
                          <td>{{item.calcpricepr}}</td>
                          <td>{{item.durationam}}</td>
                          <td>{{item.durationmeasure}}</td>
                          <td>{{item.consume1am}}</td>
                          <td>{{item.consume1measure}}</td>
                          <td>{{item.quantity1am}}</td>
                          <td>{{item.measure1}}</td>
                          <td>{{item.consume2am}}</td>
                          <td>{{item.consume2measure}}</td>
                          <td>{{item.quantity2am}}</td>
                          <td>{{item.measure2}}</td>
                          <td>{{item.priceam}}</td>
                          <td>{{item.pricemeasure}}</td>
                          <td>{{item.amount}}</td>
                          <td>{{item.co2am}}</td>
                          <td>{{item.co2sumam}}</td>
                          <td>{{item.calc}}</td>
                          <td>{{item.acnoint}}</td>
                          <td>{{item.sup_id}}</td>
                          <td>{{item.disable}}</td>
                          <td>{{item.quantity}}</td>
                          <td>{{item.isworkpkg}}</td>
                        </tr>
                      </table>
                <v-card>
                    <v-card-text>
                      <code style="font-size: 0.8em" v-for="(item, i) in accounting" :key="i">
                        {{ item }}<br />
                      </code>
                  Index side med alle tilbud / revisjoner (hake) for om aktiv. Alle VOR som egne tilbud.<br />Drag slot with personell to project to inform HR
                  <br>Fremdrift: Rapportering: ... timer fra tidsbanken
                  <br>Månedsavslutning: Grunnlag klar for avsetning. Inntekt etter progress, kostnad etter fordeling
                  <br>Ordre: Indikator på fakturering og planlagt ordre etter kontrakt
                  <br>Rapport som sammenligner forbruk pr m2 maling og sand mot andre tilbud

                    </v-card-text>

                  </v-card>

                </v-card>
                </div>
              </v-tab-item>

            </v-tabs-items>
          </v-tabs>

        </v-sheet>
      </v-container>
    </v-main>
  </div>
</template>

<style scoped>

.debug td {border-left: 1px solid #c9c9c9;}

/* Rotated table header */
th.rotate {
  /* Something you can count on */
  height: 140px;
  white-space: nowrap;
}

th.rotate > div {
  transform: 
    /* Magic Numbers */
    translate(25px, 51px)
    /* 45 is really 360 - 45 */
    rotate(315deg);
  width: 30px;
}
th.rotate > div > span {
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
}
/* ... rotated table header */

</style>

<script>
/*eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import requestData from "@/mixins/requestData.js";
import Calculationtable from "@/views/bid/comp/CalculationTable.vue";
import Activities from "@/views/bid/comp/Activities.vue";
import ApexCharts from "vue-apexcharts";
import GraphicGantt from "@/components/GraphicGantt.vue"
import GraphicGanttV0 from "@/components/GraphicGanttV0.vue"
import Jobpackages from "@/views/bid/comp/Jobpackages.vue"
import SelectListToDialog from "@/components/SelectListToDialog.vue";

// (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)

// https://github.com/beatrizsmerino/vue-dinner-calculator/blob/master/js/app.js
export default {
  mixins: [requestData],
  components: {
    Calculationtable,
    Activities,
    "graphic-gantt": GraphicGantt,
    "graphic-gantt-v0": GraphicGanttV0,
    "apex-charts": ApexCharts,
    Jobpackages,
    SelectListToDialog
  },
  mounted: function() {
    this.getPrices();
    this.width = this.$refs.width.clientWidth - 120;
  },
  data() {
    return {
      width: 800,
      debug: false,
      drawer: true,
      mini: true,
      dialog: false,
      isActivity: undefined,
      loading: false,
      panel: [],
      tab: 'main',
      bcid: "c8np7ksr7uc7kgss7ba0",
      bidselected: {},
      selectedItem: undefined,
      sectionTitle: "Kalkyle",
      jobpackagecategory: ['Kontrakt', 'Opsjon', 'VOR'],
      margin: 20,
      revenuefixed: 0,
      tblheader: [
          /*{complete: true, class:"text-left", title: "Klassifisering"},*/
          {class:"text-left", title: "Tekst"},
          {class:"text-left", title: "Kommentar"},
          {class:"text-left", title: "Antall"},
          {class:"text-left", title: "Forbruk"},
          {class:"text-left", title: "Varighet"},
          {class:"text-right pr-4", title: "Mengde"},
          {class:"text-left", title: "Pris"},
          {class:"text-right", title: "Sum"},
          {class:"text-right", title: "CO2-kg"},
      ],
      tblheaderwpkg: [
          {class:"text-left", title: "Rekkeflg."},
          {class:"text-left", title: "Tekst"},
          {class:"text-left", title: "Kommentar"},
          {class:"text-left", title: "Fra dato"},
          {class:"text-left", title: "Til dato"},
          {class:"text-left", title: "Dager"},
          {class:"text-left", title: "Antall (progress)"},
          {class:"text-left", title: "Timeforbruk"},
          {class:"text-right pr-4", title: "Σ timer"},
          {class:"text-left", title: "Mengdeforbruk"},
          {class:"text-right pr-4", title: "Σ mengde"},
      ],
      tabs: [
        {
          ref: "#main",
          icon: "mdi-flare",
          title: "Kalkyle",
          color: "pink darken-3"
        },
        {
          ref: "#Oppsummering",
          icon: "mdi-book-outline",
          title: "Oppsummering",
          color: "green"
        },
        {
          ref: "#jobbpakker",
          icon: "mdi-account-hard-hat",
          title: "Jobbpakker",
          color: "red"
        },
        {
          ref: "#activity",
          icon: "mdi-run",
          title: "Aktiviteter pr jobbpakke",
          color: "red"
        },
        {
          ref: "#projectplan",
          icon: "mdi-chart-gantt",
          title: "Prosjektplan",
          color: "red"
        },
        {
          ref: "#forutsetninger",
          icon: "mdi-head-question-outline",
          title: "Forutsetninger",
          color: "red"
        },
        {
          ref: "#revenue",
          icon: "mdi-cash-100",
          title: "Omsetning og resultat",
          color: "cyan",
          summary: true
        },
        {
          ref: "#goods",
          icon: "mdi-format-paint",
          title: "Sand og maling",
          color: "cyan",
          summary: true
        },
        {
          ref: "#access",
          icon: "mdi-ladder",
          title: "Tilkomst",
          color: "cyan",
          summary: true
        },
        {
          ref: "#personnel",
          icon: "mdi-human-queue",
          title: "Personell",
          color: "cyan",
          summary: true,
        },
        {
          ref: "#travelacco",
          icon: "mdi-airplane",
          title: "Reise og opphold",
          color: "cyan",
          summary: true
        },
        {
          ref: "#consumable",
          icon: "mdi-hammer",
          title: "Forbruksmatriell",
          color: "cyan",
          summary: true
        },
        {
          ref: "#tuut",
          icon: "mdi-cog-outline",
          title: "Tungt utstyr",
          color: "cyan",
          summary: true
        },
        {
          ref: "#transport",
          icon: "mdi-truck-outline",
          title: "Transport",
          color: "cyan",
          summary: true
        },
        {
          ref: "#gantt",
          icon: "mdi-chart-gantt",
          title: "Gantt"
        },
        {
          ref: "#debug",
          icon: "mdi-bug-outline",
          title: "Debug",
          color: "black"
        },
      ],
      accounting: [],
      prices: [],
      dialogprice: {},
      defaults: [
        {
          val: 12,
          column: "consume1am",
          text: "Timer pr dag",
          classification: "personnel",
          category: "Ansatt"
        },
        { val: 14, text: "Dager pr rotasjon" },
        { val: 2, text: "Rotasjoner" },
        { val: 300, column: "meter", text: "Rørlengde (slangemeter)" },
        { val: 1000, text: "Snittlengde flytur i km (Co2 beregning)" },
      ],
      apexoptions: {
        colors: this.$colors(),
        labels: [
          "Sand og maling",
          "Tilkomst",
          "Personell",
          "Reise og opphold",
          "Forbruksmatriell",
          "Tungt utstyr",
          "Transport",
          "Resultat",
        ]
      },
      task: [
        {xid: 1, level: 1, title: "Rør 1",       progress: "10%", days: 17},
        {xid: 2, level: 2, title: "Sandblåsing", progress: "10%", days: 25},
        {xid: 3, level: 2, title: "Maling",      progress: "10%", days: 10},
        {xid: 4, level: 1, title: "Rør 2",       progress: "10%", days: 12},
        {xid: 5, level: 2, title: "Sandblåsing", progress: "10%", days: 45},
      ],
      measure: [
        {parent: 2, type: "progress", title: "Kvadrat",  count: 300, progress: 150, measure: "m2", stroke: "red"},
        {parent: 2, type: "days",     title: "Dager",    count: 25,  progress: 8, measure: "dg"},
        {parent: 2, type: "hours",    title: "Timer",    count: 700, progress: 250, measure: "hrs"},
        {parent: 2, type: "consume",  title: "Sand",     count: 2000,progress: 1800, measure: "kg"},
        {parent: 3, type: "progress", title: "Kvadrat (<-dailylog)",  count: 200, progress: 150, measure: "m2", stroke: "red"},
        {parent: 3, type: "days",     title: "Dager (<-teller f.start)",    count: 10,  progress: 17, measure: "dg"},
        {parent: 3, type: "hours",    title: "Timer (<-tidsbanken)",    count: 500, progress: 900, measure: "hrs"},
        {parent: 3, type: "consume",  title: "Maling (<-dailylog)",   count: 170, progress: 80, measure: "ltr"},
        {parent: 5, type: "progress", title: "Kvadrat",  count: 300, progress: 150, measure: "m2", stroke: "red"},
        {parent: 5, type: "days",     title: "Dager",    count: 17,  progress: 8, measure: "dg"},
        {parent: 5, type: "hours",    title: "Timer",    count: 700, progress: 250, measure: "hrs"},
        {parent: 5, type: "consume",  title: "Sand",     count: 2000,progress: 1800, measure: "kg"},
      ],
    };
  },
  computed: {    
    minmaxdate: function() {
      var min = undefined;
      var max = undefined;
      this.accounting.forEach(elem => {
        if (elem.section === 'jobpkg' || elem.section === 'activity' ) {
          if (this.$func.IsValidDate(elem.frdt) && (new Date(elem.frdt) < min || min == undefined)) {min = new Date(elem.frdt)}
          if (this.$func.IsValidDate(elem.todt) && (new Date(elem.todt) > max || max == undefined)) {max = new Date(elem.todt)}
        }
      })
      return {min: min, max: max, days: this.$func.NumberOfDays(min, max)}
    },
    jobpackages: function() {
      let arr = this.accounting.filter(function (el) {return el.section == "jobpkg";})
      arr = arr.sort((a, b) => { return a.orderint - b.orderint; });
      return arr
    },
    activities: function() {
      let arr = this.accounting.filter(function (el) {return el.section == "activity";})
      arr = arr.sort((a, b) => { return a.orderint - b.orderint; });
      //console.log(arr)
      return arr
    },
    gantt: function() {
      let arr = this.accounting.filter(function (el) {return el.section == "activity" || el.section == "jobpkg";})
      arr = arr.sort((a, b) => { return a.orderint - b.orderint; });
      // console.log(arr)
      return arr
    },
    donutseries: function() {
      return [
        this.sumAccountingFilterReduce({filters: {section: ['ledger'], classification: ['goods']}, sum: ['amount']}),
        this.sumAccountingFilterReduce({filters: {section: ['ledger'], classification: ['access']}, sum: ['amount']}),
        this.sumAccountingFilterReduce({filters: {section: ['ledger'], classification: ['personnel']}, sum: ['amount']}),
        this.sumAccountingFilterReduce({filters: {section: ['ledger'], classification: ['travelacco']}, sum: ['amount']}),
        this.sumAccountingFilterReduce({filters: {section: ['ledger'], classification: ['consumable']}, sum: ['amount']}),
        this.sumAccountingFilterReduce({filters: {section: ['ledger'], classification: ['tuut']}, sum: ['amount']}),
        this.sumAccountingFilterReduce({filters: {section: ['ledger'], classification: ['transport']}, sum: ['amount']}),
        -this.sumAccountingFilterReduce({filters: {section: ['ledger']}, sum: ['amount']}),
      ];
    },
    aga: function() {
      var val = this.sumAccountingWithFilterOnCalcField("aga", 1)
      this.valueToAccountingRow("personnel", "LONN_AGA", val);
      return val;
    },
    otp: function() {
      var val = this.sumAccountingWithFilterOnCalcField("otp", 1)
      this.valueToAccountingRow("personnel", "LONN_OTP", val);
      return val;
    },
    fp: function() {
      var val = this.sumAccountingWithFilterOnCalcField("fp", 1)
      this.valueToAccountingRow("personnel", "LONN_FP", val);
      return val;
    },
    afp: function() {
      var val = this.sumAccountingWithFilterOnCalcField("afp", 1)
      this.valueToAccountingRow("personnel", "LONN_AFP", val);
      return val;
    },
    rot: function() {
      var val = this.sumAccountingWithFilterOnCalcField("rot", 1)
      this.valueToAccountingRow("personnel", "LONN_ROT", val);
      return val;
    },
    rti: function() {
      var val = this.sumAccountingWithFilterOnCalcField("rti", 1)
      this.valueToAccountingRow("personnel", "LONN_RTI", val);
      return val;
    },
    totalHours: function() {
      return this.sumAccountingFilterReduce({filters: {section: ['ledger'], classification: ['personnel'], measure1: ['timer']}, sum: ['countint', '*', 'durationam', '*', 'consume1am']})
    }
  },
  methods: {
    updateRevenueMargin() {
      if (this.accounting.length === 0) {
        return 0;
      }
      var val = this.accounting.reduce(function(total, item) {return item.section === 'ledger' && item.classification !== 'revenue' ? total + item.amount : total; }, 0);
      val = -val / (100 - this.margin) * 100
      this.valueToAccountingRow("revenue", "omsetning", val);
    },
    updateRevenueFixed() {
      this.valueToAccountingRow("revenue", "omsetning", -Math.abs(this.revenuefixed));
    },
    save() {

      if (this.accounting.length == 0) {return}

      // Assign value for parent
      const jobpkgs =  this.accounting.filter(function (item){ return item.section === 'jobpkg'});
      const lookup = jobpkgs.reduce((result, item) => {result[item.xid] = item.title; return result;}, {});      
      this.accounting.forEach((element, index) => {
        if (element.section == 'activity' ) {this.accounting[index].parent = lookup[element.classification];}
        if (element.section == 'jobpkg' ) {this.accounting[index].parent = element.title;}
      });

      this.loading = true

      // Ready parameters for post
      const params = new URLSearchParams();
      params.append("company", this.$store.state.activecompany);
      params.append("pid", this.bidselected.xid);
      params.append("bcid", this.bcid);
      params.append("items", JSON.stringify(this.accounting));

      this.axios.post("/api/bid/calc", params).then(
        (response) => {
          console.log(response.status);
          this.$showMessage({ content: "Lagret", color: "info" });
          this.loading = false
        },
        (error) => {
          this.$showMessage({ content: error, color: "error" });
        }
      );
    },    
    bidSelected: function(val) {
      this.bidselected = val
      var self = this;
      //console.log(val)
      //console.log(val.xid)
      this.requestJSON2('/api/bid/component', { id: "c4dvgi86n88hi80i3mqg", scope: "bcrecords", projectid: val.xid} )
      .then(function(e) { 
        //console.log(e)
        self.accounting = e[Object.keys(e)[0]] || []
      });      
    },
    deleterow: function(id) {
      for (let index = 0; index < this.accounting.length; ++index) {
        if (this.accounting[index].xid === id) {
          this.accounting.splice(index, 1);     
          break;     
        }
      }
    },
    move: function(section, classification, orderint, direction) {
      //console.log(section, classification, orderint, direction)
      if (classification === undefined) {classification == '';}
      var map = []
      // Loop array
      for (let index = 0; index < this.accounting.length; ++index) {
        // Filter on section
        if (this.accounting[index].section == section && this.accounting[index].classification == classification) {
          // Log to map matching sections
          if (this.accounting[index].orderint == orderint) {
            map.push({i: index, o: this.accounting[index].orderint + direction} ) 
          } else {
            map.push({i: index, o: this.accounting[index].orderint}) 
          }
        }
      }
      //console.log(map)
      // Sort map by new orderint
      map.sort((a, b) => (a.o > b.o) ? 1 : -1)
      // Re-create sort number
      for (let index = 0; index < map.length; ++index) {
        this.accounting[map[index].i].orderint = index
      }
    }, 
    nyJobbpakke() {
      var self = this;
      this.$newXID().then(function(res) {
        var orderint = self.accounting.filter((obj) => obj.section === 'jobpkg').length || 0;
        self.accounting.push({xid: res.xid, orderint: orderint, section: "jobpkg", title: undefined, comment: undefined, frdt: undefined, todt: undefined, classification: undefined, disable: 0 })
      })
    },
    openDialog(id) {
      //console.log(id)
      this.isActivity = undefined;
      if (id !== undefined) {
        this.isActivity = id;
      }
      //console.log(this.dialogprice)
      this.dialog = true;
    },
    // Insert new row to accounting
    newAccountingRow: function(item) {

      var meta = {};
      if (IsJsonString(item.meta)) {
        meta = JSON.parse(item.meta);
      }

      if (this.isActivity !== undefined) {
        item.classification = this.isActivity;
      }

      var elem = {
        // pk:
        classification: item.classification,
        category: item.category,
        sup_id: item.sup_id,
        lineid: meta.lineid || item.lineid,
        title: item.title,

        countint: 1,
        priceam: item.priceam,
        pricemeasure: meta.price,
        calcpricepr: meta.count,

        consume1am: meta.dconsume1 || this.defaultFiller("consume1am", item),
        measure1: item.pricemeasure,
        consume1measure: meta.consume1,

        consume2am: meta.dconsume2 || this.defaultFiller("consume2am", item),
        measure2: meta.measure2,
        consume2measure: meta.consume2,

        durationam: meta.duration !== undefined ? this.minmaxdate.days : 0, //this.defaultFiller("duration", item),

        amount: 0,

        calc: meta.calc || "",
        durationmeasure: meta.duration,
        co2am: item.co2am,

        acnoint: meta.acno || 0,
        disable: meta.disable || 0,

        orderint: this.accounting.filter((obj) => obj.classification === item.classification).length
      };

      if (this.isActivity !== undefined) {
        elem.section = "activity";
      } else {
        elem.section = "ledger";
      }

      var self = this;
      this.$newXID().then(function(res) {
        elem.xid = res.xid;
        //self.accounting.unshift(elem);
        self.accounting.push(elem);
      })


      //console.log(meta)
      //console.log(elem)
    },
    closeDialog: function() {
      this.dialog = false;
      this.panel = undefined;
    },
    getTabSection(ref) {
      this.tab = ref.substring(1);
      for (let i = 0; i < this.tabs.length; i++) {
        if (this.tabs[i].ref == ref) {
          this.sectionTitle = this.tabs[i].title;
          break;
        }
      }
    },
    sumAccountingWithFilterOnCalcField(filter, factor) {
      var val = this.accounting.reduce(function(total, item) {
        return "calc" in item && item.calc.includes(filter)
          ? total + Math.round(item.amount * factor)
          : total;
      }, 0);
      return val
    },
    sumAccountingFilterReduce(obj) {
      if (this.accounting.length === 0) {
        return 0;
      }

      // List with only the filtered rows - obj.filter
      var flist = this.accounting.filter(d => {
        return Object.keys(obj.filters).every(f => {
          return obj.filters[f].length < 1 || obj.filters[f].includes(d[f]);
        });
      });

      // Reduce to sum - obj.sum
      /*
      const total = flist.reduce((sum, currentValue) => {
        return sum + currentValue[obj.sum[0]];
      }, 0);   
      */
      
      var sum = 0;
      flist.forEach(row => {
        var dsum = 0
        var arit = ''
        obj.sum.forEach(col => {
          // Check if only arithmetic column
          if (col == '*') {arit = '*'; return;}
          if (col == '+') {arit = '+'; return;}

          // Calculate
          if (arit == '*') {dsum = dsum * row[col]; arit = ''}
          else if (arit == '+') {dsum = dsum + row[col]; arit = ''}
          else {dsum = row[col]}
        })
        sum += dsum
      })
      return sum
    },
    valueToAccountingRow: function(classification, lineid, val) {
      val = Math.round(val)
      this.accounting.forEach(function(item) {
        if (item.classification === classification && item.lineid === lineid) {
          item.countint = 1;
          item.priceam = val;
        }
      });
    },
    // Fill with default numbers when inserting new row
    defaultFiller: function(column, item) {
      for (const def of this.defaults) {
        if (
          def.column == column &&
          item.classification == def.classification &&
          (item.category == def.category || def.category == "*") 
        ) {
          return def.val;
        }
      }
      return 0;
    },
    // Retrieve default prices from DB
    getPrices: function() {
      var self = this;
      this.requestJSON2("/api/bid/component", {
        id: "c4dvgi86n88hi80i3mqg",
        scope: "prices"
      }).then(function(e) {
        self.prices = e[Object.keys(e)[0]];

        // Prepare object
        var p = {};

        // Get unique classifications
        /*
        const uniqueClassification = [...new Set(e[Object.keys(e)[0]].map(d => d.classification))];
        uniqueClassification.forEach(function(item) {
          p[item] = []
        })
        */

        // Get unique classifications and category
        // https://stackoverflow.com/questions/38613654/javascript-find-unique-objects-in-array-based-on-multiple-properties
        var result = [];
        var seen = Object.create(null);
        result = e[Object.keys(e)[0]].filter(o => {
          var key = ["classification", "category"].map(k => o[k]).join("|");
          if (!seen[key]) {
            seen[key] = true;
            return true;
          }
        });
        result.sort((a, b) => (a.category > b.category ? 1 : -1));

        // Assign classification and category to object
        result.forEach(function(item) {
          if (!p.hasOwnProperty(item.classification)) {
            p[item.classification] = [];
          }
          p[item.classification].push({ category: item.category, items: [] });
        });

        // Assign all prices
        e[Object.keys(e)[0]].forEach(function(item) {
          for (var prop in p) {
            if (prop == item.classification) {
              p[prop].forEach(function(item2, index2) {
                if (item2.category == item.category) {
                  p[prop][index2].items.push(item);
                }
              });
              /*
              if (p[prop].forEach(function(item2, index2) {          
                if (item2.category == item.category) {
                  p[item.classification][index2].items.psuh(item2.item)
                }
              })
              */
            }
          }
          /*
            p[item.classification].forEach(function(item2, index2) {          
              if (item2.category == item.category) {
                p[item.classification][index2].items.psuh(item2.item)
              }
            })
            */
        });

        self.dialogprice = p;

        /*
        const uniqueClassCat = [...new Set(e[Object.keys(e)[0]].map(d => {classification: d.classification, category: d.category}))];
        console.log(uniqueClassCat)
*/
        /*
        e[Object.keys(e)[0]].forEach(function(item){
          p[item.classification].push(item)
        })          
          console.log(p)
          */
        //this.$set(this.price, index, item);

        self.$forceUpdate();
      });
    },
    // Filters accounting before passing to component
    accountingListFilterClassification: function(l) {
      let arr = this.accounting.filter(function(u) {
        return u.classification == l;
      });
      arr = arr.sort((a, b) => { return a.orderint - b.orderint; });
      return arr
    },
    // Restate consume1am and durationam with new values
    fillDefaults: function() {
      for (const ac of this.accounting) {
        ac.consume1am = this.defaultFiller("consume1", ac)
        //ac.durationam = this.defaultFiller("duration", ac)
        /*
        for (const def of this.defaults) {
          if (
            def.column == "consume1am" &&
            ac.classification == def.classification &&
            ac.category == def.category
          ) {
            ac.consume1am = def.val;
          }
          if (
            def.column == "durationam" &&
            ac.classification == def.classification &&
            ac.category == def.category
          ) {
            ac.durationam = def.val;
          }
        }
        */
      }
    }
  }
};

function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

</script>
