<template>
  <v-container id="image" fluid>
    <v-card
      min-height="90%"
      class="ma-8"
      style="background: rgba(255, 255, 255, 0.5); backdrop-filter: blur(8px)"
    >
      <v-app-bar window color="transparent" flat dense>
        <v-btn color="grey darken-1" :to="{ name: 'home' }" icon>
          <v-icon>mdi-home-outline</v-icon>
        </v-btn>

        <v-toolbar-title class="grey--text text--darken-1 font-weight-medium">{{ drawer.title }}</v-toolbar-title>
        <v-spacer></v-spacer>

      <CompanyUser/>
      <FullscreenButtons/>

      </v-app-bar>

      <v-row no-gutters>
        <!-- KATEGORIER -->
        <v-col cols="2">
          <v-list style="background: transparent" dense shaped>
            <v-list-item-group v-model="item" color="blue darken-1">
              <v-list-item
                v-for="(item, i) in navitems"
                :key="i"
                @click="getsection(item.link)"
              >
                <!-- :to="item.link" -->
                <v-list-item-icon>
                  <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                  <v-icon v-else>mdi-chevron-right</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>

        <v-col cols="10">
          <v-sheet class="pa-3" style="background: transparent">
            <v-layout row wrap>
              <v-card
                class="ma-2"
                width="320"
                v-for="(items, propertyName, index) in navgroup"
                v-bind:key="index"
                color="rgba(255, 255, 255, 0.6)"
                flat
              >
                <v-card-title class="grey--text py-2">{{
                  propertyName
                }}</v-card-title>
                <!--<v-divider></v-divider>-->
                <v-list style="background: transparent">
                  <v-list-item-content class="py-0">
                    <v-list-item
                      two-line
                      v-for="item in items"
                      v-bind:key="item.id"
                      :to="item.link"
                    >
                    <v-list-item-icon>
                      <v-icon color="blue lighten-2" v-if="item.icon">{{ item.icon }}</v-icon>
                      <v-icon color="blue lighten-2" v-else>mdi-chevron-right-circle-outline</v-icon>
                    </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.subtitle
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-content>
                </v-list>
              </v-card>
            </v-layout>
          </v-sheet>
        </v-col>
      </v-row>
      <div>&nbsp;</div>
    </v-card>
  </v-container>
</template>

<style scoped>
#image {
  background-image: url("/5e5881f70d6391bff9df23cfa907e1cd9621e365.jpg");
  background-size: cover;
  height: 100vh;
  resize: both;
  overflow: scroll;  
}
</style>

<script>

export default {
  /* computed: {navitems() {return this.$store.getters.navsection(this.$route.path);}, },*/

  /* watch: { navitems(newValue, oldValue) { console.log(newValue, oldValue); }}, */
  methods: {
    getsection(s) {
      this.$store.commit("SET_NAVTREESELECTED", s);
      this.section = this.$store.getters.navsection(s);
      this.navgroup = this.$store.getters.navsection(s).groupBy("group");
    },
    rememberHistory() {
      // Get nav link from previous page view
      var prev = this.$store.getters.navtreeselected;
      // Loop current tree for match
      for (var key in this.navitems) {
        if (this.navitems[key].link == prev) {
          // Match, will set active class and trigger click / get
          this.item = parseInt(key);
          this.getsection(this.navitems[key].link);
        }
      }
      // If no match, select first nav section
      if (this.item === null) {
        this.item = 0;
        this.getsection(this.navitems[0].link);
      }
    },
  },
  mounted: function () {
    this.rememberHistory();
  },
  beforeMount: function () {
    this.drawer.title = this.$store.getters.pagedrawer(this.$route.path);
    this.drawer.subtitle = this.$store.getters.pagedrawersub(this.$route.path);
    this.navitems = this.$store.getters.navsection(this.$route.path);
  },
  data() {
    return {
      drawer: { title: null },
      section: [],
      item: null,
      navgroup: {},
      navitems: [],
      mini: false,
    };
  },
};

// https://www.consolelog.io/group-by-in-javascript/
Array.prototype.groupBy = function (prop) {
  return this.reduce(function (groups, item) {
    const val = item[prop];
    groups[val] = groups[val] || [];
    groups[val].push(item);
    return groups;
  }, {});
};
</script>