<template>
  <v-card >
    <v-card-title>{{ title }}</v-card-title>
    <v-card-actions>
      <v-card-subtitle> Kopier & Lim inn bilde (ctrl + v). </v-card-subtitle>
      <v-spacer></v-spacer>
      <v-btn color="orange lighten-2" text @click="clear"> Fjern </v-btn>
      <v-btn color="orange lighten-1" text @click="upload"> Last opp </v-btn>
    </v-card-actions>
    <v-divider></v-divider>
    <v-row>
      <v-col>
        <canvas :src="image" style="background: #fafafa; width: 100%" id="mycanvas" ref="canv"></canvas>
      </v-col>  
      <v-col v-if="autoreload !== 'false'">
        <div style="background: #E3F2FD; width: 100%">
        <img :src="image"  />
        </div>
      </v-col>  
    </v-row>
  </v-card>
</template>

<script>
/*eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
//import requestData from "@/mixins/requestData.js";

export default {
  //  mixins: [requestData],
  mounted: function () {
    this.pasteevent();
  },
  props: ["section", "title", "id", "autoreload"],
  data() {
    return {
      image: "",
    };
  },
  methods: {
    loadimage() {
      if (this.autoreload !== true) {
         return
      }
      this.clear();
      this.image = '/api/imagedownload?company='+this.$store.state.activecompany+'&id='+this.id+'&scope='+this.section
    },
    clear() {
      var c = this.$refs["canv"];
      var $ = c.getContext("2d");
      c.width = window.innerWidth;
      c.height = window.innerHeight;
      $.clearRect(0, 0, c.width, c.height);
      this.image = ""
    },
    upload() {
      var self = this;
      var c = this.$refs["canv"];
      let formData = new FormData();
      //formData.append("requester", this.$route.name);

      // Company ID
      if (this.$store.state.activecompany.length < 10) {
        this.$showMessage({
          content: "Feil - mangler selskap-id",
          color: "error",
        });
        return;
      } else {
        formData.append("company", this.$store.state.activecompany);
      }

      // Object ID
      if (this.id.length < 10) {
        this.$showMessage({
          content: "Feil - mangler objekt-id",
          color: "error",
        });
        return;
      } else {
        formData.append("id", this.id);
      }

      // Seksjon
      if (this.section.length < 3) {
        this.$showMessage({
          content: "Feil - mangler seksjon",
          color: "error",
        });
        return;
      } else {
        formData.append("scope", this.section);
      }

      //
      c.toBlob(function (blob) {
        // File content
        if (blob.size < 2000) {
          self.$showMessage({
            content: "Feil - mangler bilde",
            color: "error",
          });
          return;
        } else {
          formData.append("file", blob);
        }

        self.axios
          .post("/api/imageupload", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            console.log(response.status);
            self.$emit('uploaded', 'true');
            self.loadimage();
            self.$showMessage({ content: "Bilde lastet opp", color: "success"})
          });
      });
    },
    pasteevent() {
      window.addEventListener(
        "paste",
        function (e) {
          // Handle the event
          retrieveImageFromClipboardAsBlob(e, function (imageBlob) {
            // If there's an image, display it in the canvas
            if (imageBlob) {
              //var canvas = self.$refs["canv"];
              var canvas = document.getElementById("mycanvas");
              var ctx = canvas.getContext("2d");

              // Create an image to render the blob on the canvas
              var img = new Image();

              // Once the image loads, render the img on the canvas
              img.onload = function () {
                // Update dimensions of the canvas with the dimensions of the image
                canvas.width = this.width;
                canvas.height = this.height;

                // Draw the image
                ctx.drawImage(img, 0, 0);
              };

              // Crossbrowser support for URL
              var URLObj = window.URL || window.webkitURL;

              // Creates a DOMString containing a URL representing the object given in the parameter
              // namely the original Blob
              img.src = URLObj.createObjectURL(imageBlob);
            }
          });
        },
        false
      );
    },
    /*
    paste5() {
      window.addEventListener(
        "paste",
        function (e) {
          // Handle the event
          retrieveImageFromClipboardAsBase64(e, function (imageDataBase64) {
            // If there's an image, open it in the browser as a new window :)
            if (imageDataBase64) {
              // data:image/png;base64,iVBORw0KGgoAAAAN......
              window.open(imageDataBase64);
            }
          });
        },
        false
      );
    },
    paste3() {
      document.onpaste = function (event) {
        var items = event.clipboardData.items;
        console.log(JSON.stringify(items)); // will give you the mime types
        var blob = items[0].getAsFile();
        var reader = new FileReader();
        reader.onload = function (event) {
          console.log(event.target.result);
        }; // data url
        reader.readAsDataURL(blob);
      };
    },
    paste2() {
      var self = this;
      document.onpaste = function (pasteEvent) {
        var item = pasteEvent.clipboardData.items[0];
        if (item.type.indexOf("image") === 0) {
          var blob = item.getAsFile();

          var reader = new FileReader();
          reader.onload = function (event) {
            var c = self.$refs["canv"];
            c.src = event.target.result;
          };

          reader.readAsDataURL(blob);
        }
      };
    },
    paste1() {
      // https://codepen.io/dimmby/pen/QRdGxL
      //var c = document.getElementById("canv");
      var c = this.$refs["canv"];
      var $ = c.getContext("2d");
      c.width = window.innerWidth;
      c.height = window.innerHeight;
      //var imgURL = "https://s3-us-west-2.amazonaws.com/s.cdpn.io/131045/colorful-triangles.jpg";
      //loadImage(imgURL);
      console.log(c.width, c.height);

      window.addEventListener("paste", function (e) {
        if (e.clipboardData == false) return false;
        var imgs = e.clipboardData.items;
        if (imgs == undefined) return false;
        for (var i = 0; i < imgs.length; i++) {
          if (imgs[i].type.indexOf("image") == -1) continue;
          var imgObj = imgs[i].getAsFile();
          var url = window.URL || window.webkitURL;
          var src = url.createObjectURL(imgObj);
          $.clearRect(0, 0, c.width, c.height);
          loadImage(src);
        }
      });

      function loadImage(src) {
        var img = new Image();
        img.onload = function () {
          $.drawImage(img, 0, 0);
        };
        img.src = src;
      }
    },*/
  },
};

/**
 * This handler retrieves the images from the clipboard as a blob and returns it in a callback.
 *
 * @param pasteEvent
 * @param callback
 */
function retrieveImageFromClipboardAsBlob(pasteEvent, callback) {
  if (pasteEvent.clipboardData == false) {
    if (typeof callback == "function") {
      callback(undefined);
    }
  }

  var items = pasteEvent.clipboardData.items;

  if (items == undefined) {
    if (typeof callback == "function") {
      callback(undefined);
    }
  }

  for (var i = 0; i < items.length; i++) {
    // Skip content if not image
    if (items[i].type.indexOf("image") == -1) continue;
    // Retrieve image on clipboard as blob
    var blob = items[i].getAsFile();

    if (typeof callback == "function") {
      callback(blob);
    }
  }
}

/**
 * This handler retrieves the images from the clipboard as a base64 string and returns it in a callback.
 *
 * @param pasteEvent
 * @param callback
 */
/*
function retrieveImageFromClipboardAsBase64(pasteEvent, callback, imageFormat) {
  if (pasteEvent.clipboardData == false) {
    if (typeof callback == "function") {
      callback(undefined);
    }
  }

  var items = pasteEvent.clipboardData.items;

  if (items == undefined) {
    if (typeof callback == "function") {
      callback(undefined);
    }
  }

  for (var i = 0; i < items.length; i++) {
    // Skip content if not image
    if (items[i].type.indexOf("image") == -1) continue;
    // Retrieve image on clipboard as blob
    var blob = items[i].getAsFile();

    // Create an abstract canvas and get context
    var mycanvas = document.createElement("canvas");
    var ctx = mycanvas.getContext("2d");

    // Create an image
    var img = new Image();

    // Once the image loads, render the img on the canvas
    img.onload = function () {
      // Update dimensions of the canvas with the dimensions of the image
      mycanvas.width = this.width;
      mycanvas.height = this.height;

      // Draw the image
      ctx.drawImage(img, 0, 0);

      // Execute callback with the base64 URI of the image
      if (typeof callback == "function") {
        callback(mycanvas.toDataURL(imageFormat || "image/png"));
      }
    };

    // Crossbrowser support for URL
    var URLObj = window.URL || window.webkitURL;

    // Creates a DOMString containing a URL representing the object given in the parameter
    // namely the original Blob
    img.src = URLObj.createObjectURL(blob);
  }
}*/
</script>