<template>
  <v-container fluid class="pa-0">
    <v-app-bar color="blue-grey darken-3" hide-on-scroll dark>
      <v-btn :to="{ name: 'home' }" icon>
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-btn @click="$goBack" icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-divider inset vertical></v-divider>
      <v-row class="py-0 mt-7">
        <v-spacer></v-spacer>
        <v-col cols="2" sm="2" class="pt-1">
          <v-select
            dense
            outlined
            :value="$store.state.active.frequency"
            @input="SET_FREQUENCY"
            :items="$store.state.frequency"
            label="Frekvens"
          ></v-select>
        </v-col>
        <v-col cols="2" sm="2" class="pt-1">
          <v-select
            dense
            outlined
            :value="$store.state.active.history"
            @input="SET_HISTORY"
            :items="$store.state.history"
            label="Historikk"
          ></v-select>
        </v-col>
        <v-col cols="2" sm="2" class="pt-1">
          <yrpr-filter />
        </v-col>
      </v-row>
      <v-btn
        class="mx-2 mt-n0"
        color="blue lighten-2"
        outlined
        v-on:click="REQ_UPDATE"
        >Oppdater</v-btn
      >
      <v-btn text icon color="blue-grey lighten-2" @click="disableGrid">
        <span v-if="gridPreview"><v-icon>mdi-monitor-edit</v-icon></span>
        <span v-else><v-icon>mdi-monitor-lock</v-icon></span>
      </v-btn>
    </v-app-bar>

    <v-sheet class="white ma-3">
      <div
        class="headline text-uppercase font-weight-bold blue-grey--text text--darken-2 pa-2"
      >
        {{ $store.state.paths[$route.path].title }}
      </div>
      <!-- <div class="subtitle-1 blue-grey--text text--darken-1 font-italic font-weight-medium">{{page.subtitle}}</div>-->
      <!-- https://www.jenniferbland.com/how-to-add-charts-and-graphs-to-a-vue-application/ -->

      <grid-layout
        :layout.sync="layout"
        :col-num="12"
        :row-height="30"
        :is-draggable="gridIsDraggable"
        :is-resizable="gridIsResizable"
        :is-mirrored="false"
        :vertical-compact="true"
        :margin="[10, 10]"
        :use-css-transforms="false"
        :responsive="false"
        @layout-updated="layoutUpdatedEvent"
      >
        <!--<v-carousel height="600" show-arrows-on-hover>-->

        <grid-item
          style
          v-for="item in layout"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :key="item.i"
          :autoSize="true"
        >
          <!--<v-carousel-item>-->

          <v-sheet
            v-if="gridready && item.wtype == 'chart'"
            class="chart-wrapper-grid pa-2"
            elevation="2"
            tile
          >
            <widget-chart :options="fromData(item.i)" :autoresize="true" />
          </v-sheet>

          <v-sheet
            v-else-if="gridready && item.wtype == 'stat'"
            class="chart-wrapper-grid"
            style="overflow: hidden"
            elevation="2"
            tile
          >
            <widget-stat :obj="fromData(item.i)" />
          </v-sheet>

          <v-sheet
            dark
            v-else-if="gridready && item.wtype == 'tabulator'"
            elevation="1"
            tile
          >

            <v-btn
              v-if="parameters.excel"
              outlined
              color="red"
              small
              @click="requestMedia($route.path, { media: parameters.excel, id: $route.params.page, ...$store.state.active })"
              class="ml-2 mt-2"
              >Excel<v-icon right>mdi-download</v-icon></v-btn
            >
            <!--
            <v-btn
              v-if="parameters.excel"
              outlined
              color="red"
              small
              @click="requestMediaTmp($route.path, { media: parameters.excel })"
              class="ml-2 mt-2"
              >Excel<v-icon right>mdi-download</v-icon></v-btn
            >-->
            <div class="pa-3">
              <span class="title">{{ widgets[item.i].title }}</span>
              <span
                class="subtitle-2 font-weight-black amber--text float-right mt-1"
                >{{ widgets[item.i].subtitle }}</span
              >
            </div>
            <VueTabulator
              ref="tabulatorref"
              v-model="widgets[item.i].data"
              :options="fromDataObj(item.i, 'options')"
            />
          </v-sheet>

          <v-sheet v-else elevation="2" class="pa-2" style="height: 100%">
          </v-sheet>

          <!--</v-carousel-item>-->
        </grid-item>
        <!--</v-carousel>-->
      </grid-layout>
    </v-sheet>
  </v-container>
</template>

<style lang='scss'>
//@import "~vue-tabulator/dist/scss/bootstrap/tabulator_bootstrap4";
//@import "~vue-tabulator/dist/scss/semantic-ui/tabulator_semantic-ui";
//@import "~vue-tabulator/dist/scss/tabulator_midnight";
//@import "~vue-tabulator/dist/scss/tabulator_modern";
//@import "~vue-tabulator/dist/scss/tabulator_simple";
@import "@/assets/tabulator_simple_gd";
//@import "~vue-tabulator/dist/scss/tabulator_site";
//@import "~vue-tabulator/dist/scss/tabulator";
</style>

<style>
/**
 * The default size is 600px×400px, for responsive charts
 * you may need to set percentage values as follows (also
 * don't forget to provide a size for the container).
 */
.echarts {
  width: 100%;
  height: 100%;
}

.chart-wrapper {
  width: 550px;
  height: 400px;
  padding: 5px;
}

.chart-wrapper-grid {
  width: 100%;
  height: 100%;
}
</style>

<script>
/*eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

/*
  VUE Grid layout 
  http://vue-grid-layout.surge.sh/#/
  https://github.com/Krutie/bugs-of-the-city/blob/master/src/components/editor/GridView.vue
  https://blog.prototypr.io/grid-layout-editor-for-vue-js-a-research-project-for-pariksha-io-e3445025d21e

  VUE ECHARTS
  https://www.jenniferbland.com/how-to-add-charts-and-graphs-to-a-vue-application/
*/

import ECharts from "vue-echarts";
import "echarts/lib/chart/treemap";
import { TabulatorComponent } from "vue-tabulator";
import requestmedia from "@/mixins/requestMedia.js";

/*
import "echarts/lib/chart/line";
import "echarts/lib/component/polar";
import "echarts/lib/component/toolbox";
*/
import WidgetStatistic from "@/components/WidgetStatistic.vue";
import VueGridLayout from "vue-grid-layout";

// Godt eksempel på bruk av vuex fra component:
// https://vue-select.org/guide/vuex.html

import YearMonthDropdown from "@/components/YearMonthDropdown.vue";
import requestData from "@/mixins/requestData.js";

export default {
  mixins: [requestData, requestmedia],
  components: {
    "widget-chart": ECharts,
    "widget-stat": WidgetStatistic,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    VueTabulator: TabulatorComponent,
    "yrpr-filter": YearMonthDropdown,
  },
  mounted: function () {
    this.requestData();
  },
  data() {
    return {
      gridIsDraggable: false,
      gridIsResizable: false,
      gridPreview: true,
      initOptions: {
        // renderer: "svg"
      },
      gridready: false,
      widgets: {},
      layout:
        JSON.parse(
          JSON.stringify(this.$store.getters.navbylink(this.$route.path).layout)
        ) || [],
      drawer: null,
      parameters: {},
    };
  },
  methods: {
    disableGrid() { 
      this.gridIsDraggable = !this.gridIsDraggable;
      this.gridIsResizable = !this.gridIsResizable;
      this.gridPreview = !this.gridPreview;
      //this.contenteditable = !this.contenteditable
    },
    REQ_UPDATE() {
      this.requestData();
    },
    SET_FREQUENCY(val) {
      this.$store.commit("SET_FREQUENCY", val);
    },
    SET_HISTORY(val) {
      this.$store.commit("SET_HISTORY", val);
    },
    /*
    requestMediaTmp: function (o, b) {
      const req = { ...b, ...this.$store.state.active };
      this.requestMedia(o, req);
    },
    */
    requestData: function () {
      var reqUrl;
      if (this.$route.name == "economy/report") {
        reqUrl = "/api/economy/report";
      }
      if (this.$route.name == "bid/report") {
        reqUrl = "/api/bid/report";
      }
      if (this.$route.name == "equipment/report") {
        reqUrl = "/api/equipment/report";
      }

      var self = this;
      const req = {
        ...{ id: self.$route.params.page },
        ...{ company: this.$store.state.activecompany },
        ...this.$store.state.active,
      };
      this.axios
        .get(reqUrl, {
          params: req,
        })
        .then(function (response) {
          const keys = Object.keys(response.data);
          for (const key of keys) {
            // CHART
            if (response.data[key].wtype == "chart") {
              self.$set(self.widgets, key, self.ChartStandardOptions());
              if (
                typeof response.data[key].title &&
                response.data[key].title.text !== "undefined"
              ) {
                self.widgets[key].title.text = response.data[key].title.text;
              }
              if (
                typeof response.data[key].title &&
                response.data[key].title.subtext !== "undefined"
              ) {
                self.widgets[key].title.subtext =
                  response.data[key].title.subtext;
              }
              if (typeof response.data[key].xAxis !== "undefined") {
                // self.widgets[key].xAxis = response.data[key].xAxis;
                //console.log(response.data[key].xAxis)
                response.data[key].xAxis.forEach(function (element, i) {
                  self.widgets[key].xAxis[i] = {
                    ...self.widgets[key].xAxis[i],
                    ...element,
                  };
                });
                //let result = self.widgets[key].xAxis[0].data.map(date => new Date(date))
                //self.widgets[key].xAxis[0].data = result
                // Array.prototype.push.apply(self.widgets[key].xAxis, response.data[key].xAxis);
                // Array.prototype.push.apply(self.widgets[key].xAxis, response.data[key].xAxis);
                // self.widgets[key].xAxis[0] = self.widgets[key].xAxis[0].concat(response.data[key].xAxis[0])
                // console.log(self.widgets[key].xAxis)
              }
              if (typeof response.data[key].yAxis !== "undefined") {
                response.data[key].yAxis.forEach(function (element, i) {
                  self.widgets[key].yAxis[i] = {
                    ...self.widgets[key].yAxis[i],
                    ...element,
                  };
                });
              }
              // LEGEND
              if (typeof response.data[key].legend !== "undefined") {
                if (typeof response.data[key].legend.selected !== "undefined") {
                  self.widgets[key].legend = Object.assign(
                    self.widgets[key].legend,
                    { selected: JSON.parse(response.data[key].legend.selected) }
                  );
                }
              }
              if (typeof response.data[key].series !== "undefined") {
                self.widgets[key].series = response.data[key].series;
              }
            }

            // STAT
            if (response.data[key].wtype == "stat") {
              self.$set(self.widgets, key, {});
              self.widgets[key] = response.data[key];
            }

            // TABLE
            if (response.data[key].wtype == "tabulator") {
              self.$set(self.widgets, key, { options: {}, data: {} });
              self.widgets[key].title = response.data[key].title;
              self.widgets[key].subtitle = response.data[key].subtitle;
              if (response.data[key].hasOwnProperty("parameters")) {
                self.parameters = response.data[key].parameters;
              }
              (self.widgets[key].options = {
                ...response.data[key].options,
                ...{
                  rowFormatter: function (row) {
                    // Upper style
                    if (row.getData().style == "1") {
                      row.getElement().style.backgroundColor =
                        "rgba(243,243,243,0.8)";
                      //row.getElement().style.borderTop = "1px solid #BFECEA";
                      //row.getElement().style.borderBottom = "2px solid #BFECEA";
                      row.getElement().style.fontWeight = "700";
                      row.getElement().style.color = "black";
                    }
                    // Second level style
                    if (row.getData().style == "2") {
                      //row.getElement().style.backgroundColor = "rgba(243,243,243,0.8)";
                      row.getElement().style.fontWeight = "700";
                      row.getElement().style.color = "black";
                    }
                    // Percentage style
                    if (row.getData().style == "6") {
                      row.getElement().style.borderBottom =
                        "1px dashed #EDE7F6";
                      //row.getElement().style.backgroundColor = "#EDE7F6";
                      row.getElement().style.fontWeight = "700";
                      row.getElement().style.fontStyle = "italic";
                      row.getElement().style.color = "#757575";
                    }
                  },
                },
              }),
                (self.widgets[key].data = response.data[key].data);
              //console.log(self.widgets[key].data)
              //console.log(self.widgets[key].options)
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          // always executed
          self.gridready = true;
        });
      /* setTimeout(function() {
        self.widgets.bo8bcm2uof2ru8r9jdpg.color = ["#0277BD", "#11DAFA"];
      }, 20000);
      */
    },
    /*
    pageLayout: function() {
      // this.layout = this.$store.getters.navbylink(this.$route.path).layout
      // this.layout = JSON.parse(JSON.stringify(this.$store.getters.navbylink(this.$route.path).layout))
      // this.layout = testLayout
      // console.log(testLayout)
      // console.log(JSON.parse(JSON.stringify(this.$store.getters.navbylink(this.$route.path).layout)))      
    },
    */
    fromData: function (name) {
      return this.widgets[name];
    },
    fromDataObj: function (name, key) {
      return this.widgets[name][key];
    },
    layoutUpdatedEvent: function (newLayout) {
      console.log("New layout:", JSON.stringify(newLayout));
    },
    ChartStandardOptions() {
      return {
        animation: false,
        // color: ["#039BE5", "#81D4FA"],
        textStyle: {
          color: "#757575",
          fontFamily: ["Roboto", "monospace", "serif"],
        },
        toolbox: {
          show: false,
          // y: 'bottom',
          feature: {
            magicType: {
              type: ["stack", "tiled"],
            },
            dataView: {},
            saveAsImage: {
              pixelRatio: 2,
            },
          },
        },
        title: {
          text: "-",
          textStyle: {
            color: "#585858",
            fontWeight: "500",
            fontSize: 20,
          },
        },
        legend: {
          right: 10,
          top: 5,
        },
        backgroundColor: "#fff",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            nameTextStyle: {
              align: "center",
            },
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              lineStyle: {
                color: "#BDBDBD",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              lineStyle: {
                color: "#BDBDBD",
              },
            },
          },
        ],
      };
    },
  },
};
</script>