<template>
  <v-sheet rounded class="pa-3">
    <v-toolbar light flat class="mb-3" dense>
      <span v-for="(header, i) in options" :key="i">
        <div v-if="filters.hasOwnProperty(header.value)" class="pl-2">
          <v-autocomplete chips clearable deletable-chips dense multiple small-chips solo-inverted hide-details flat :label="header.text" :items="columnValueList(header.value)" v-model="filters[header.value]">
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 3" color="purple lighten-4">
                <span>
                  {{ item }}
                </span>
              </v-chip>
              <span v-if="index === 3" class="grey--text caption">
                (+{{ filters[header.value].length - 3 }} flere)
              </span>
            </template>
          </v-autocomplete>
        </div>
      </span>
    </v-toolbar>
    <div v-if="gridready">
    <apexchart type="bar" height="350" :options="chartOptions" :series="seriesPlannedInvoice"></apexchart>
    <apexchart type="treemap" height="350" :options="chartOptions2" :series="seriesCustomerInvoice"></apexchart>
    <apexchart type="treemap" height="350" :options="chartOptions2" :series="seriesProductInvoice"></apexchart>
    <!--
       Treemap with salescategory, customers
    -->
    <span class="caption purple--text text--lighten-2" v-if="readat">Data lest {{new Date(readat*1000).toLocaleDateString("nb-NO")}} {{new Date(readat*1000).toLocaleTimeString("nb-NO")}}</span>
    </div>
  </v-sheet>
</template>

<script>
/*eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
import ApexCharts from "vue-apexcharts";
import requestData from "@/mixins/requestData.js";
import nb from "apexcharts/dist/locales/nb.json";

export default {
  mixins: [requestData],
  components: {
    apexchart: ApexCharts
  },
  created: function() {
  },
  mounted: function() {
    this.requestData();
  },
  computed: {
    filteredItems() {
      /* https://codepen.io/wolfpup/pen/gOadmPx */
      return this.items.filter(d => {
        return Object.keys(this.filters).every(f => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
    }
  },
  watch: {
    filteredItems() {
      this.prepChart();
    }
  },
  methods: {
    columnValueList(val) {
      /* https://codepen.io/wolfpup/pen/gOadmPx */
      // filteredItems will narrow down other filters to selected, alternativ use items
      var unique = [...new Set(this.items.map(d => d[val] || ""))];
      //var unique = [...new Set(this.filteredItems.map(d => d[val] || ""))];
      // Remove empty
      unique = unique.filter(function(el) {
        return el != "";
      });
      return unique.sort();
    },
    prepChart: function() {

       // Planned invoice timeline
      var PlannedInvoice = {};
      this.filteredItems.forEach(function(item) {
        if (!item.hasOwnProperty("plannedDelDt")) {return;}
        if (!item.hasOwnProperty("ram")) {return;}
        if (!PlannedInvoice.hasOwnProperty(item.dimProNm)) {PlannedInvoice[item.dimProNm] = [];}
        PlannedInvoice[item.dimProNm].push({ x: item.plannedDelDt, y: item.ram });
      });

      //ar.sort((a, b) => new Date(a.x).getTime() - new Date(b.x).getTime());
      var arrPlannedInvoice = [];
      for (var k in PlannedInvoice) {
        arrPlannedInvoice.push({ name: k, data: PlannedInvoice[k] });
      }
      this.seriesPlannedInvoice = arrPlannedInvoice;

    // Customer categories
      var CustomerInvoice = {};
      this.filteredItems.forEach(function(item) {
        if (!item.hasOwnProperty("customerNm")) {return;}
        if (!item.hasOwnProperty("ram")) {return;}
        if (!CustomerInvoice.hasOwnProperty(item.customerNm)) {CustomerInvoice[item.customerNm] = [];}
        CustomerInvoice[item.customerNm].push( item.ram );
      });
      var arrCustomerInvoice = [];
      for (var l in CustomerInvoice) {
        arrCustomerInvoice.push({ x: l, y: CustomerInvoice[l].reduce((a, b) => a + b) });
      }
      this.seriesCustomerInvoice = [{data: arrCustomerInvoice}];

    // Product categories
      var ProductInvoice = {};
      this.filteredItems.forEach(function(item) {
        if (!item.hasOwnProperty("productCat")) {return;}
        if (!item.hasOwnProperty("ram")) {return;}
        if (!ProductInvoice.hasOwnProperty(item.productCat)) {ProductInvoice[item.productCat] = [];}
        ProductInvoice[item.productCat].push( item.ram );
      });
      var arrProductInvoice = [];
      for (var m in ProductInvoice) {
        arrProductInvoice.push({ x: m, y: ProductInvoice[m].reduce((a, b) => a + b) });
      }
      this.seriesProductInvoice = [{data: arrProductInvoice}];


    },
    requestData: function() {
      var self = this;
      const obj = {
        id: self.$route.params.page,
        company: this.$store.state.activecompany,
        format: "datatable"
      };
      //console.log(obj)
console.log(this.$route.name)
      this.requestJSON2("/api/" + this.$route.name, obj).then(function(e) {
        const resp = e[Object.keys(e)[0]];
        resp.data.forEach(function(item) {
          var newData = item.plannedDelDate.replace(/(\d+[.])(\d+[.])/, "$2$1");
          if (Date.parse(newData) < 0) {
            return;
          } else {
            item.plannedDelDt = newData;
          }
        });

        self.title = resp.title;
        self.chartOptions.title.text = resp.title
        self.items = resp.data || [];
        self.options = resp.options || [];

          if (typeof resp.parameters["readat"] !== "undefined" && resp.parameters["readat"] > 0) {
            self.readat = resp.parameters["readat"]
          }

        /*
        if (typeof resp.parameters !== "undefined") {
          if (typeof resp.parameters["groupby"] !== "undefined") {
            self.groupby = resp.parameters["groupby"];
          }
          if (
            typeof resp.parameters["refresh"] !== "undefined" &&
            resp.parameters["refresh"]
          ) {
            self.refresher = true;
          }
        }
      */
        self.options.forEach(function(item) {
          if (item.dropfilter !== "undefined" && item.dropfilter === true) {
            self.$set(self.filters, item.value, []);
          }
        });
        self.gridready = true
      });
    }
  },
  data() {
    return {
      title: "",
      items: [],
      options: [],
      filters: {},
      gridready: false,
      readat: false,

      seriesPlannedInvoice: [],
      seriesCustomerInvoice: [],   
      seriesProductInvoice: [],   

          chartOptions2: {
            legend: {
              show: false
            },
            chart: {
              height: 350,
              type: 'treemap'
            },
            title: {
              text: 'Ordrer pr kunde'
            },
            colors: [
              '#3B93A5',
              '#F7B844',
              '#ADD8C7',
              '#EC3C65',
              '#CDD7B6',
              '#C1F666',
              '#D43F97',
              '#1E5D8C',
              '#421243',
              '#7F94B0',
              '#EF6537',
              '#C0ADDB'
            ],
            plotOptions: {
              treemap: {
                distributed: true,
                enableShades: false
              }
            }            
          },

      chartOptions: {
         title: {
            text: '',
            align: 'left'
         },           
        chart: {
          locales: [nb],
          defaultLocale: "nb",
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: false
            }
          },
          background: "#fff",
          stacked: true,
          animations: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%"
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          type: "datetime"
        },
        yaxis: {
          title: {
            text: "Tkr"
          },
          labels: {
            formatter: function(val) {
              return (val / 1000)
                .toFixed(0)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
            }
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return (
                (val / 1000)
                  .toFixed(0)
                  .replace(".", ",")
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " tkr"
              );
            }
          }
        }
      }
    };
  }
};
</script>    