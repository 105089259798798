
<template>
  <!-- Dersom VueTabulator komponenten ligger i root, så vil overflow fungere som det skal   -->
  <v-sheet v-if="widget" rounded class="pa-3" style="height: 99%">
    <div>
      <h3 class="float-left font-weight-medium grey--text text--darken-1 pb-2">
        {{ widget.title }}
      </h3>

      <!--
  <v-card flat v-if="widget" style="height: calc(100% - 32px); overflow: auto">
    <v-card-title class="grey--text text--darken-1 py-2"
      >{{ widget.title }} -->
      <v-spacer></v-spacer>
      <div class="float-right">
        <v-btn
          v-if="widget.parameters.newrow"
          outlined
          color="teal"
          small
          @click="addRow()"
          >Ny rad<v-icon right>mdi-table-row-plus-after</v-icon></v-btn
        >
        <v-btn
          v-if="widget.parameters.excel"
          outlined
          color="teal"
          small
          @click="
            requestMedia($route.path, {
              media: widget.parameters.excel,
              id: pageid,
            })
          "
          class="ml-2"
          >Excel<v-icon right>mdi-download</v-icon></v-btn
        >
        <v-btn
          v-if="widget.parameters.json"
          outlined
          color="teal"
          small
          @click="
            requestMedia($route.path, {
              media: widget.parameters.json,
              id: pageid,
            })
          "
          class="ml-2"
          >JSON<v-icon right>mdi-code-json</v-icon></v-btn
        >
      </div>
    </div>

    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      bottom
      height="3"
      background-color="teal lighten-5"
      color="deep-purple accent-4"
      style="height: 3px"
      class="mb-2"
    ></v-progress-linear>
    <div style="height: calc(98% - 32px); overflow: auto">
      <VueTabulator
        ref="tabulator"
        v-model="widget.data"
        :options="options"
        @cell-edited="cellEdited"
        @cell-click="cellClick"
      />
    </div>
  </v-sheet>
</template>

<script>
/*eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { TabulatorComponent } from "vue-tabulator";
import tabulatoredit from "@/mixins/tabulatorEdit.js";
import requestmedia from "@/mixins/requestMedia.js";

export default {
  components: { VueTabulator: TabulatorComponent },
  mixins: [tabulatoredit, requestmedia],
  props: ["wid", "widget", "options", "equipmentid", "pageid"],
  /*
  mounted: function() {
    const tabulatorInstance = this.$refs.tabulator.getInstance();
window.addEventListener('resize', function(){
    tabulatorInstance.redraw(true); //trigger full rerender including all data and rows
});    
    setTimeout(function(){ 
        tabulatorInstance.redraw(true);   
        console.log("draw")
    }, 1000);    
  },
  */
  data() {
    return {
      loading: false,
    };
  },
};
</script>
