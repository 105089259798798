<template>
  <v-sheet color="white" class="pt-2" rounded >    
      <div class="float-right pr-2">
        <span class="caption purple--text text--lighten-2" v-if="readat">Data lest {{new Date(readat*1000).toLocaleDateString("nb-NO")}} {{new Date(readat*1000).toLocaleTimeString("nb-NO")}}</span>
        <v-btn v-if="refresher" outlined small color="purple" @click="refresh()" class="ml-2">Oppdater<v-icon right>mdi-cloud-refresh</v-icon></v-btn>
      </div>

      <dropdown-filters :data="widget.data" v-on:dropdownFiltersChanged="newFiltersFromChild"></dropdown-filters>

  <v-data-table
    :headers="widget.options"
    :items="filteredItems"
    :items-per-page="-1"
    hide-default-footer
  >
    <template v-slot:[`item.project`]="{ item }">
      {{ item.project }}<span class="float-right"><v-chip small label color="blue lighten-5">{{ item.segm}}</v-chip> <v-chip small label color="blue lighten-5">{{ item.pm }}</v-chip></span>
      <v-progress-linear background-color="blue lighten-4" color="blue lighten-2" :value="item.progr"></v-progress-linear>
    </template>  

    <template v-slot:[`item.calcint`]="{ item }">
            <span v-if="item.calcint > 0"><v-badge offset-y="12" color="lime" :content="item.calcint"><v-icon color="light-green darken-1">mdi-emoticon-cool-outline</v-icon></v-badge></span>
            <span v-else><v-icon color="pink lighten-2">mdi-emoticon-frown-outline</v-icon></span>
    </template>  
    <template v-slot:[`item.equpint`]="{ item }">
            <span v-if="item.equpint > 0"><v-badge offset-y="12" color="lime" :content="item.equpint"><v-icon color="light-green darken-1">mdi-emoticon-cool-outline</v-icon></v-badge></span>
            <span v-else><v-icon color="pink lighten-2">mdi-emoticon-frown-outline</v-icon></span>
    </template>  
    <template v-slot:[`item.handint`]="{ item }">
            <span v-if="item.handint > 0"><v-badge offset-y="12" color="lime" :content="item.handint"><v-icon color="light-green darken-1">mdi-emoticon-cool-outline</v-icon></v-badge></span>
            <span v-else><v-icon color="pink lighten-2">mdi-emoticon-frown-outline</v-icon></span>
    </template>  
    <template v-slot:[`item.ordlnint`]="{ item }">
            <span v-if="item.ordlnint > 0"><v-badge offset-y="12" color="lime" :content="item.ordlnint"><v-icon color="light-green darken-1">mdi-emoticon-cool-outline</v-icon></v-badge></span>
            <span v-else><v-icon color="pink lighten-2">mdi-emoticon-frown-outline</v-icon></span>
    </template>  
    <template v-slot:[`item.evalint`]="{ item }">
            <span v-if="item.evalint > 0"><v-badge offset-y="12" color="lime" :content="item.evalint"><v-icon color="light-green darken-1">mdi-emoticon-cool-outline</v-icon></v-badge></span>
            <span v-else><v-icon color="pink lighten-2">mdi-emoticon-frown-outline</v-icon></span>
    </template>  

  </v-data-table>

  </v-sheet>
</template>

<script>
import requestData from "@/mixins/requestData.js";
import DropdownFilters from "@/components/DropdownFilters.vue";

export default {
  mixins: [requestData],
  components: {
    "dropdown-filters": DropdownFilters,
  },
  mounted: function () {
    this.requestData();
  },
  computed: {
    filteredItems() {
      if (this.widget.data === undefined) {
        return []
      }

      /* https://codepen.io/wolfpup/pen/gOadmPx */
      return this.widget.data.filter(d => {
        return Object.keys(this.filters).every(f => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
    }
  },
  methods: {
    newFiltersFromChild(obj) {
      this.filters = obj;
    },
    refresh() {
      this.requestData();
    },
    requestData: function () {
      var self = this;
      const req = {
        id: self.$route.params.page,
        company: this.$store.state.activecompany,
        media: "json",
        format: "datatable",
      };
      this.requestJSON2("/api/" + this.$route.name, req)
        .then(function (e) {
          var obj = {};
          if (e['c9ulli4r7ucevfka96k0'] !== undefined) {
            obj = e['c9ulli4r7ucevfka96k0']
          }
          self.widget = obj;
          if (
            typeof obj.parameters["readat"] !== "undefined" &&
            obj.parameters["readat"] > 0
          ) {
            self.readat = obj.parameters["readat"];
          }
          if (
            typeof obj.parameters["refresh"] !== "undefined" &&
            obj.parameters["refresh"]
          ) {
            self.refresher = true;
          }
          if (e['c9vbb8kr7uc08lt6tcd0'] !== undefined) {
            e['c9vbb8kr7uc08lt6tcd0'].data.forEach(element => {
              self.widget.data.forEach(item => {
                if (element.dimPro == item.pno) {
                  //console.log(element.dimPro, item.pno)
                  item.ordlnint = element.orderlnint
                }
              })
            });
          }
        })
        .then(function () {
        });
    },
  },
  data() {
    return {
      widget: {},
      filters: {},     
      refresher: false,
      readat: false,
    };
  },
};
</script>