<template>
  <div>
    <!-- Chips -->
    <v-row>
      <v-col cols="5" class="pt-0 mb-2">
        <v-chip-group active-class="deep-purple white--text" v-model="activeCategory">
          <v-chip color="#E1BEE7" v-for="tag in equipmentCategoryEquipment" :key="tag" :value="tag">
            {{ tag }}
          </v-chip>
        </v-chip-group>
      </v-col>
      <v-col cols="7">
      </v-col>
    </v-row>

    <!-- Varer -->
    <v-row no-gutters>
      <v-col cols="5">
        <v-card class="mr-2" flat rounded>
          <v-card-title>
            <div>Utstyrsliste</div>
            <v-spacer></v-spacer>
            <v-text-field v-model="equipmentSearch" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table dense :search="equipmentSearch" :headers="equipmentHeaders" :items="equipmentSelection" hide-default-footer :items-per-page="-1" single-expand :expanded.sync="equipmentExpanded" item-key="xid" show-expand>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon color="blue" small @click="addItemToCart(item)"> mdi-cart </v-icon>
            </template>

            <template v-slot:expanded-item="{ item }">
              <td :colspan="4">
                Leverandør <strong>{{ item.sup_nm }}</strong>
                <br />Varenr: {{ item.sup_item_id }}
                <br />Beskrivelse: {{ item.description }}
                <br />Beskrivelse: {{ item.subcategory }}
                <br />Enhet: {{ item.measure }}
                <br />Antall pr enhet: {{ item.unitsam }}
                <br />
                <v-btn v-if="item.equipment_url" :href="item.equipment_url" target="_blank" class="ma-2" outlined color="indigo">
                  <v-icon>mdi-link-variant</v-icon>
                </v-btn>
              </td>
              <td :colspan="5" class="pa-2">
                <v-img :lazy-src="item.image_url" max-width="250" :src="item.image_url"></v-img>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <!-- Handlevogn -->
      <v-col cols="7">
        <v-card class="px-2" flat rounded>
          <v-card-title>Handlevogn
            <v-spacer></v-spacer>
            <v-icon large color="teal lighten-2"> mdi-cart </v-icon>
          </v-card-title>
          <v-card-subtitle>PO nr: {{ poid }}.Leveringsadresse: - Leveringsdato</v-card-subtitle>

          <table class="mytable">
            <thead>
              <tr>
                <th class="text-left">Utstyr</th>
                <th class="text-left" style="width: 220px">Ansatt</th>
                <th class="text-left" style="width: 200px">Størrelse</th>
                <th class="text-right" style="width: 50px">Pris</th>
                <th class="text-right" style="width: 20px"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in cartItems" :key="i">
                <td class="text-left">{{ item.title }}
                  <!--<br/>{{ item.unitsam }} {{ item.measure }}-->
                </td>
                <td>
                  <v-autocomplete :items="empAll" dense></v-autocomplete>
                </td>
                <td class="text-right">
                  <v-text-field single-line dense v-model.trim="item.size" :rules="[rules.required]"></v-text-field>
                </td>
                <td class="text-right">{{ $formatNumber(item.priceam) }}</td>
                <td class="text-right">
                  <v-btn x-small @click="deleteFromCart(i)" icon color="pink">
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>Total</td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right">
                  {{ $formatNumber(total) }}
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <div class="text-center">
            <v-btn class="ma-2" depressed color="success">Send bestilling</v-btn>
            <v-btn class="ma-2" depressed color="primary">Lagre kladd</v-btn>
          </div>

          <v-sheet>Order hist</v-sheet>

        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import requestData from "@/mixins/requestData.js";

export default {
  mixins: [requestData],
  props: {
    projectHeader: Array,
    projectid: String,
    poid: String,
  },
  mounted: function() {
    var self = this;
    const req = {
      id: self.$route.params.page,
      company: this.$store.state.activecompany,
      media: "json"
    };

    this.requestJSON2("/api/" + this.$route.name, req)
      .then(function(e) {
        self.equipmentAll = e["c5kokn4bcv4027hit90g"];
        var tmp = [];
        e["c5m7bksbcv40l06j0fgg"].forEach(function(item) {
          tmp.push({
            text: item.employee + " (" + item.empid + ")",
            value: item.empid
          });
        });
        self.empAll = tmp.sort((a, b) => (a.text > b.text ? 1 : -1));
      })
      .then(function() {
        self.gridready = true;
      });
  },
  watch: {
    /*
    projectid: function(val, oldVal) {
      if (val !== oldVal) {
        this.hentetProsjekt = false;
        this.projectHeader = {};
      }
    },*/
    activeCategory: function(val, oldVal) {
      // if (val==='undefined') {this.equipmentSelection=this.equipmentAll}
      if (val !== oldVal) {
        var newArray = this.equipmentAll.filter(function(el) {
          return el.subcategory == val;
        });
        newArray.forEach(function(part, index, theArray) {
          theArray[index].count = 1;
        });
        this.equipmentSelection = newArray;
      }
    }
  },
  data() {
    return {
      rules: {
        required: value => !!value || "Required.",
        numberRule: v => {
          //if (!v.trim()) return true;
          if (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) return true;
          return "Number has to be between 0 and 999";
        }
      },
      empAll: [],
      activeCategory: "",
      equipmentAll: [],
      equipmentSelection: [],
      equipmentExpanded: [],
      equipmentSearch: "",
      equipmentHeaders: [
        { text: "Vare", value: "title" },
        { text: "Innkjøpspris", value: "priceam", align: "right" },
        { text: "Bestill", value: "actions", sortable: false, align: "center" }
      ],
      cartItems: [],
    }
  },
  computed: {
    equipmentCategoryEquipment() {
      return this.uniqueValues("subcategory");
    },
    total: function() {
      return this.cartItems.reduce(function(total, item) {
        return total + item.amount;
      }, 0);
    }
  },
   methods: {
    deleteFromCart: function(index) {
      this.cartItems.splice(index, 1);
    },
    multiplisert: function(i) {
      var amount = Math.round(
        this.cartItems[i].priceam * this.cartItems[i].count
      );
      this.cartItems[i].amount = amount;
      return this.cartItems[i].amount;
    },
    uniqueCategoryByClassification(cls) {
      const array = this.equipmentAll || [];
      const result = [];
      const map = new Map();
      for (const item of array) {
        if (item['classification'] === cls) {
          if (!map.has(item.category)) {
            map.set(item.category, true); // set any value to Map
            result.push(item.category);
          }
        }
      }
      return result;
    },
    uniqueValues(cls) {
      const unique = [...new Set(this.equipmentAll.map(item => item[cls]))];
      return unique.sort();
    },
    addItemToCart(item) {
      this.cartItems.push(Object.assign({amount: 0, emp: '', size: ''}, item));
    }
    /*customFilter(item, queryText) {
      const textOne = item.text.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },*/
    /*
    hentProsjekt() {
      var self = this;
      this.requestJSON2("/api/equipment/component", {
        id: "bqcbqsjipt3aimtdrtp0",
        scope: "project",
        projectid: this.projectid
      }).then(function(e) {
        self.projectHeader = e[Object.keys(e)[0]];
      });
    }*/      
   }
};
</script>