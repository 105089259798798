<template>
  <v-sheet rounded class="pa-2">
    <v-row no-gutters>
      <v-col cols="5" class="pr-2">
        <hent-prosjekt @emitproject="hentetProsjekt" @prechange="prechange" />
      </v-col>
      <v-col cols="2" class="pr-2">
        <v-menu v-model="dateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field dark style="height: 48px" solo flat background-color="blue darken-4" v-model="date" label="Velg handlingsdato" hide-details="auto" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="date" first-day-of-week=1 @input="dateMenu = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="5">
        <v-sheet dark rounded class="blue darken-4 pa-1">
          <v-subheader style="height: 21px" class="pa-0">Handling
            <v-spacer></v-spacer>
            <v-btn icon color="blue lighten-4" @click="radioclear">
              <v-icon small>mdi-backspace-outline</v-icon>
            </v-btn>
          </v-subheader>
          <v-divider></v-divider>
          <v-radio-group ref="radioform" dense row v-model="radioEditValid" class="mt-0" style="height: 30px">
            <v-radio v-for="r in radioOptions" :key="r.value" :label="r.label" :value="r.value" :color="r.color"></v-radio>
          </v-radio-group>
        </v-sheet>
      </v-col>
    </v-row>

    <template v-if="gridready">
      <v-divider class="my-2"></v-divider>
      <div v-for="widget in layout" :key="widget.i">
        <v-data-table :headers="headers" :items="widgets[widget.i]" :items-per-page="-1" hide-default-footer dense>
          <template v-slot:[`item.discountam`]="{item}">
            <v-text-field dense reverse class="mt-0 pt-1" style="width: 55px; float: right;" v-model="item.discountam" maxlength="3" :rules="rulesdiscount" @change="discountChange(item, widget.i)"></v-text-field>
          </template>
          <template v-slot:[`item.countam`]="{item}">
            <v-text-field dense reverse class="mt-0 pt-1" style="width: 55px; float: right;" v-model="item.countam" maxlength="5" :rules="rulescount" @change="countChange(item, widget.i)"></v-text-field>
          </template>
          <template v-slot:[`item.status`]="{item}">
            <v-radio-group dense row v-model="item.status" class="mt-0" style="height: 30px;" @change="statusChange(item, widget.i)">
              <v-radio v-for="r in radioOptions" :key="r.value" :label="r.label" :value="r.value" :color="r.color" :disabled="radioEditValid !== r.value"></v-radio>
            </v-radio-group>
          </template>
        </v-data-table>
      </div>
    </template>
  </v-sheet>
</template>


<style scoped>
::v-deep .v-text-field {
  width: 150px;
}
::v-deep th {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
::v-deep td {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
</style>

<script>
/*eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import requestData from "@/mixins/requestData.js";
import HentProsjekt from "@/components/HentProsjekt.vue";

export default {
  mixins: [requestData],
  components: {
    HentProsjekt
  },
  created: function() {
    this.layout =
      JSON.parse(
        JSON.stringify(this.$store.getters.navbylink(this.$route.path).layout)
      ) || [];
  },
  mounted: function() {
    this.$options.sockets.onmessage = function(msg) {
      var obj = JSON.parse(msg.data);
      this.loading = false;
      if (obj.status !== "ok") {
        this.$showMessage({ content: obj.status, color: "error" });
      }
    };
  },
  beforeDestroy: function() {
    delete this.$options.sockets.onmessage;
  },
  watch: {
    radioEditValid: function() {
      if (this.radioEditValid !== null && this.date == null) {
        this.$showMessage({
          content: "Aktiv dato må velges!",
          color: "warning"
        });
        this.radioEditValid = null;
        this.radioclear();
      }
    }
  },
  data() {
    return {
      ws: null,
      widgets: {},
      layout: [],
      loading: false,
      gridready: false,
      radioEditValid: null,
      rulesdiscount: [
        v =>
          (!isNaN(parseFloat(v)) && v >= 0 && v <= 100) ||
          "Verdi mellom 0 og 100"
      ],
      rulescount: [
        v =>
          (!isNaN(parseFloat(v)) && v >= 0 && v <= 100000) ||
          "Verdi mellom 0 og 100000"
      ],
      date: null, //new Date().toISOString().substr(0, 10),
      dateMenu: false,
      projectHeader: {},
      projectid: null,
      radioOptions: [
        {
          label: "Planlagt",
          value: "planlagt",
          color: "blue"
        },
        {
          label: "Sendt",
          value: "sendt",
          color: "green"
        },
        {
          label: "Returnert",
          value: "returnert",
          color: "purple"
        },
        {
          label: "Svinn",
          value: "svinn",
          color: "red",
          disabled: "svinn"
        }
      ],
      headers: [
        { text: "Lokasjon", value: "location" },
        //{ text: "Intern ID", value: "internalid" },
        { text: "Objekt", align: "start", value: "object", width: 250 },
        { text: "Vekt", align: "end", value: "attr_weightint" },
        { text: "Dagsleie", align: "end", value: "dayrentintam" },
        { text: "Rabatt % (0-100)", align: "end", value: "discountam" },
        { text: "Antall", align: "end", value: "countam" },
        { text: "Status", sortable: false, value: "status" },
        { text: "Fra dato", value: "startdate", width: 105 },
        { text: "Til dato", value: "enddate", width: 105 }
      ]
    };
  },
  methods: {
    reset: function() {
      this.radioclear();
      this.radioEditValid = null;
      this.date = null;
      this.widgets = {};
      this.projectid = null;
      this.gridready = false;
    },
    radioclear() {
      this.$refs.radioform.reset();
    },
    discountChange(e, k) {
      // Check if valid
      if (
        !isNaN(parseFloat(e.discountam)) &&
        e.discountam >= 0 &&
        e.discountam <= 100
      ) {
        this.cellEdited({
          tbl: k,
          row: e.xid,
          col: "discountam",
          val: parseFloat(e.discountam)
        });
      } else {
        // console.log("Not number")
      }
    },
    countChange(e, k) {
      // Check if valid
      if (
        !isNaN(parseFloat(e.countam)) &&
        e.countam >= 0 &&
        e.countam <= 100000
      ) {
        this.cellEdited({
          tbl: k,
          row: e.xid,
          col: "countam",
          val: parseFloat(e.countam)
        });
      } else {
        // console.log("Not number")
      }
    },
    statusChange(e, k) {
      // Check if dato is set
      if (this.date == null) {
        this.$showMessage({
          content: "Aktiv dato må velges! Endring ikke lagret...",
          color: "warning"
        });
        return;
      }
      // Index of edited
      var ix = this.widgets[k].indexOf(e);

      // Sett planlagt
      if (e.status === "planlagt") {
        this.widgets[k][ix].startdate = "";
        this.widgets[k][ix].enddate = "";
        this.cellEdited({ tbl: k, row: e.xid, col: "status", val: e.status });
        this.cellEdited({ tbl: k, row: e.xid, col: "startdate", val: null });
        this.cellEdited({ tbl: k, row: e.xid, col: "enddate", val: null });
      }
      // Sett sendt
      if (e.status === "sendt") {
        this.widgets[k][ix].startdate = new Date(this.date).toLocaleDateString(
          "ro-RO"
        );
        this.widgets[k][ix].enddate = "";
        this.cellEdited({ tbl: k, row: e.xid, col: "status", val: e.status });
        this.cellEdited({
          tbl: k,
          row: e.xid,
          col: "startdate",
          val: e.startdate
        });
        this.cellEdited({ tbl: k, row: e.xid, col: "enddate", val: null });
      }
      // Sett returnert
      if (e.status === "returnert") {
        this.widgets[k][ix].enddate = new Date(this.date).toLocaleDateString(
          "ro-RO"
        );
        this.cellEdited({ tbl: k, row: e.xid, col: "status", val: e.status });
        this.cellEdited({ tbl: k, row: e.xid, col: "enddate", val: e.enddate });
      }
      // Sett svinn
      if (e.status === "svinn") {
        this.widgets[k][ix].enddate = new Date(this.date).toLocaleDateString(
          "ro-RO"
        );
        this.cellEdited({ tbl: k, row: e.xid, col: "status", val: e.status });
        this.cellEdited({ tbl: k, row: e.xid, col: "enddate", val: e.enddate });
      }
    },
    prechange(e) {
      console.log("prechange", e);
      this.reset();
    },
    hentetProsjekt(e) {
      // Receive emitted list from component
      this.reset();

      var self = this;
      this.projectHeader = e;
      e.forEach(function(item) {
        if (item.field === "pid") {
          self.projectid = item.value;
        }
      });
      this.getEquipmentFlow();
    },
    getEquipmentFlow() {
      var self = this;
      this.requestJSON2("/api/equipment/component", {
        id: "c0etf86vvhfntac2no9g",
        projectid: self.projectid,
        scope: "shippingproject"
        //format: "datatable" @TODO
      }).then(function(e) {
        self.widgets = e || {};
        if (e[Object.keys(e)[0]] !== null) {
          self.gridready = true;
        }
      });
    },
    cellEdited(data) {
      //this.loading = true;
      //var data = a.getData();
      // Send message
      if (this.$store.state.socket.isConnected) {
        this.$socket.sendObj({
          tbl: data["tbl"],
          row: data["row"],
          company: this.$store.state.activecompany,
          col: data["col"],
          val: String(data["val"]),
          url: this.$route.path
        });
      } else {
        this.$showMessage({
          content: "Websocket connection error",
          color: "error"
        });
      }
    }
  }
};
</script>