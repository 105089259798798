<template>
  <v-card
    flat
    class="mx-auto"
    max-width="444"
    v-if="obj"
    @mouseover.native="mousehover = true"
    @mouseleave.native="mousehover = false"
  >
    <v-card-text>
      <div class="title">{{ obj.title }}</div>
      <span v-if="mousehover" class="overlay">
        <span class="tooltip" v-if="obj.tips">
          <v-icon size="21" color="green lighten-2">mdi-information-outline</v-icon>
          <span class="tooltiptext">{{ obj.tips }}</span>
        </span>
        <span class="down1" v-if="obj.relation">
            <v-icon v-on:click="gotopage" size="21" color="blue lighten-2">mdi-link-variant</v-icon>
        </span>
      </span>
      <div class="display-1 text--primary">{{ obj.stat }}</div>
      <div class="caption font-weight-medium">{{ obj.subtitle }}</div>
      <div v-html="obj.text"></div>
    </v-card-text>
    <v-card-text class="py-0">
      <v-sparkline :fill="true" line-width="8" :value="obj.data" :type="obj.type" :color="obj.color"></v-sparkline>
    </v-card-text>
  </v-card>
</template>

<style>
/* Tooltip container */
.overlay {
  position: relative;
  float: right;
  top: -29px;
  /* display: inline-block; */
}

/* https://michaelnthiessen.com/hover-in-vue/ */
/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 170px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  top: -9px;
  right: -10px;
}

.down1 {
  position: absolute;
  top: 25px;
  right: 0px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>

<script>
// https://michaelnthiessen.com/hover-in-vue/

export default {
  name: "WidgetStatistic",
  props: {
    obj: Object
  },
  methods: {
    gotopage: function() {
      var too = this.$store.getters.navbyid(this.obj.relation);
      this.$router.push({ path: too.link, params: { id: too.id } })
    }
  },
  data() {
    return {
      mousehover: false
    };
  }
};
</script>
