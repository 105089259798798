<template>
        <v-row>
          <v-col cols="9" class="pa-5">

            <v-data-table
              dense
              v-model="selected"
              :headers="headers"
              :items="items"
              item-key="xid"
              show-select
              group-by="client"
              class="elevation-1"
              :items-per-page="-1"
              hide-default-footer
              show-group-by
            >
            </v-data-table>
          </v-col>
          <!-- Kundeliste -->
          <v-col cols="3" class="pa-5">

            <v-badge
              bordered
              color="error"
              :content="this.selected.length"
              overlap
              class="mt-5"
            >
              <v-btn
                class="white--text"
                color="primary"
                depressed
                :href="`mailto:${this.$store.state.useremail}?bcc=${mergedMail}`"
                target="_blank"
              >
                Opprett e-post
                <v-icon right dark> mdi-email-outline </v-icon>
              </v-btn>
            </v-badge>

          </v-col>
        </v-row>
</template>

<script>
import requestData from "@/mixins/requestData.js";

export default {
  mixins: [requestData],
  mounted: function () {
    this.requestContacts();
  },
  methods: {
    reset: function () {
      this.items = [];
    },
    requestContacts: function () {
      var self = this;
      this.requestJSON2("/api/bid/component", {
        id: "bqirj0ript31tabngf00",
        scope: "contacts",
        format: "datatable",
      }).then(function (e) {
        const resp = e[Object.keys(e)[0]];
        self.items = resp.data || [];
        //self.headers = resp.options || [];
      });
    },
  },
  computed: {
    // a computed getter
    mergedMail: function () {
      const recipients = [];
      this.selected.forEach((i) => {
        recipients.push(i.email);
      });
      return recipients.join(";");
    },
  },
  data() {
    return {
      widgets: {},

      selected: [],
      headers: [
        { text: "Selskap", value: "client", groupable: true },
        { text: "Navn", value: "val", groupable: false },
        {
          text: "Stilling",
          value: "position",
          sortable: false,
          groupable: false,
        },
        {
          text: "E-post",
          align: "start",
          sortable: false,
          value: "email",
          groupable: false,
        },
        { text: "Telefon", value: "phone", sortable: false, groupable: false },
      ],
      items: [],
    };
  },
};
</script>
