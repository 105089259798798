<template>
   <v-toolbar rounded flat>
   <v-row>
      <v-col cols="2">
         <SelectListToDialog @emitselected="actorSelected" title="Leverandør" componentid="c8j3qg4r7uc41d54j5dg" :tableHeaders="[{ text: 'Nr', value: 'supno' },{ text: 'Navn', value: 'supnm' },{ text: 'Orgnr', value: 'bsno' },{ text: 'Klassifisering', value: 'class' },{ text: 'Rang', value: 'rank' }]"/>
      </v-col>
      <v-col cols="8">
         <h2 class="red--text text--darken-3"> {{ actor.supnm }} </h2>
      </v-col>
      <v-col cols="2">
         <v-btn color="primary" dark >Hent varelinjer</v-btn>            
      </v-col>
   </v-row>
   </v-toolbar>
</template>



<script>
import requestData from "@/mixins/requestData.js";
import SelectListToDialog from "@/components/SelectListToDialog.vue";

export default {
  components: {
    SelectListToDialog
  },
  mixins: [requestData],
  mounted: function() {
    //this.requestCustomers();
  },
  methods: {
    actorSelected: function(val) {
      this.actor = val;
    },
    reset: function() {
      this.actor = {};
    },
    requestCustomer: function() {
      // Kontakter
      var self = this;
      this.requestJSON2("/api/bid/component", {
        id: "bqirj0ript31tabngf00",
        scope: "customer",
        contactid: this.customer.xid
      }).then(function(e) {
        const resp = e[Object.keys(e)[0]];
        self.contacts = resp || [];
      });
    }
  },
  data() {
    return {
      actor: {}
    };
  }
};
</script>
