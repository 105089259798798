<template>
  <v-simple-table dense>
    <thead><tr><th v-for="(item, i) in header" :key="i">{{ item }}</th></tr></thead>
    <tbody>
      <tr v-for="(item, index) in items" :key=index>
        <td>
          <v-icon @click="move('jobpkg', item.classification, item.orderint, -1.5)" :disabled="index==0" color="teal lighten-3">mdi-arrow-up-bold</v-icon>
          <v-icon @click="move('jobpkg', item.classification, item.orderint, 1.5)" :disabled="index==(items.length-1)" color="teal lighten-3">mdi-arrow-down-bold</v-icon>
        </td>
        <td><v-text-field class="pa-2" dense filled single-line hide-details="auto" v-model="item.title"></v-text-field></td>
        <td><v-text-field class="pa-2" dense filled single-line hide-details="auto" v-model="item.comment"></v-text-field></td>
        <td style="width: 200px"><v-select class="pa-2" dense filled single-line hide-details="auto" :items="jobpackagecategory" v-model="item.category" label="Type"></v-select></td>
        <td><input v-model="item.frdt" type="date" min="2019-01-01" max="2050-01-01"></td>
        <td><input v-model="item.todt" type="date" min="2019-01-01" max="2050-01-01"></td>
        <td>{{ days(index) }}</td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<style scoped>
input[type="date"] {
  color: blue;
  background-color : #0000000f;
  height: 40px;
}
</style>

<script>
export default {
  props: {items: Array, jobpackagecategory: Array, header: Array},
  methods: {
    days: function(i) {
      if (
        this.$func.IsValidDate(this.items[i].todt) &&
        this.$func.IsValidDate(this.items[i].frdt)
      ) {
        this.items[i].daysint = this.$func.NumberOfDays(
          this.items[i].frdt,
          this.items[i].todt
        );
        return this.items[i].daysint;
      }
    },
    move: function(section, classification, orderint, direction) {
      this.$emit("emitmove", section, classification, orderint, direction);
    }
  }
};
</script>
