<template>
  <v-card class="ma-2" width="210" :to="to" :class="color + ' darken-2'" dark>
    <v-card-title class="headline py-2">
      <v-icon large class="mr-3">{{ icon }}</v-icon>{{ headline }}
    </v-card-title>
    <v-card-text style="min-height: 100px" :class="color + ' pt-4'">{{ subtitle }}</v-card-text>
    <v-card-actions :class="color + ' lighten-2 py-0'">
      <v-spacer></v-spacer>
      <v-btn icon large>
        <v-icon>mdi-chevron-triple-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "navcard",
  props: ["headline", "subtitle", "to", "image", "icon", "index", "color"]
};
</script>