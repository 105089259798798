<template>
  <div>
    <v-container fluid>
      <v-sheet class="ma-auto" rounded elevation="1" style="
          background: rgba(255, 255, 255, 0.5);
          backdrop-filter: blur(8px);
          height: 96vh;
        ">
        <ToolbarLeftNavTitle :title="$store.state.paths[$route.path].title" />

        <div v-if="gridready" class="pa-2" style="height: calc(100% - 48px)">
          <v-sheet rounded class="pa-3" v-for="(item, index) in layout" :key="index" style="height: 100%">
            <div>
              <h3 class="float-left font-weight-medium grey--text text--darken-1 pb-2">
                {{ widgets[item.i].title }}
              </h3>
              <v-spacer></v-spacer>
              <div class="float-right">
                <span class="caption teal--text text--lighten-2 mr-2" v-if="readat">Data lest {{new Date(readat*1000).toLocaleDateString("nb-NO")}} {{new Date(readat*1000).toLocaleTimeString("nb-NO")}}</span>
                <v-btn v-if="widgets[item.i].parameters.newrow" outlined color="teal" small @click="addRow()">Ny rad<v-icon right>mdi-table-row-plus-after</v-icon></v-btn>
                <v-btn v-if="widgets[item.i].parameters.excel" outlined color="teal" small @click="requestMedia($route.path, {media: widgets[item.i].parameters.excel, id: $route.params.page,})" class="ml-2">Excel<v-icon right>mdi-download</v-icon></v-btn>
                <v-btn v-if="widgets[item.i].parameters.json" outlined color="teal" small @click="requestMedia($route.path, {media: widgets[item.i].parameters.json,id: $route.params.page,})" class="ml-2">JSON<v-icon right>mdi-code-json</v-icon></v-btn>
                <v-btn v-if="widgets[item.i].parameters.refresh" outlined color="teal" small @click="refresh()" class="ml-2">Oppdater<v-icon right>mdi-cloud-refresh</v-icon></v-btn>
              </div>
            </div>
            <v-progress-linear :active="loading" :indeterminate="loading" bottom height="3" background-color="teal lighten-5" color="deep-purple accent-4" style="height: 3px" class="mb-2"></v-progress-linear>

            <div style="height: calc(100% - 32px); overflow: auto">
              <VueTabulator ref="tabulator" v-model="widgets[item.i].data" :options="fromDataObj(item.i)" @cell-edited="cellEdited" @cell-click="cellClick" />
            </div>
          </v-sheet>
        </div>
      </v-sheet>
    </v-container>
  </div>
</template>

<style scoped>
.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row:nth-child(even) {
  background-color: #ede7f6;
}
</style>
<style lang='scss'>
@import "@/assets/tabulator_simple_gd";
</style>

<script>
/*eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

//import { TabulatorComponent } from "vue-tabulator";
import requestData from "@/mixins/requestData.js";

import { TabulatorComponent } from "vue-tabulator";
import tabulatoredit from "@/mixins/tabulatorEdit.js";
import requestmedia from "@/mixins/requestMedia.js";

export default {
  mixins: [requestData, tabulatoredit, requestmedia],
  components: {
    VueTabulator: TabulatorComponent
  },
  //props: ["wid", "widget", "options","equipmentid", "pageid"],
  created: function() {
    this.layout =
      JSON.parse(
        JSON.stringify(this.$store.getters.navbylink(this.$route.path).layout)
      ) || [];
  },
  mounted: function() {
    //this.requestData();
    this.requestTabulator();
    this.$options.sockets.onmessage = function(msg) {
      //console.log("received in component: ", msg.data)
      var obj = JSON.parse(msg.data);
      //this.loading = false;
      if (obj.status !== "ok") {
        this.$showMessage({ content: obj.status, color: "error" });
      }
    };
  },
  beforeDestroy: function() {
    delete this.$options.sockets.onmessage;
  },
  data() {
    return {
      ws: null,
      loading: false,
      gridIsDraggable: false,
      gridIsResizable: false,
      gridPreview: true,
      initOptions: {
        // renderer: "svg"
      },
      gridready: false,
      readat: false,
      widgets: {},
      layout: [],
      drawer: null,
      //loading: false,
      parameters: {}
    };
  },
  methods: {
    /*
    downloadCSV() {
      const tabulatorInstance = this.$refs.tabulatorref[0].getInstance();
      tabulatorInstance.download("csv", "data.csv");
    },
    */
    refresh() {
      this.requestTabulator();
    },
    disableGrid() {
      this.gridIsDraggable = !this.gridIsDraggable;
      this.gridIsResizable = !this.gridIsResizable;
      this.gridPreview = !this.gridPreview;
    },

    layoutUpdatedEvent: function(newLayout) {
      console.log("New layout:", JSON.stringify(newLayout));
    }
  }
};

/*
    cellEdited(a) {
      this.loading = true;
      var data = a.getData();

      // Send message
      if (this.$store.state.socket.isConnected) {
        this.$socket.sendObj({tbl: Object.keys(this.widgets)[0], row: data["xid"], company: this.$store.state.activecompany, col: a.getField(), val: data[a.getField()] })
      } else {
        this.$showMessage({ content: "Websocket connection error", color: "error"})
      }
    },
    */
/*
    cellClick(e, a) {
      // @row-click="myMethod"
      if (a.getField() == "delete") {
        if (confirm('Are you sure you want to delete this entry?')) {
        //const tabulatorInstance = this.$refs.tabulatorref[0].getInstance();
        const tabulatorInstance = this.$refs.tabulatorref[0].getInstance(); //`tabulatorref`+item.i
        var data = a.getData()
        //console.log({tbl: Object.keys(this.widgets)[0], row: data.xid, company: this.$store.state.activecompany })
        //console.log(a.getField("xid"))
        var path = '/api/' + this.$route.path.split('/')[1] + '/delete'
        this.axios
            .get(path, {params: {tbl: Object.keys(this.widgets)[0], row: data.xid, company: this.$store.state.activecompany}})
            .then(function (response) {
              console.log(response.status)
              tabulatorInstance.deleteRow(a.getRow())
            })
            .catch(function (error) {
              console.log(error);
            })
            .finally(function () {
              // always executed
            });
        }
      }
    },    
    addRow(key) {
      console.log(key)
      //const tabulatorInstance = this.$refs.tabulatorref[0].getInstance();
      var ref = "tabulatorref"+key
      const tabulatorInstance = this.$refs[ref][0].getInstance();
      this.$newXID().then(function(res) {
        //console.log(res.xid)
        //console.log(tabulatorInstance)
        tabulatorInstance.addRow({xid: res.xid}, true);
      })

      //console.log("component: ", key);
      //console.log(tabulatorInstance);
      //tabulatorInstance.clearData();
      // console.log(this.layout);
      //this.$newXID().then(res => console.log(res))
    },
    */

/*
    fromDataObj: function(name) {
    //fromDataObj: function(name, key) {
      //return this.widgets[name][key];
      return this.widgets[name].options;
    },*/
/*
    requestData: function() {
      var reqUrl = "/api/" + this.$route.name;
      var self = this;
      //const req = { ...{ id: self.page.id }, ...this.$store.state.active };
      const req = {id: self.page.id, company: this.$store.state.activecompany, format: "editable", media: "json"};
      this.axios
        .get(reqUrl, {
          params: req
        })
        .then(function(response) {
          const keys = Object.keys(response.data);
          for (const key of keys) {
            // TABLE
            if (response.data[key].wtype == "tabulator") {
              if (response.data[key].hasOwnProperty('parameters')) {
                self.parameters = response.data[key].parameters
              }
              self.$set(self.widgets, key, { options: {}, data: {} });
              self.widgets[key].index = "xid";
              self.widgets[key].title = response.data[key].title;
              self.widgets[key].subtitle = response.data[key].subtitle;

              // Loop columns with purpose of re sorting lookup values
              response.data[key].options.columns.forEach(function (item1) {
                  if (item1.formatter === "lookup" && Object.keys(item1.editorParams.values).length > 0) {
                      item1.editorParams.values = sortObjectEntriesByValue(item1.editorParams.values);
                  }
                  if ('columns' in item1) {
                    item1.columns.forEach(function (item2) {
                        if (item2.formatter === "lookup" && Object.keys(item2.editorParams.values).length > 0) {
                          item2.editorParams.values = sortObjectEntriesByValue(item2.editorParams.values);
                        }
                    });
                  }  
              });

              (self.widgets[key].options = {
                ...response.data[key].options,
                ...{
                  rowFormatter: function(row) {
                    // Upper style
                    if (row.getData().style == "1") {
                      row.getElement().style.backgroundColor =
                        "rgba(243,243,243,0.8)";
                      //row.getElement().style.borderTop = "1px solid #BFECEA";
                      //row.getElement().style.borderBottom = "2px solid #BFECEA";
                      row.getElement().style.fontWeight = "700";
                      row.getElement().style.color = "black";
                    }
                    // Second level style
                    if (row.getData().style == "2") {
                      //row.getElement().style.backgroundColor = "rgba(243,243,243,0.8)";
                      row.getElement().style.fontWeight = "700";
                      row.getElement().style.color = "black";
                    }
                    // Percentage style
                    if (row.getData().style == "6") {
                      row.getElement().style.borderBottom = "1px dashed #EDE7F6";
                      //row.getElement().style.backgroundColor = "#EDE7F6";
                      row.getElement().style.fontWeight = "700";
                      row.getElement().style.fontStyle = "italic";
                      row.getElement().style.color = "#757575";
                    }
                  }
                }
              }),
                (self.widgets[key].data = response.data[key].data);
            }
          }
        self.gridready = true;  
        })
        .catch(function(error) {
          console.log(error)
          self.gridready = false;
          return
        })
        .finally(function() {
          // always executed
        });
    },*/
/*
window.moment=function(dt, format){
 // do whatever you want here

  console.log(format)
  var parts = dt.split(".");
  // var a = new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10)); 
  var a = parseInt(parts[2], 10)+"-"+(parseInt(parts[1], 10))+"-"+parseInt(parts[0], 10)
  console.log(a)
  return a

};

class formatDate extends Date {

  getFormattedDate() {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return `${this.getDate()}-${months[this.getMonth()]}-${this.getFullYear()}`;
  }

}
*/
/*
moment.prototype.isValid = function (m) {
  console.log(m);
  return true
};
*/
/*
TabulatorComponent.prototype.extendModule("sort", "sorters", {
    ddmmyyyy:function(a, b){
        // a = moment(a, "DD/MM/YYYY hh:mm");
        // b = moment(b, "DD/MM/YYYY hh:mm");
        return a - b;
    },
});
*/

/*
TabulatorComponent.prototype.extendModule("format", "formatters", {
    //bold:function(cell, formatterParams){
    bold:function(cell){
        return "<strong>" + cell.getValue() + "</strong>"; //make the contents of the cell bold
    },
    //uppercase:function(cell, formatterParams){
    uppercase:function(cell){
        return cell.getValue().toUpperCase(); //make the contents of the cell uppercase
    }
});
*/
</script>