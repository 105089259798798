<template>
      <!-- Kamishibai -->
      <div>
          <graphic-kamishibai :options="options" :data="items"></graphic-kamishibai>
          <!--Legg pop-up link til utstyrskort fra utstyr. Leder: Telefonnr til prosjektleder - teams link call-->
      </div>
</template>


<script>
import requestData from "@/mixins/requestData.js";
import GraphicKamishibai from "@/components/GraphicKamishibai.vue";

export default {
  mixins: [requestData],
  components: {
    "graphic-kamishibai": GraphicKamishibai,
  },
  created: function () {
    this.svgheight = Math.max(
      document.documentElement.clientHeight - 100 || 0,
      window.innerHeight - 100 || 0
    );
  },
  mounted: function () {
    this.requestData();
  },
  methods: {
    requestData: function () {
      var self = this;
      const req = {
        id: self.$route.params.page,
        company: this.$store.state.activecompany,
        media: "json",
        format: "datatable",
      };
      this.requestJSON2("/api/" + this.$route.name, req).then(function (e) {
        const resp = e[Object.keys(e)[0]];
        self.items = resp.data || [];
        self.options = resp.options || [];
      });
    },
  },
  data() {
    return {
      items: [],
      options: [],
    };
  },
};
</script>