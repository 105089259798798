var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pa-2",attrs:{"rounded":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"5"}},[_c('hent-prosjekt',{on:{"emitproject":_vm.hentetProsjekt,"prechange":_vm.prechange}})],1),_c('v-col',{staticClass:"pr-2",attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"height":"48px"},attrs:{"dark":"","solo":"","flat":"","background-color":"blue darken-4","label":"Velg handlingsdato","hide-details":"auto","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1"},on:{"input":function($event){_vm.dateMenu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-sheet',{staticClass:"blue darken-4 pa-1",attrs:{"dark":"","rounded":""}},[_c('v-subheader',{staticClass:"pa-0",staticStyle:{"height":"21px"}},[_vm._v("Handling "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"blue lighten-4"},on:{"click":_vm.radioclear}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-backspace-outline")])],1)],1),_c('v-divider'),_c('v-radio-group',{ref:"radioform",staticClass:"mt-0",staticStyle:{"height":"30px"},attrs:{"dense":"","row":""},model:{value:(_vm.radioEditValid),callback:function ($$v) {_vm.radioEditValid=$$v},expression:"radioEditValid"}},_vm._l((_vm.radioOptions),function(r){return _c('v-radio',{key:r.value,attrs:{"label":r.label,"value":r.value,"color":r.color}})}),1)],1)],1)],1),(_vm.gridready)?[_c('v-divider',{staticClass:"my-2"}),_vm._l((_vm.layout),function(widget){return _c('div',{key:widget.i},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.widgets[widget.i],"items-per-page":-1,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.discountam",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"mt-0 pt-1",staticStyle:{"width":"55px","float":"right"},attrs:{"dense":"","reverse":"","maxlength":"3","rules":_vm.rulesdiscount},on:{"change":function($event){return _vm.discountChange(item, widget.i)}},model:{value:(item.discountam),callback:function ($$v) {_vm.$set(item, "discountam", $$v)},expression:"item.discountam"}})]}},{key:"item.countam",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"mt-0 pt-1",staticStyle:{"width":"55px","float":"right"},attrs:{"dense":"","reverse":"","maxlength":"5","rules":_vm.rulescount},on:{"change":function($event){return _vm.countChange(item, widget.i)}},model:{value:(item.countam),callback:function ($$v) {_vm.$set(item, "countam", $$v)},expression:"item.countam"}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-radio-group',{staticClass:"mt-0",staticStyle:{"height":"30px"},attrs:{"dense":"","row":""},on:{"change":function($event){return _vm.statusChange(item, widget.i)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}},_vm._l((_vm.radioOptions),function(r){return _c('v-radio',{key:r.value,attrs:{"label":r.label,"value":r.value,"color":r.color,"disabled":_vm.radioEditValid !== r.value}})}),1)]}}],null,true)})],1)})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }