<template>
  <v-snackbar v-model="show" :color="color">
    {{ message }}
    <v-btn class="float-right" text @click="show = false">Lukk</v-btn>
  </v-snackbar>
</template>

<script>
// https://dev.to/stephannv/how-to-create-a-global-snackbar-using-nuxt-vuetify-and-vuex-1bda
export default {
  data () {
    return {
      show: false,
      message: '',
      color: ''
    }
  },

  created () {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'showMessage') {
        this.message = state.snackbar.content
        this.color = state.snackbar.color
        this.show = true
      }
    })
  }
}
</script>