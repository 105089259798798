<template>
  <v-main class="bg">
    <Notifier></Notifier>

    <v-toolbar dark flat color="transparent">
      <!-- purple lighten-5 -->
      <v-icon color="purple lighten-3">mdi-flare</v-icon>
      <v-toolbar-title class="headline pl-2 font-weight-bold" style="width: 170px">
        InSee
        <span color="purple" style="vertical-align: super; font-size: 12px; padding-left: 8px">BETA</span>
      </v-toolbar-title>
    </v-toolbar>

    <v-flex class="d-flex justify-center mt-10">
      <form class="login" @submit.prevent="login">
        <v-card color="rgba(0, 0, 0, 0.1)" style="backdrop-filter: blur(8px); width: 400px" class="pa-5">
          <v-card-title class="headline font-weight-regular white--text">Login</v-card-title>
          <v-card-text>
            <v-text-field class="mt-2" autocomplete="username" v-model="username" label="E-mail" required solo background-color="deep-purple lighten-5"></v-text-field>
            <v-text-field autocomplete="password" v-model="password" type="password" label="Password" required solo background-color="deep-purple lighten-5"></v-text-field>
            <v-btn raised color="deep-purple lighten-5" class="mr-4" type="submit">Login</v-btn>
          </v-card-text>
        </v-card>
      </form>
    </v-flex>

  </v-main>
</template>

<style scoped>
body {
  margin: 0;
  padding: 0;
}
.bg {
  background-image: url("/bia-vilt-PatZnNB3Cpo-unsplash.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #512da8;
  width: 100vw;
  height: 100vh;
}
</style>

<script>
import Notifier from "@/components_global/Notifier.vue";

export default {
  components: { Notifier },
  methods: {
    /*
    checkCurrentLogin () {
      if (this.currentUser) {
        this.$router.replace(this.$route.query.redirect || '/authors')
      }
    },
    */
    login() {
      this.axios
        .post("/auth/login", {
          username: this.username,
          password: this.password
        })
        .then(
          response => {
            this.$store.commit("AUTH_SUCCESS");
            if (this.$store.state.loggedIn) {
              this.$connect();
            }
            this.$router.push("/cache");
            console.log(response.status);
          },
          error => {
            console.log(error);
            this.$showMessage({
              content: "Feil ved innlogging",
              color: "error"
            });
          }
        );
    }
  },
  data() {
    return {
      username: "",
      password: "",
    };
  }
};

// https://blog.sqreen.com/authentication-best-practices-vue/

/*
function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}
*/
</script>