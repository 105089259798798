<template>
  <v-sheet class="pa-2" rounded color="white">
    <v-slider style="z-index: 99; position: absolute; top: 0; right: 8px;" step="200" min="100" max="1600" vertical v-model="svgheight">
      <!--@change="heightchange"-->
    </v-slider>
    <svg class="graph" :height="svgheight" style="width: 100%" version="1.2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" role="img">
      <!-- Vertical plot-lines on x-axis -->
      <g class="x-grids">
        <line v-for="(item, index) in chartdata.xgrids" :x1="item.x1" :x2="item.x2" :y1="item.y1" :y2="item.y2" :class="item.cls" :key="index"></line>
      </g>
      <!-- Horisontal top or down line -->
      <g class="line">
        <line v-for="(item, index) in chartdata.xgrid" :x1="item.x1" :x2="item.x2" :y1="item.y1" :y2="item.y2" :key="index"></line>
      </g>
      <!-- Horisontal helper line -->
      <g class="helperline">
        <line v-for="(item, index) in chartdata.xlines" :x1="item.x1" :x2="item.x2" :y1="item.y1" :y2="item.y2" :key="index"></line>
      </g>
      <!-- Horisontal x labels -->
      <g class="labels x-labels">
        <text v-for="(item, index) in chartdata.xlabels" :x="item.x" :y="item.y" :key="index">
          {{ item.text }}
        </text>
      </g>
      <!-- Bars -->
      <g class="bars">
        <template v-for="(item, index) in chartdata.series" :x="item.x" :y="item.y">
          <!-- Color label left -->
          <rect :key="`i0-${index}`" width="8" :height="item.h" :y="item.y" x="0" rx="2" :style="item.style"></rect>
          <!-- Rectangel -->
          <rect class="bar" :key="`i1-${index}`" :width="item.w" :height="item.h" :y="item.y" rx="3" :x="item.x" :style="item.style" :stroke="item.stroke" :chartdata-tooltip="item.dataTooltip">
            <title>{{ item.dataTooltip }}</title>
          </rect>
          <!-- Rectangel inline text -->
          <text :key="`i2-${index}`" :x="item.txtx" :y="item.y" dy="1em" dx="5px" :style="item.txtstyle">
            {{ item.txt }}
          </text>
        </template>
      </g>
      <!-- Vertical left labels -->
      <g class="labels y-labels">
        <text v-for="(item, index) in chartdata.ylabels" :x="item.x" :y="item.y" :key="index" dy="1em" :style="item.style">
          {{ item.text }}
        </text>
      </g>
    </svg>
  </v-sheet>
</template>

<style scoped>
.graph .labels.x-labels {
  text-anchor: start;
}
.graph .labels.y-labels {
  text-anchor: start;
}
.graph .x-grids {
  stroke: #ccc;
  stroke-dasharray: 5;
  stroke-width: 1;
}
.graph .today {
  stroke: red;
  stroke-dasharray: 2;
  stroke-width: 2;
}
.graph .line {
  stroke: #ccc;
  stroke-dasharray: 0;
  stroke-width: 1;
}
.graph .helperline {
  stroke: #ccc;
  stroke-width: 1;
  stroke-dasharray: 2, 3
}
/*
.graph .bar:hover rect {
    fill: #ec008c;
    opacity: 1;
}*/
.labels {
  font-size: 13px;
}
.label-title {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  fill: black;
}
.bar-highlight {
  fill: #f09dcf !important;
  opacity: 1 !important;
}
</style>


<script>
export default {
  props: {
    data: {
      type: Array
    },
    options: {
      type: Object
    },
    initheight: Number,
    initwidth: Number
  },
  mounted: function() {
    this.svgheight = this.initheight || 1000;
  },
  updated: function() {
    this.$nextTick(function() {
      // Code that will run only after the
      // entire view has been re-rendered
      var bars = document.getElementsByClassName("bar");
      for (var i = 0; i < bars.length; i++) {
        bars[i].addEventListener("mouseover", mouseOverEffect);
        bars[i].addEventListener("mouseout", mouseOutEffect);
      }
    });
  },
  computed: {
    chartdata() {
      var d = [];

      if (this.data !== undefined) {
        this.data.forEach(function(element) {
          d.push({
            id: element.xid,
            key: element.key,
            title: element.title,
            stdt: element.startdate,
            endt: element.enddate
          });
        });
      }

      var chartoptions = {};
      if (this.options.hasOwnProperty("chart")) {
        chartoptions = this.options;
        chartoptions.chart["width"] = this.initwidth; // this.$refs.svgelement1.clientWidth;
        chartoptions.chart["height"] = this.svgheight;
      }

      return this.$func.TimelineData(d, chartoptions);
    }
  },
  data() {
    return {
      svgheight: 200
    };
  }
};

// https://www.petercollingridge.co.uk/tutorials/svg/interactive/mouseover-effects/
function mouseOverEffect() {
  this.classList.add("bar-highlight");
  //console.log(this.attributes["data-tooltip"])
}
function mouseOutEffect() {
  this.classList.remove("bar-highlight");
}
</script>