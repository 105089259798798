<template>
  <v-sheet color="white" class="pt-2" rounded >    
      <div class="float-right pr-2">
        <span class="caption purple--text text--lighten-2" v-if="readat">Data lest {{new Date(readat*1000).toLocaleDateString("nb-NO")}} {{new Date(readat*1000).toLocaleTimeString("nb-NO")}}</span>
        <v-btn v-if="refresher" outlined small color="purple" @click="refresh()" class="ml-2">Oppdater<v-icon right>mdi-cloud-refresh</v-icon></v-btn>
      </div>

      <dropdown-filters :data="widget.data" v-on:dropdownFiltersChanged="newFiltersFromChild"></dropdown-filters>

      <!-- Timeline canvas -->
            <div style="width: 100%; position: relative" ref="svgelement1">
              <timeline-reservation-chart
                v-if="gridready"
                :initheight="height"
                :initwidth="width"
                :options="timelineReservationOptions"
                :data="filteredItems"
              />
            </div>
  </v-sheet>
</template>

<script>
/*
https://learnvue.co/2020/01/how-to-add-drag-and-drop-to-your-vuejs-project/
https://www.drewtown.dev/post/the-magic-of-vues-dynamic-components-for-lists-of-data/
https://stackoverflow.com/questions/40322404/vuejs-how-can-i-use-computed-property-with-v-for
https://medium.com/js-dojo/component-communication-in-vue-js-ca8b591d7efa
*/
import requestData from "@/mixins/requestData.js";
import TimelineReservationChart from "@/components/GraphicTimelineReservation2.vue";
import DropdownFilters from "@/components/DropdownFilters.vue";

export default {
  mixins: [requestData],
  components: {
    "timeline-reservation-chart": TimelineReservationChart,
    "dropdown-filters": DropdownFilters,
  },
  mounted: function () {

    this.requestData();
    this.width = this.$refs.svgelement1.clientWidth - 20;
    //this.height = this.$refs.svgelement1.clientHeight;
    this.height = window.innerHeight-50;
    this.timelineReservationOptions = {
        sorter: "startdate",
        chart: {
          xOffset: 180,
        },
        plot: {
          reservation: {
            rectangleHeight: 21,
            rectangleGap: 7,
          },
        },
        font: "15px Roboto",
      };    
  },
  computed: {
    filteredItems() {
      if (this.widget.data === undefined) {
        return []
      }

      /* https://codepen.io/wolfpup/pen/gOadmPx */
      return this.widget.data.filter(d => {
        return Object.keys(this.filters).every(f => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
    }
  },
  methods: {
    newFiltersFromChild(obj) {
      this.filters = obj;
    },
    refresh() {
      this.requestData();
    },
    requestData: function () {
      var self = this;
      const req = {
        id: self.$route.params.page,
        company: this.$store.state.activecompany,
        media: "json",
        format: "datatable",
      };
      this.requestJSON2("/api/" + this.$route.name, req)
        .then(function (e) {
          var obj = e[Object.keys(e)[0]]
          self.widget = obj;
          if (
            typeof obj.parameters["readat"] !== "undefined" &&
            obj.parameters["readat"] > 0
          ) {
            self.readat = obj.parameters["readat"];
          }
          if (
            typeof obj.parameters["refresh"] !== "undefined" &&
            obj.parameters["refresh"]
          ) {
            self.refresher = true;
          }
        })
        .then(function () {
          self.gridready = true
        });
    },
  },
  data() {
    return {
      width: 1000,
      height: 0,
      widget: {},
      timelineReservationOptions: {},
      filters: {},     
      gridready: false, 
      refresher: false,
      readat: false,
    };
  },
};
</script>