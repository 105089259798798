<template>
    <v-app-bar style="background: linear-gradient(to bottom,rgba(255, 255, 255, 0.5),rgba(255, 255, 255, 0.2))" dense flat hide-on-scroll><!--  color="grey lighten-5"-->          
      <v-btn :to="{ name: 'home' }" icon>
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-btn @click="$goBack" icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-divider inset vertical></v-divider>
      <v-toolbar-title
        class="text-uppercase font-weight-bold font-weight-medium ml-3"
      >{{title}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <FullscreenButtons/>
    </v-app-bar>
</template>

<script>
/*eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

export default {
   props: ['title'],
}
</script>
