import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import ReportPivot from '@/views/ReportPivot';
import ReportPivot2 from '@/views/ReportPivot2';
import ReportWidget from '@/views/ReportWidget';

import Navpage from '@/views/Navpage2';

// Admin
import Admin from '@/views/admin/Admin';

// Bid
import Customercard from '@/views/bid/Customer';
import Massmail from '@/views/bid/Massmail';
import BidCalc from '@/views/bid/BidCalc';

// Components
import Cache from '@/components/Cache';
//import ContractHeader from '@/components/ContractHeader';
//import ContractPrice from '@/components/ContractPrice';
import Logout from '@/components/Logout';

// Project
import TabulatorEditProject from '@/views/project/TabulatorEditProject';
import Projectpoint from '@/views/project/ProjectPoint';
import Requisitionppe from '@/views/project/Requisition';

// Equipment
import Planning from '@/views/equipment/Planning';
import Requisition from '@/views/equipment/Requisition';
import Shipping from '@/views/equipment/Shipping';
import Attributes from '@/views/equipment/Attributes';
import Utstyrsinnkjop from '@/views/equipment/Utstyrsinnkjop';
import Purchasing from '@/views/equipment/Purchasing';

// Personal
import Todo from '@/views/personal/Todo';
import Ilog from '@/views/personal/Ilog';

// Strategy
import StrategyAction from '@/views/strategy/StrategyAction';
import XYDiagram from '@/views/strategy/XYDiagram';

// Measure
import Measure from '@/views/measure/Measure';

// Common
import Kamishibai from '@/views/common/Kamishibai';
import Timelinebar from '@/views/common/Timelinebar';
import Statusrows from '@/views/common/Statusrows';
// import TabulatorEdit from '@/views/common/TabulatorEdit';
import TabulatorEditSingle from '@/views/common/TabulatorEditSingle';
import Slide from '@/views/common/Slide';
import DataTable from '@/views/common/Datatable';
import Dashboard from '@/views/common/Dashboard';
import Carousel from '@/views/common/Carousel';
import KitchenSink from '@/views/common/KitchenSink';
import ProdChart from '@/views/production/ProdChart';

// Misc
import Fileimport from '@/views/Fileimport';

import Staging0 from '@/components/UploadFiles';
import Staging1 from '@/views/Staging1';
import Staging2 from '@/views/Staging2';
import Staging3 from '@/views/Staging3';


Vue.use(Router)

/*
const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next('/')
    return
  }
  next()
}
*/

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  } else {
    next('/')
  }
}

const routes = [
  { path: '/', name: "public", meta: {layout: "public-layout"} }, // 
  { path: '/logout', name: "logout", component: Logout },

  { beforeEnter: ifAuthenticated, path: '/home', name: "home", meta: {layout: "index-layout"} },
  { beforeEnter: ifAuthenticated, path: '/cache', name: "cache", component: Cache },

  // Info
  { beforeEnter: ifAuthenticated, path: '/info', component: Navpage, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/info/timelinebar/:page', name: "info/timelinebar", component: Timelinebar, meta: {layout: "transparent-layout"} },
  { beforeEnter: ifAuthenticated, path: '/info/statusrows/:page', name: "info/statusrows", component: Statusrows, meta: {layout: "transparent-layout"} },
  { beforeEnter: ifAuthenticated, path: '/info/datatable/:page', name: "info/datatable", component: DataTable, meta: {layout: "transparent-layout"} },

  // Måling
  { beforeEnter: ifAuthenticated, path: '/measure', component: Navpage, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/measure/edit', name: "measure/edit", component: Measure, meta: {layout: "default-layout"} },

  // Økonomi rapporter
  { beforeEnter: ifAuthenticated, path: '/economy', component: Navpage, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/economy/report/:page', name: "economy/report", component: ReportWidget, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/economy/pivot/:table', component: ReportPivot },

  // Tilbud
  { beforeEnter: ifAuthenticated, path: '/bid', component: Navpage, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/bid/report/:page', name: "bid/report", component: ReportWidget, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/bid/editable/:page', name: "bid/editable", component: TabulatorEditSingle, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/bid/customercard/:page', name: "bid/customercard", component: Customercard, meta: {layout: "transparent-layout"} },
  { beforeEnter: ifAuthenticated, path: '/bid/massmail/:page', name: "bid/massmail", component: Massmail, meta: {layout: "transparent-layout"} },
  { beforeEnter: ifAuthenticated, path: '/bid/datatable/:page', name: "bid/datatable", component: DataTable, meta: {layout: "transparent-layout"} },
  { beforeEnter: ifAuthenticated, path: '/bid/dashboard/:page', name: "bid/dashboard", component: Dashboard, meta: {layout: "transparent-layout"} },
  { beforeEnter: ifAuthenticated, path: '/bid/calc/:page', name: "bid/calc", component: BidCalc, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/bid/export/:page', name: "bid/export", component: () => import("@/views/bid/ExportProjectPlan"), meta: {layout: "transparent-layout"} },
  
  // Strategi
  { beforeEnter: ifAuthenticated, path: '/strategy', component: Navpage, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/strategy/editable/:page', name: "strategy/editable", component: TabulatorEditSingle, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/strategy/slide/:page', name: "strategy/slide", component: Slide, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/strategy/action', name: "strategy/action", component: StrategyAction, meta: {layout: "transparent-layout"} },
  { beforeEnter: ifAuthenticated, path: '/strategy/xydiagram/:page', name: "strategy/xydiagram", component: XYDiagram, meta: {layout: "transparent-layout"} },

  // Contract
  { beforeEnter: ifAuthenticated, path: '/contract', component: Navpage, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/contract/editable/:page', name: "contract/editable", component: TabulatorEditSingle, meta: {layout: "default-layout"} },
  //{ beforeEnter: ifAuthenticated, path: '/contract2', component: ContractHeader },
  //{ beforeEnter: ifAuthenticated, path: '/contractprice2', component: ContractPrice },

  // Equipment
  { beforeEnter: ifAuthenticated, path: '/equipment', component: Navpage, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/equipment/editable/:page', name: "equipment/editable", component: TabulatorEditSingle, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/equipment/kamishibai/:page', name: "equipment/kamishibai", component: Kamishibai, meta: {layout: "transparent-layout"} },
  { beforeEnter: ifAuthenticated, path: '/equipment/requisition', name: "equipment/requisition", component: Requisition, meta: {layout: "transparent-layout"} },
  { beforeEnter: ifAuthenticated, path: '/equipment/planning', name: "equipment/planning", component: Planning, meta: {layout: "transparent-layout"} },
  { beforeEnter: ifAuthenticated, path: '/equipment/table/:page', name: "equipment/table", component: Shipping, meta: {layout: "transparent-layout"} },
  { beforeEnter: ifAuthenticated, path: '/equipment/timelinebar/:page', name: "equipment/timelinebar", component: Timelinebar, meta: {layout: "transparent-layout"} },
  { beforeEnter: ifAuthenticated, path: '/equipment/report/:page', name: "equipment/report", component: ReportWidget, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/equipment/attributes/:page', name: "equipment/attributes", component: Attributes, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/equipment/carousel/:page', name: "equipment/carousel", component: Carousel, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/equipment/datatable/:page', name: "equipment/datatable", component: DataTable, meta: {layout: "transparent-layout"} },
  { beforeEnter: ifAuthenticated, path: '/equipment/utstyrsinnkjop/:page', name: "equipment/utstyrsinnkjop", component: Utstyrsinnkjop, meta: {layout: "transparent-layout"} },
  { beforeEnter: ifAuthenticated, path: '/equipment/purchasing/:page', name: "equipment/purchasing", component: Purchasing, meta: {layout: "transparent-layout"} },
  
  
  // Project
  { beforeEnter: ifAuthenticated, path: '/project', component: Navpage, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/project/editable/:page', name: "project/editable", component: TabulatorEditSingle, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/project/customtable/:page', name: "project/customtable", component: TabulatorEditProject, meta: {layout: "transparent-layout"} },
  { beforeEnter: ifAuthenticated, path: '/project/projectpoint/:page', name: "project/projectpoint", component: Projectpoint, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/staging/requisition/:page', name: "project/requisition", component: Requisitionppe, meta: {layout: "transparent-layout"} },
  { beforeEnter: ifAuthenticated, path: '/project/carousel/:page', name: "project/carousel", component: Carousel, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/project/pivot/:page', name: "project/pivot", component: ReportPivot2,  meta: {layout: "transparent-layout"} },
  { beforeEnter: ifAuthenticated, path: '/project/datatable/:page', name: "project/datatable", component: DataTable, meta: {layout: "transparent-layout"} },
  { beforeEnter: ifAuthenticated, path: '/project/kamishibai/:page', name: "project/kamishibai", component: Kamishibai, meta: {layout: "transparent-layout"} },

  // Personal
  { beforeEnter: ifAuthenticated, path: '/personal', component: Navpage, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/personal/todo/:page', component: Todo },
  { beforeEnter: ifAuthenticated, path: '/personal/ilog/:page', component: Ilog },

  // Production
  { beforeEnter: ifAuthenticated, path: '/production', component: Navpage, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/production/datatable/:page', name: "production/datatable", component: DataTable, meta: {layout: "transparent-layout"} },
  { beforeEnter: ifAuthenticated, path: '/production/custom/:page', component: ProdChart, name: "production/custom", meta: {layout: "transparent-layout"} },

  // Personell
  { beforeEnter: ifAuthenticated, path: '/personell', component: Navpage, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/personell/editable/:page', name: "personell/editable", component: TabulatorEditSingle, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/personell/datatable/:page', name: "personell/datatable", component: DataTable, meta: {layout: "transparent-layout"} },


  // Master data
  { beforeEnter: ifAuthenticated, path: '/master', component: Navpage, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/master/datatable/:page', name: "master/datatable", component: DataTable, meta: {layout: "transparent-layout"} },
  { beforeEnter: ifAuthenticated, path: '/master/editable/:page', name: "master/editable", component: TabulatorEditSingle, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/master/supplier/priceimport', name: "master/supplier/priceimport", component: Fileimport, meta: {layout: "default-layout"} },

  // Admin
  { beforeEnter: ifAuthenticated, path: '/admin', component: Admin, meta: {layout: "transparent-layout"} },

  // Staging
  { beforeEnter: ifAuthenticated, path: '/staging', component: Navpage, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/staging/editable/:page', name: "staging/editable", component: TabulatorEditSingle, meta: {layout: "default-layout"} },
  { beforeEnter: ifAuthenticated, path: '/staging/datatable/:page', name: "staging/datatable", component: DataTable, meta: {layout: "transparent-layout"} },
  { beforeEnter: ifAuthenticated, path: '/staging/kitchensink/:page', name: "staging/kitchensink", component: KitchenSink, meta: {layout: "default-layout"} },

  { beforeEnter: ifAuthenticated, path: '/staging0', component: Staging0, name: "staging/staging0" },
  { beforeEnter: ifAuthenticated, path: '/staging1', component: Staging1, name: "staging/staging1" },
  { beforeEnter: ifAuthenticated, path: '/staging2', component: Staging2, name: "staging/staging2" },
  { beforeEnter: ifAuthenticated, path: '/staging3', component: Staging3, name: "staging/staging3" },

]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new Router({
  mode: 'history',
  routes // short for `routes: routes`
})

// 4. Create and mount the root instance.
// Make sure to inject the router with the router option to make the
// whole app router-aware.

// Now the app has started!
export default router
