<template>
  <div style="width: 100%; height: 100% position: relative">


    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" :height="svgheight" style="border:1px solid #f0f0f0">
      <defs>
        <marker id="triangle" viewBox="0 0 10 10"
              refX="1" refY="5"
              markerUnits="strokeWidth"
              markerWidth="10" markerHeight="10"
              orient="auto">
          <path d="M 0 0 L 10 5 L 0 10 z" fill="#000"/>
        </marker>                  
      </defs>

      <!-- Title -->
      <g text-anchor="middle" dominant-baseline="middle">
        <text style="font-weight: bold; font-size: 20px" :x="svgdata.title.x" :y="svgdata.title.y">
          {{ svgdata.title.text }}
        </text>
      </g>

      <!-- Left tasks -->
      <g>
        <text v-for="(item, ix1) in dataparser.tasks" :x="item.x" :y="item.y" :key="ix1">
          <tspan v-if="item.level == 1" font-weight="700" fill="red" font-size="16">{{ item.text }}</tspan>
          <tspan v-else>{{ item.text }}</tspan> 
        </text>
      </g>

      <!-- Bars -->
      <g class="bars">
        <template v-for="(item, index) in dataparser.measures">
          <!-- Rectangel -->
          <!-- Text label left --><text :key="`i0-${index}`" :x="item.x" :y="item.y">{{ item.text }}</text>
          <!-- Rect progress   --><rect :key="`i2-${index}`" :width="item.progress / item.count * item.width" height="18" :y="item.y - 14" rx="3" :x="item.start" style="fill: rgba(205, 220, 57, 0.7);" stroke="rgba(205, 220, 57, 0.3)"><title>Hei</title>--></rect>
          <!-- Rect full       --><rect :key="`i1-${index}`" :width="item.width" height="18" :y="item.y - 14" rx="3" :x="item.start" style="fill: rgba(205, 220, 57, 0.5);" :stroke="item.stroke" stroke-dasharray="4" stroke-width="2"><!--<title>{{ item.dataTooltip }}</title>--></rect>
          <!-- Line prog. end  --><line :key="`i4-${index}`" :x1="item.start + item.progress / item.count * item.width" :y1="item.y - 14"  :x2="item.start + item.progress / item.count * item.width" :y2="item.y + 4" stroke="black"></line>       
          <!-- Text progress   --><text :key="`i3-${index}`" text-anchor="end" :x="item.start - 5 + item.progress / item.count * item.width"  :y="item.y ">{{ Math.round(item.progress / item.count * 100 * 10) / 10  }} %</text>
          <!-- Text full end   --><text :key="`i5-${index}`" :x="item.start + item.width + 10" :y="item.y" >{{ item.progress }} av <tspan font-weight="700">{{ item.count }}</tspan> {{ item.measure }}</text>
        </template>
      </g>
    </svg>
  </div>
</template>

<style scoped>
</style>

<script>

export default {
  mounted: function () {
    //this.svgheight = this.initheight
},
computed: {
  svgdata: function () {
    return this.$func.SVGDiagram(this.chartdata, this.chartoptions)
  },
  dataparser: function () {

    var ret = {tasks: [], measures: []}
    var tasks = []
    var arrmap = {}
    var measures = []
    // First sort by sort order

    // Add to array
    for (let i = 0; i < this.task.length; i++) {
        tasks.push(this.task[i])
        tasks[i].measure = 0
        arrmap[this.task[i].id] = {pos: i}
    }
    console.log(arrmap)

    // Add count of measures to parent
    for (let i = 0; i < this.measure.length; i++) {
      // console.log("parentid:  ", this.measure[i].parent, " has position: ", arrmap[this.measure[i].parent])
      tasks[arrmap[this.measure[i].parent].pos].measure += 1
    }

    // Calc position for tasks
    var ypos = this.defaults.offsetTop
    for (let i = 0; i < tasks.length; i++) {
        tasks[i].x = (this.defaults.indent * tasks[i].level)
        tasks[i].y = ypos 
        ypos = ypos + (this.defaults.linespace) + ((tasks[i].measure) * this.defaults.linespace)
        // console.log(tasks[i].y, tasks[i].measure.width)
    }

    // Calc position for measures
    ypos = this.defaults.offsetTop
    var cnt = 0, parpos = 0    
    for (let i = 0; i < this.measure.length; i++) {
      if (parpos < tasks[arrmap[this.measure[i].parent].pos].y) {cnt = 0}
        measures.push(this.measure[i])
        measures[i].x = 150
        measures[i].y = tasks[arrmap[this.measure[i].parent].pos].y + (cnt * this.defaults.linespace) 
        measures[i].start = 300
        measures[i].width = tasks[arrmap[this.measure[i].parent].pos].days * this.defaults.dayscale 
        cnt++
        parpos = tasks[arrmap[this.measure[i].parent].pos].y
        console.log("measure", measures[i].text, "parent pos:", tasks[arrmap[this.measure[i].parent].pos].y, "days", tasks[arrmap[this.measure[i].parent].pos].days)
    }

    ret.tasks = tasks
    ret.measures = measures
    console.log(ret)
    return ret
  }
},
  data() {
    return {
      defaults: {
        linespace: 20,
        indent: 20,
        offsetTop: 50,
        dayscale: 20,
      },
      task: [
        {id: 1, level: 1, text: "Rør 1",       progress: "10%", days: 17},
        {id: 2, level: 2, text: "Sandblåsing", progress: "10%", days: 25},
        {id: 3, level: 2, text: "Maling",      progress: "10%", days: 10},
        {id: 4, level: 1, text: "Rør 2",       progress: "10%", days: 12},
        {id: 5, level: 2, text: "Sandblåsing", progress: "10%", days: 45},
        ],
      measure: [
        {parent: 2, type: "progress", text: "Kvadrat",  count: 300, progress: 150, measure: "m2", stroke: "red"},
        {parent: 2, type: "days",     text: "Dager",    count: 25,  progress: 8, measure: "dg"},
        {parent: 2, type: "hours",    text: "Timer",    count: 700, progress: 250, measure: "hrs"},
        {parent: 2, type: "consume",  text: "Sand",     count: 2000,progress: 1800, measure: "kg"},
        {parent: 3, type: "progress", text: "Kvadrat (<-dailylog)",  count: 200, progress: 150, measure: "m2", stroke: "red"},
        {parent: 3, type: "days",     text: "Dager (<-teller f.start)",    count: 10,  progress: 17, measure: "dg"},
        {parent: 3, type: "hours",    text: "Timer (<-tidsbanken)",    count: 500, progress: 900, measure: "hrs"},
        {parent: 3, type: "consume",  text: "Maling (<-dailylog)",   count: 170, progress: 80, measure: "ltr"},
        {parent: 5, type: "progress", text: "Kvadrat",  count: 300, progress: 150, measure: "m2", stroke: "red"},
        {parent: 5, type: "days",     text: "Dager",    count: 17,  progress: 8, measure: "dg"},
        {parent: 5, type: "hours",    text: "Timer",    count: 700, progress: 250, measure: "hrs"},
        {parent: 5, type: "consume",  text: "Sand",     count: 2000,progress: 1800, measure: "kg"},
      ],  
      svgheight: 500,
      chartdata: {
      title: {text: "Prosjekt - arbeidspakker og plan"},
      ylabels: [{pos: "high", text: "Høy"}, {pos: "low", text: "Lav"}, {pos: "middle", text: "Hvor attraktivt er markedet"}],
      xlabels: [{pos: "low", text: "Høy"}, {pos: "high", text: "Lav"}, {pos: "middle", text: "Konkurransestyrke"}],
      rects: [ 
        {fill: "rgba(158,158,158, 0.5)", text: "Utnytte mens det varer", bx: 1, by: 1}, 
        {fill: "rgba(76, 175, 80, 0.5)", text: "Hold posisjonen", bx: 1, by: 2}, 
        {fill: "rgba(76, 175, 80, 0.5)", text: "Invester / vekst", bx: 1, by: 3}, 
        {fill: "rgba(255,152,0, 0.5)", text: "Verdt å holde på?", bx: 2, by: 1}, 
        {fill: "rgba(158,158,158, 0.5)", text: "Selektiv investering / holde inntjening", bx: 2, by: 2}, 
        {fill: "rgba(76, 175, 80, 0.5)", text: "Opprettholde eller forbedre", bx: 2, by: 3}, 
        {fill: "rgba(255,152,0, 0.5)", text: "Høste / selge", bx: 3, by: 1}, 
        {fill: "rgba(255,152,0, 0.5)", text: "Verdt å forbedre?", bx: 3, by: 2}, 
        {fill: "rgba(158,158,158, 0.5)", text: "Forbedre eller samarbeide", bx: 3, by: 3}, 
      ]
    },
      chartoptions: {boxx: 3, boxy: 3, height: 500, pad: {l: 50, r: 10, t: 50, b: 50}},
    };
  },
};
</script>