<template>
  <v-container align-center fill-height justify-center>
    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
  </v-container>
</template>

<script>
export default {
  mounted: function() {
    var self = this;
    this.axios
      .get("/api/cache", {params: {company: this.$store.state.activecompany}})
      .then(function(response) {
        if (response.data.pages != null){
          self.$store.commit("SET_PAGECONTENT", response.data.pages);
        } else {
          self.$store.commit("SET_PAGECONTENT", []);
        }
        //console.log(Object.keys(response.data.paths).length === 0 && response.data.paths.constructor === Object)
        if (Object.keys(response.data.paths).length === 0 && response.data.paths.constructor === Object){
          // Empty
          self.$store.commit("SET_PATHSCONTENT", {});
        }  else {
          // Filled
          self.$store.commit("SET_PATHSCONTENT", response.data.paths);
        }
        self.$store.commit("SET_USER", response.data.user);
        self.$store.commit("SET_USEREMAIL", response.data.useremail);
        self.$store.commit("SET_COMPANYCONTENT", response.data.company);
        //self.$store.commit("SET_ACTIVECOMPANY", Object.keys(response.data.company)[0]);
        self.$store.commit("SET_ACTIVECOMPANY", response.data.firstcompany);
        self.$router.push({ name: "home" });
      })
      .catch(function(error) {
        console.log(error.data);
        self.$store.commit("SET_PAGECONTENT", []);
        self.$store.commit("SET_COMPANYCONTENT", {});
        self.$store.commit("SET_ACTIVECOMPANY", null);
      })
      .finally(function() {
      });
  }
};
</script>
