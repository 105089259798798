<template>
  <div>
      <!-- Timeline canvas -->
      <v-row>
        <v-col cols="12">
          <v-card flat class="pa-2">
            <div style="width: 100%; position: relative" ref="svgelement1">
              <timeline-reservation-chart
                :initheight="height"
                :initwidth="width"
                :options="timelineReservationOptions"
                :data="data.data"
              />
            </div>
          </v-card>
        </v-col>
      </v-row>

      <!-- Equipment -->
      <v-row class="mt-2" dense>
        <!-- Venstre: Utstyrskategorier --> 
        <v-col cols="2">
          <v-card flat>
            <v-card-title class="blue-grey white--text pa-2"
              >Utstyrskategori</v-card-title
            >
            <v-treeview
              dense
              item-key="id"
              activatable
              :return-object="true"
              @update:active="treeClick"
              :items="categories"
            ></v-treeview>
          </v-card>
        </v-col>

        <!-- Midten: Filtrert liste -->
        <v-col cols="5">
          <v-card flat>
            <v-card-title class="blue-grey white--text pa-2"
              >Filtrert liste</v-card-title
            >
            <drag-drop-container
              listid="0"
              title=""
              :items="dynList(0)"
              @childdrag="childDrag"
              @childdrop="childDrop"
            ></drag-drop-container>
          </v-card>
        </v-col>

        <!-- Høyre: Prosjekt allokering -->
        <v-col cols="5">
          <v-card flat>
            <hent-prosjekt @emitproject="hentetProsjekt" @prechange="prechange"/>

            <div v-if="projectHeader.length">
              <v-card-text class="pa-0">
                <v-expansion-panels tile flat accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <span
                        >Planlagt oppstart den<span class="red--text pl-1">{{
                          extractFieldValue("projectHeader", "startdate").value
                        }}</span>
                        og avslutning den<span class="red--text pl-1">{{
                          extractFieldValue("projectHeader", "enddate").value
                        }}</span
                        >.</span
                      >
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Prosjekt
                      {{
                        extractFieldValue("projectHeader", "erp_projectid")
                          .value
                      }}
                      {{
                        extractFieldValue("projectHeader", "description").value
                      }}.
                      <div v-for="(w, i) in equipmentprojectwish" :key="i">
                        {{ w.line }}: {{ w.description }}
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-divider></v-divider>
                <v-divider></v-divider>
              </v-card-text>

              <div v-for="l in dropLists" :key="l.xid">
                <drag-drop-container
                  listid="1"
                  :title="l.title"
                  :items="dynList2(l.xid)"
                  @childdrag="childDrag"
                  @childdrop="childDrop"
                ></drag-drop-container>
              </div>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn outlined text @click="save">Lagre</v-btn>
              </v-card-actions>
            </div>
          </v-card>
        </v-col>
      </v-row>
  </div>
</template>


<script>
/*
https://learnvue.co/2020/01/how-to-add-drag-and-drop-to-your-vuejs-project/
https://www.drewtown.dev/post/the-magic-of-vues-dynamic-components-for-lists-of-data/
https://stackoverflow.com/questions/40322404/vuejs-how-can-i-use-computed-property-with-v-for
https://medium.com/js-dojo/component-communication-in-vue-js-ca8b591d7efa
*/
import DragDropContainer from "@/components/DropContainer.vue";
import HentProsjekt from "@/components/HentProsjekt.vue";
import requestData from "@/mixins/requestData.js";
import TimelineReservationChart from "@/components/GraphicTimelineReservation2.vue";

export default {
  mixins: [requestData],
  components: {
    DragDropContainer,
    HentProsjekt,
    "timeline-reservation-chart": TimelineReservationChart,
  },
  mounted: function () {
    //this.drawCanvas();
    this.width = this.$refs.svgelement1.clientWidth - 20;
    this.height = window.innerHeight - 50;
    this.timelineReservationOptions = {
        chart: {},
        plot: {
          reservation: {
            rectangleHeight: 16,
            rectangleGap: 4,
          },
        },
      };
    this.getEquipmentList();
    this.getEquipmentFlow();
  },
  methods: {
    getEquipmentList() {
      var self = this;
      this.requestJSON2("/api/equipment/component", {
        id: "c03hhp6vvhfioda46pa0",
        scope: "equipmentforplanning",
      }).then(function (e) {
        const resp = e[Object.keys(e)[0]];
        if (resp != null) {
          // Add key to rows
          for (var i = 0; i < resp.length; ++i) {
            resp[i].l1id = self.$func.Fnv32a(resp[i].l1nm);
            resp[i].l2id = self.$func.Fnv32a(resp[i].l1nm + "-" + resp[i].l2nm);
          }
          self.equipment =
            resp.sort((a, b) => a.shortid.localeCompare(b.shortid)) || [];
          //self.categories = DataToTree(resp);
          self.categories = self.$func.DataToTree(resp);
        }
      });
    },
    getEquipmentFlow() {
      var self = this;
      this.requestJSON2("/api/equipment/component", {
        id: "c03hhp6vvhfioda46pa0",
        scope: "equipmentflow",
      }).then(function (e) {
        const resp = e[Object.keys(e)[0]];
        self.equipflow = resp || [];
        self.drawCanvas();
      });
    },
    getProjectWish() {
      var self = this;
      this.requestJSON2("/api/equipment/component", {
        id: "c03hhp6vvhfioda46pa0",
        scope: "equipmentprojectwish",
        projectid: this.projectid,
      }).then(function (e) {
        const resp = e[Object.keys(e)[0]];
        self.equipmentprojectwish = resp;
      });
    },
    save() {
      // Include all equipment with new list (dropped)
      var items = [];
      this.equipment.forEach(function (element) {
        if (element.list !== "0") {
          items.push({ eid: element.xid, status: "planlagt" });
        }
      });

      // Ready parameters for post
      const params = new URLSearchParams();
      params.append("company", this.$store.state.activecompany);
      params.append("projectid", this.projectid);
      params.append("items", JSON.stringify(items));

      this.axios.post("/api/equipment/projectplanning", params).then(
        (response) => {
          console.log(response.status);
          this.$showMessage({ content: "Lagret", color: "info" });
        },
        (error) => {
          this.$showMessage({ content: error, color: "error" });
        }
      );
    },
    drawCanvas() {
      var data = [];

      // From selected equipment (category or subcategory), get the list of equipment ids
      var displayedEid = [];
      this.displayedItems.forEach(function (element) {
        displayedEid.push(element.xid);
      });

      if (this.equipflow === null) {
        return;
      }

      // Only show planned equipment from the selected list
      this.equipflow.forEach(function (element) {
        if (displayedEid.includes(element.eid)) {
          data.push({
            xid: element.eid,
            key: element.ekey,
            title: element.project,
            startdate: element.startdate,
            enddate: element.enddate,
          });
        }
      });

      /*
      var chartoptions = {
        chart: {},
        plot: {
          reservation: {
            rectangleHeight: 16,
            rectangleGap: 4,
          },
        },
      };
      // chartoptions.chart.width = this.$refs["svgelement1"].clientWidth;
      // chartoptions.chart.height = this.svgheight;
      */
      this.data.data = data;
    },
    treeClick(item) {
      // Get the clicked row
      if (item.length > 0) {
        this.treeSelected = item[0];

        // Selected from first level
        if (item[0].lvl === 1) {
          const result = this.equipment.filter((rw) => rw.l1id == item[0].id);
          this.displayedItems = result;
          this.drawCanvas();
        }

        // Selected from second level
        if (item[0].lvl === 2) {
          const result = this.equipment.filter((rw) => rw.l2id == item[0].id);
          this.displayedItems = result;
          this.drawCanvas();
        }
      } else {
        this.treeSelected = null;
        this.displayedItems = [];
        this.drawCanvas();
      }
    },
    childDrag: (evt, item) => {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemID", item.xid);
    },
    childDrop(evt, list) {
      const itemID = evt.dataTransfer.getData("itemID");
      const item = this.displayedItems.find((item) => item.xid == itemID);
      if (item === undefined) {
        this.$showMessage({
          content: "Velg korrekt utstyrskategori",
          color: "info",
        });
        return;
      }
      item.list = list;
      //console.log("Item", item.xid, item.title, "now belongs to list", list);
      if (list === "0") {
        this.removeItemFromProject(item);
      } else {
        this.addItemToProject(item);
      }
    },
    addItemToProject(item) {
      this.equipflow.push({
        eid: item.xid,
        ekey: item.shortid,
        pid: this.projectid,
        project: this.extractFieldValue("projectHeader", "description").value,
        startdate: this.extractFieldValue("projectHeader", "startdate").value,
        enddate: this.extractFieldValue("projectHeader", "enddate").value,
      });
      this.drawCanvas();
      if (this.extractFieldValue("projectHeader", "startdate").value === "") {
        this.$showMessage({
          content: "Prosjekt uten startdato",
          color: "warning",
        });
      }
    },
    removeItemFromProject(item) {
      var self = this;
      for (var i = 0; i < this.equipflow.length; ++i) {
        if (
          item.xid === self.equipflow[i].eid &&
          self.equipflow[i].pid === self.projectid
        ) {
          self.equipflow.splice(i, 1);
          break;
        }
      }
      this.drawCanvas();
    },
    reset() {
      var self = this;
      this.displayedItems = [];
      this.treeSelected = null;
      this.projecheader = [];
      this.projectid = null;
      for (var i = 0; i < this.equipment.length; ++i) {
        if (self.equipment[i].list !== "0") {
          self.equipment[i].list = "0";
        }
      }
    },
    prechange(e) {
      console.log("prechange", e)
      this.reset();
    },
    hentetProsjekt(e) {
      // Receive emitted list from component
      var self = this;
      this.reset();
      this.projectHeader = e;
      e.forEach(function (item) {
        if (item.field === "pid") {
          self.projectid = item.value;
        }
      });
      this.getProjectWish();
      this.projectToDropList();
    },
    projectToDropList() {
      // Loop each equipflow
      var self = this;
      var eids = [];
      this.equipflow.forEach(function (item) {
        // Check if active project in equipflow
        if (item.pid === self.projectid) {
          // Move equip list to "1" drop list
          eids.push(item.eid);
        }
      });
      // Move equip list to "1" drop list
      for (var i = 0; i < this.equipment.length; ++i) {
        if (eids.includes(self.equipment[i].xid)) {
          self.equipment[i].list = "1";
        }
      }
    },
  },
  computed: {
    dynList() {
      return function (listid) {
        return this.displayedItems.filter(function (item) {
          return item.list == listid;
        });
        /*
        if (listid == 0) {
          return this.tst.filter(function (item) {
            return item.list == listid;
          });          
        } else {
          return this.displayedItems.filter(function (item) {
            return item.list == listid;
          });
        }
        */
      };
    },
    dynList2() {
      return function (listid) {
        return this.equipment.filter(function (item) {
          return item.list == listid;
        });
        /*
        if (listid == 0) {
          return this.tst.filter(function (item) {
            return item.list == listid;
          });          
        } else {
          return this.displayedItems.filter(function (item) {
            return item.list == listid;
          });
        }
        */
      };
    },
  },
  data() {
    return {
      width: 1000,
      height: 200,
      data: {},

      equipflow: [],
      equipment: [],
      equipmentprojectwish: [],
      categories: [],
      treeSelected: null,
      projectHeader: [],
      projectid: null,
      dropLists: [{ xid: "1", title: "Utstyr allokert til prosjekt" }],
      displayedItems: [],
      timelineReservationOptions: {},
    };
  },
};

</script>