<template>
    <v-container class="pt-1" fluid>

      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">Mål og handlingsplan</v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field readonly v-model="editItem.area" label="Tilhører område" required></v-text-field>
              <v-text-field v-model="editItem.val" :counter="40" :rules="rules1" label="Strategisk mål" required></v-text-field>
              <v-text-field v-model="editItem.measuredescr" :counter="40" :rules="rules2" label="Kvantifisert målbeskrivelse" required></v-text-field>
              <v-text-field v-model="dialog" :counter="2" label="Disable" required></v-text-field>


              <v-btn color="primary" class="float-right" @click="saveStrategy">Lagre</v-btn>

              <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">
                Validate
              </v-btn>

              <v-btn color="error" class="mr-4" @click="resetval">
                Reset Form
              </v-btn>

              <v-btn color="warning" @click="resetValidation">
                Reset Validation
              </v-btn>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">
              I accept
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Chips -->
      <v-row no-gutters>
        <v-chip-group active-class="deep-purple white--text" v-model="ixArea" @change="clickChipsChanged">
          <v-chip label color="#E1BEE7" v-for="(tag, index) in stplan" :key="index">
            {{ tag.val }}
          </v-chip>
        </v-chip-group>
      </v-row>

      <v-row no-gutters>

        <!-- MÅL first col -->
        <v-col cols="3">
          <h3 class="ma-1 pl-2 grey--text text--darken-2">Mål</h3>
          <v-card class="ma-1" flat>
            <v-card-title class="red--text text--darken-3 py-2">
              {{carea.val}}<v-spacer></v-spacer>
              <v-btn v-if="ixArea" icon @click="edit('goal-new')">
                <v-icon color="blue lighten-2">mdi-pencil-plus-outline</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-list dense>
              <v-list-item-group v-model="ixGoal" color="primary" mandatory>
                <!--<v-list-item v-for="(item, index) in goals" :key="item.xid">-->
                <v-list-item v-for="(item, index) in cgoals" :key="item.xid">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.val"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action class="ma-0">
                    <v-btn v-if="ixGoal==index" icon @click="edit('goal-edit', index, item.xid)">
                      <v-icon color="blue lighten-2">mdi-pencil-outline</v-icon>
                    </v-btn>
                  </v-list-item-action>

                  <!--<v-list-item-icon><v-icon>mdi-pencil-outline</v-icon></v-list-item-icon>                      -->
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>

        <!-- DELMÅL second col -->
        <v-col cols="3">
          <h3 class="ma-1 pl-2 grey--text text--darken-2">
            Delmål
          </h3>
          <v-card class="ma-1" flat>
            <v-card-title class="red--text text--darken-3 py-2">{{ cgoal.val }}<v-spacer></v-spacer>
              <v-btn v-if="ixGoal!==undefined" icon @click="edit('subgoal-new')">
                <v-icon color="blue lighten-2">mdi-pencil-plus-outline</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-list dense>
              <v-list-item-group v-model="ixSubgoal" color="primary" mandatory>
                <v-list-item v-for="(item, index) in csubgoals" :key="index">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.val"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action class="ma-0">
                    <v-btn v-if="ixSubgoal==index" icon @click="edit('subgoal-edit')">
                      <v-icon color="blue lighten-2">mdi-pencil-outline</v-icon>
                    </v-btn>
                  </v-list-item-action>

                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>

        <!-- HANDLINGSPLANER third col -->
        <v-col cols="3">
          <h3 class="ma-1 pl-2 grey--text text--darken-2">Handlingsplan</h3>
          <v-card class="ma-1" flat>
            <v-card-title class="red--text text--darken-3 py-2">{{csubgoal.val}}
              <v-spacer></v-spacer>
              <v-btn v-if="ixSubgoal!==undefined" icon @click="edit('action-new')">
                <v-icon color="blue lighten-2">mdi-pencil-plus-outline</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-list dense>
              <v-list-item-group v-model="ixActionplan" color="primary">
                <v-list-item v-for="(item, i) in cactionplans" :key="i" @click="edit('actionplan-edit')">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.val"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>

        <!-- VALGT INFO -->
        <v-col cols="3">
          <h3 class="ma-1 pl-2 grey--text text--darken-2">Info</h3>
          <v-card class="ma-1 py-1" flat>
            <v-sheet class="ma-2 pa-2" style="background: rgba(187, 222, 251, 0.6);">
              <div class="h6 font-weight-bold">{{cgoal.val}}</div>
              <div>{{cgoal.measuredescr}}</div>
              <div class="caption">{{cgoal.responsible}}Gunnar Danielsen</div>
              <v-sheet class="pa-2" style="background: rgba(0, 0, 0, 0.1);">
                <div class="h6 font-weight-bold">{{csubgoal.val}}</div>
                <div>{{csubgoal.measuredescr}}</div>
                <div class="caption">{{csubgoal.responsible}}Gunnar Danielsen</div>
              </v-sheet>
            </v-sheet>
          </v-card>
        </v-col>

      </v-row>

      <v-row>
        <v-col>
          Vårt fundament<br>
          Misjon<br>
          Visjon<br>
          Verdier<br>
        </v-col>
        <v-col>
          Strategiske mål og delmål<br>
          Mål <br>
          Delmål 1<br>
          Delmål 2<br>
        </v-col>
        <v-col>
          Mål på suksess<br>
          For hvert mål og delmål<br>
        </v-col>
      </v-row>

    </v-container>
</template>

<style scoped>
</style>

<script>
import requestData from "@/mixins/requestData.js";

export default {
  mixins: [requestData],
  components: {
  },
  computed: {
    carea: function () {
      if (this.ixArea === undefined ) {return ''}
      return this.stplan[this.ixArea] || ''
    },
    cgoal: function () {
      if (this.ixArea === undefined || this.ixGoal === undefined ) {return ''}
      if(typeof this.stplan[this.ixArea] === 'undefined' || typeof this.stplan[this.ixArea].children[this.ixGoal] === 'undefined') {return ''}
      return this.stplan[this.ixArea].children[this.ixGoal] || ''
    },
    cgoals: function () {
      if (this.ixArea === undefined) {return []}
      return this.stplan[this.ixArea].children || []
    },
    csubgoal: function () {
      if (this.ixArea === undefined || this.ixGoal === undefined || this.ixSubgoal === undefined ) {return ''}
      return this.stplan[this.ixArea].children[this.ixGoal].children[this.ixSubgoal] || ''
    },
    csubgoals: function () {
      if (this.ixArea === undefined || this.ixGoal === undefined ) {return []}
      if(typeof this.stplan[this.ixArea] === 'undefined' || typeof this.stplan[this.ixArea].children[this.ixGoal] === 'undefined') {return ''}
      return this.stplan[this.ixArea].children[this.ixGoal].children || []
    },   
    cactionplan: function () {
      if (this.ixArea === undefined || this.ixGoal === undefined || this.ixSubgoal === undefined || this.ixActionplan === undefined ) {return '.'}
      return this.stplan[this.ixArea].children[this.ixGoal].children[this.ixSubgoal].children[this.ixActionplan].val || '..'
    },
    cactionplans: function () {
      if (this.ixArea === undefined || this.ixGoal === undefined || this.ixSubgoal === undefined || this.ixActionplan === undefined ) {return []}
      return this.stplan[this.ixArea].children[this.ixGoal].children[this.ixSubgoal].children[this.ixActionplan].children || []
    },   
},
  watch: {
    ixArea: function() {
      this.reset();
    }
  },
  methods: {
    saveStrategy() {
      /*
      var params = new URLSearchParams();
      params.append("company", this.$store.state.activecompany);
      params.append(
        "permission",
        JSON.stringify(this.goalItem)
      );
      console.log(params.toString())
      console.log(this.goalItem)
      // {"tbl":"bq9pcvbipt34tbtpnifg","row":"c1gqnqjc3e0grr5agidg","company":"bqf0kajipt37o10v29jg","col":"val","val":"Øke lønnsomhet nå","url":"/strategy/editable/bq9pa6jipt34rmci6rvg"}	
      */
     var obj = {}
     if (this.dialogScope === 'goaledit') {
        obj['tbl'] = 'goal'
        obj['']
     }
      console.log(obj)

    },
    clickChipsChanged() {
      /*
      var self = this;
      this.reset()
      this.stplan.forEach(function(item, i) {
        if (item.xid === val) {
          self.areaNm = item.val;
          self.ixArea = i;
        }
      });
      */
    },
    reset() {
      //this.areaNm = undefined;
      //this.ixArea = undefined;
      this.ixGoal = undefined;
      this.ixSubgoal = undefined;
      this.ixActionplan = undefined;
    },
    edit(e, index, id) {
      console.log(e, index, id);
      const t = e.split("-");
      
      this.editItem = {}

      this.editItem['company'] = this.$store.state.activecompany

      if (t[0] === 'goal') {
        this.editItem['tbl'] = 'goal'
        this.editItem['area'] = this.stplan[this.ixArea].val
        this.editItem['parent'] = this.stplan[this.ixArea].children[this.ixGoal].parent // this.stplan[this.ixArea].xid
        this.editItem['xid'] = this.stplan[this.ixArea].children[this.ixGoal].xid
        this.editItem['val'] = this.stplan[this.ixArea].children[this.ixGoal].val
        this.editItem['measuredescr'] = this.stplan[this.ixArea].children[this.ixGoal].measuredescr
      }
      this.dialog = true;
      this.dialogScope = e
      console.log(this.editItem)
    },
    validate() {
      this.$refs.form.validate();
    },
    resetval() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  },
  data() {
    return {
      stplan: [],
      editItem: {},
      ixArea: undefined,
      ixGoal: undefined,
      ixSubgoal: undefined,
      ixActionplan: undefined,
      dialog: false,
      valid: true,
      dialogScope: undefined,
      rules1: [
        v => !!v || "Tekst er obligatorisk",
        v => (v && v.length <= 40) || "Maks 40 tegn"
      ],
      rules2: [
        v => (v && v.length <= 40) || "Maks 40 tegn"
      ],
    };
  },
  mounted: function() {
    var self = this;
    const requestOne = this.axios.get("/api/strategy/component", {
      params: {
        id: "bq9otj3ipt34n4aoo6ng",
        media: "json",
        company: this.$store.state.activecompany
      }
    });
    const requestTwo = this.axios.get("/api/strategy/component", {
      params: {
        id: "bq9pcvbipt34tbtpnifg",
        media: "json",
        company: this.$store.state.activecompany
      }
    });
    const requestThree = this.axios.get("/api/strategy/component", {
      params: {
        id: "bq9pe3ript34ul3rvuhg",
        media: "json",
        company: this.$store.state.activecompany
      }
    });

    this.axios
      .all([requestOne, requestTwo, requestThree])
      .then(
        this.axios.spread((...responses) => {
          var resp = { ar: [], go: [], sg: [] };
          resp.ar = responses[0].data[Object.keys(responses[0].data)[0]];
          resp.go = responses[1].data[Object.keys(responses[1].data)[0]];
          resp.sg = responses[2].data[Object.keys(responses[2].data)[0]];
          //console.log(responses[0].data[Object.keys(responses[0].data)[0]])
          return resp;
        })
      )
      .then(function(resp) {
        var i;
        var lvl1 = {};
        var lvl2 = {};
        var lvl2a = [];
        var res = [];

        // LVL 1 - Init with children
        if (resp.ar !== null) {
          for (i = 0; i < resp.ar.length; i++) {
            lvl1[resp.ar[i].xid] = Object.assign(resp.ar[i], { children: [] });
          }
        }

        // LVL 2 - Push goals to area
        if (resp.go !== null) {
          for (i = 0; i < resp.go.length; i++) {
            lvl2[resp.go[i].xid] = Object.assign(resp.go[i], { children: [] });
          }
        }

        // LVL 3 - Push subgoals to goals
        if (resp.sg !== null) {
          for (i = 0; i < resp.sg.length; i++) {
            lvl2[resp.sg[i].parent].children.push(resp.sg[i]);
          }
        }

        // Change from map to array by removing key
        for (var key3 in lvl2) {
          if (lvl2.hasOwnProperty(key3)) {
            lvl2a.push(lvl2[key3]);
          }
        }

        // LVL 1 - Push lvl2 to level1
        for (i = 0; i < lvl2a.length; i++) {
          lvl1[lvl2a[i].parent].children.push(lvl2a[i]);
        }

        // Change from map to array by removing key
        for (var key2 in lvl1) {
          if (lvl1.hasOwnProperty(key2)) {
            res.push(lvl1[key2]);
          }
        }

        // Sort level 1 by key
        res.sort((a, b) => a.key.localeCompare(b.key));

        self.stplan = res;
      })
      .catch(errors => {
        // react on errors.
        console.log(errors);
      });
  }
};
</script>