<template>
  <div>
    <table class="mytable">
      <thead>
        <tr>
          <th v-for="(item, i) in header" :key="i" :class="item.class">{{ item.title }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in list" :key="i">
          <td v-if="complete">{{ item.classification }}</td>
          <td>{{ item.title }}</td>
          <td><v-text-field single-line dense v-model="item.comment" ></v-text-field></td>
          <td><v-text-field single-line :suffix="item.calcpricepr" dense v-model.number="item.countint" :disabled="item.disable == 1 ? true: false"></v-text-field></td>
          <td><v-text-field v-if="item.consume1measure" single-line :suffix="item.consume1measure" style="height: 30px" dense v-model.number="item.consume1am" :disabled="item.disable == 1 ? true: false"></v-text-field></td>
          <td><v-text-field v-if="item.durationmeasure" single-line :suffix="item.durationmeasure" style="height: 30px" dense v-model.number="item.durationam" :disabled="item.disable == 1 ? true: false"></v-text-field></td>
          <td class="text-right pr-4">{{ $formatNumber(mengde(i)) }} {{item.measure1}}</td>
          <td v-if="wpkg" class="text-right pr-4">{{ $formatNumber(mengde2(i)) }} {{item.wp_lineid}}</td>
          <td><v-text-field v-if="item.pricemeasure" single-line :suffix="item.pricemeasure" style="height: 30px" dense v-model.number="item.priceam" :disabled="item.disable == 1 ? true: false"></v-text-field></td>
          <td v-if="!wpkg" class="text-right">{{ $formatNumber(multiplisert(i)) }}</td>
          <td v-if="!wpkg" class="text-right">{{ multiplisertCO2(i).toFixed(1) }}</td>
          <td class="text-right"><v-btn @click="deleteItem(item.xid)" icon color="pink"><v-icon>mdi-delete-outline</v-icon></v-btn></td>
        </tr>
        <tr>
          <td>Total</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-right total">
            {{ $formatNumber(total) }}
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/myDatatable.scss";
::v-deep .v-text-field {
  min-width: 130px!important;
} 
</style>



<script>
/*eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
export default {
  props: {header: Array, list: Array, complete: Boolean, wpkg: Boolean },
  methods: {
    deleteItem: function(i) {
      this.$emit('deleterow', i);
    },
    multiplisert: function(i) {
      this.list[i].amount = Math.round(
        (this.list[i].priceam === undefined || this.list[i].priceam === 0
          ? 1
          : this.list[i].priceam) *
          (this.list[i].countint === undefined || this.list[i].countint === 0
            ? 1
            : this.list[i].countint) *
          (this.list[i].consume1am === undefined || this.list[i].consume1am === 0
            ? 1
            : this.list[i].consume1am) *
          (this.list[i].durationam === undefined || this.list[i].durationam === 0
            ? 1
            : this.list[i].durationam)
      );
      return this.list[i].amount;
    },
    mengde: function(i) {
      this.list[i].quantity1am = Math.round(
        (this.list[i].countint === undefined ? 0 : this.list[i].countint) *
          (this.list[i].durationam === undefined || this.list[i].durationam === 0
            ? 1
            : this.list[i].durationam) *
          (this.list[i].consume1am === undefined || this.list[i].consume1am === 0 ? 1 : this.list[i].consume1am)
      );
      return this.list[i].quantity1am;
    },
    mengde2: function(i) {
      this.list[i].quantity1am = Math.round(
        (this.list[i].countint === undefined ? 0 : this.list[i].countint) *
          (this.list[i].durationam === undefined || this.list[i].durationam === 0
            ? 1
            : this.list[i].durationam)
      );
      return this.list[i].quantity1am;
    },
    multiplisertCO2: function(i) {
      this.list[i].co2sumam = Math.round(
        (this.list[i].co2am === undefined ? 0 : this.list[i].co2am) *
          this.list[i].countint *
          (this.list[i].consume1am === undefined || this.list[i].consume1am === 0
            ? 1
            : this.list[i].consume1am) *
          (this.list[i].durationam === undefined || this.list[i].durationam === 0
            ? 1
            : this.list[i].durationam)
      );
      return this.list[i].co2sumam;
    }
  },
  computed: {
    total: function() {
      return this.list.reduce(function(total, item) {
        return total + item.amount;
      }, 0);
    }
  }
};
</script>