<template>
    <v-card
      class="mb-2 pa-2"
      @drop="onDrop($event, listid)"
      @dragover.prevent
      @dragenter.prevent
      tile
      flat
    >
      <h4 class="pa-0">{{ title }}</h4>
      <!--<span class="pl-5" style="font-size: 70%">(liste: {{ listid }})</span>-->
      <v-sheet class="pa-1" style="border: 2px dashed #BDBDBD; min-height: 40px; background-color: #F5F5F5">
      <v-card-text
        v-for="item in items"
        :key="item.xid"
        class="mb-1 py-1"
        style="background-color: #E0E0E0"
        draggable
        @dragstart="startDrag($event, item)"
      >
        {{ item.title }}
      </v-card-text>
      </v-sheet>
    </v-card>
</template>    

<script>
export default {
  name: "DropContainer",
  props: ["listid", "title", "items"],
  methods: {
    startDrag(evt, item) {
      this.$emit('childdrag', evt, item);
    },
    onDrop(evt, list) {
      this.$emit('childdrop', evt, list);
    },
  },
};
</script>