<template>
  <v-main id="grad">
    <Notifier></Notifier>
    <v-container fluid fill-height>
      <v-sheet width="100%" class="ma-auto pa-2" rounded elevation="1" style="
          background: rgba(255, 255, 255, 0.5);
          backdrop-filter: blur(8px);
          height: 100%;
        ">
        <ToolbarLeftNavTitle :title="$store.state.paths[$route.path].title" class="mx-n2 mt-n2" />
        <router-view></router-view>
      </v-sheet>
    </v-container>
  </v-main>
</template>

<style scoped>
#grad {
  background-image:#e2d8d8;background-image:  
  radial-gradient(at 98% 76%, hsla(262,56%,77%,1) 0, transparent 50%),  
  radial-gradient(at 18% 87%, hsla(189,75%,85%,1) 0, transparent 50%),  
  radial-gradient(at 84% 26%, hsla(297,41%,75%,1) 0, transparent 62%),  
  radial-gradient(at 0% 0%, hsla(23,40%,76%,1) 0, transparent 50%);  
  height: 100%;
}
</style>

<script>
import Notifier from "@/components_global/Notifier.vue";

export default {
  components: { Notifier },
};
</script>