<template>
  <v-toolbar light flat class="mb-3" dense>
    <!--<v-text-field dense solo-inverted hide-details flat clearable class="ml-n4" label="Søk" v-model="search" append-icon="mdi-magnify"></v-text-field>-->
    <span v-for="(value, propertyName, i) in filteronlayout" :key="i">
      <div v-if="filters.hasOwnProperty(propertyName)" class="pl-2">
        <v-autocomplete @change="autocompleteChanged" chips clearable deletable-chips dense multiple small-chips solo-inverted hide-details flat :label="value" :items="dropdownUniqueList(propertyName)" v-model="filters[propertyName]">
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 3" color="purple lighten-4">
              <span>
                {{ item }}
              </span>
            </v-chip>
            <span v-if="index === 3" class="grey--text caption">
              (+{{ filters[propertyName].length - 3 }} flere)
            </span>
          </template>
        </v-autocomplete>
      </div>
    </span>
  </v-toolbar>

</template>


<script>
export default {
  props: {
    data: {
      type: Array
    }
  },
  mounted: function() {
   // Check if layout has filters
    const p = this.$store.state.paths[this.$route.path];
    if (!p.hasOwnProperty('pageparameters')) {return}
    if (!p.pageparameters.hasOwnProperty('filters')) {return}
   
   this.filteronlayout = this.$store.state.paths[this.$route.path].pageparameters.filters;

    // Creates keys in the object "filters" based on page setup
    //console.log(this.$store.state.paths[this.$route.path].pageparameters.filters);
    var self = this;
    for (var key in p.pageparameters.filters) {
      if (p.pageparameters.filters.hasOwnProperty(key)) {
        self.$set(self.filters, key, []);
      }
    }
  },
  methods: {
    autocompleteChanged() {
      // Message parent on filter change
      this.$emit("dropdownFiltersChanged", this.filters);
    },
    dropdownUniqueList(val) {
      // dropdownUniqueList fills each dropdown with unique values
      /* https://codepen.io/wolfpup/pen/gOadmPx */
      // data will narrow down other filters to selected, alternativ use items
      if (this.data === undefined) {
        return [];
      }
      var unique = [...new Set(this.data.map(d => d[val] || ""))];
      // Remove empty
      unique = unique.filter(function(el) {
        return el != "";
      });
      return unique.sort();
    }
  },
  data() {
    return {
      filters: {},
      filteronlayout: {}
    };
  }
};
</script>
