<template>
  <div style="width: 100%; height: 100% position: relative">
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" :height="svgheight" style="border:1px solid #f0f0f0">
      <defs>

        <marker id="triangle" viewBox="0 0 10 10"
              refX="1" refY="5"
              markerUnits="strokeWidth"
              markerWidth="10" markerHeight="10"
              orient="auto">
          <path d="M 0 0 L 10 5 L 0 10 z" fill="#000"/>
        </marker>                  

        <!--<circle id="myCircle" cx="0" cy="0" r="50" />

        <linearGradient id="myGradient" gradientTransform="rotate(90)">
          <stop offset="20%" stop-color="gold" />
          <stop offset="90%" stop-color="red" />
        </linearGradient>-->


        <!--<pattern id="grid" width="11" height="11" patternUnits="userSpaceOnUse">
          <rect fill="transparent" x="0" y="0" width="9" height="9" />
          <rect fill="aliceblue" x="10" y="0" width="1" height="10" />
          <rect fill="aliceblue" x="0" y="10" width="11" height="1" />
        </pattern>-->

      </defs>
      <!--<rect fill="url(#grid)" x="0" y="0" width="100%" height="100%"/>-->
      <!--<use x="500" y="100" xlink:href="#myCircle" fill="url('#myGradient')" />-->

      <!-- Title -->
      <g text-anchor="middle" dominant-baseline="middle" >
        <text style="font-weight: bold; font-size: 20px" :x="svgdata.title.x" :y="svgdata.title.y">
          {{ svgdata.title.text }}
        </text>
      </g>

      <!-- Y Labels -->
      <g text-anchor="middle" dominant-baseline="middle" :transform="svgdata.ylabels.g.transform">
        <text v-for="(item, i1) in svgdata.ylabels.items" :x="item.x" :y="item.y" :key="`a`+i1">
          {{ item.title }}
        </text>
      </g>

      <!-- X Labels -->
      <g text-anchor="middle" dominant-baseline="middle">
        <text v-for="(item, i2) in svgdata.xlabels.items" :x="item.x" :y="item.y" :key="`b`+i2">
          {{ item.title }}
        </text>
      </g>

      <!-- Chart lines -->
      <line v-for="(item, i3) in svgdata.lines.items" :key="`c`+i3" fill="none" stroke="black" :x1="item.x1" :x2="item.x2" :y1="item.y1" :y2="item.y2" marker-end="url(#triangle)"/>

      <!-- Rectangels -->
      <g :stroke="svgdata.rects.g.stroke" :fill="svgdata.rects.g.fill" :stroke-width="svgdata.rects.g.strokew">
        <rect v-for="(item, i4) in svgdata.rects.items" :key="`d`+i4" :fill="item.fill" :x="item.x" :y="item.y" :width="item.w" :height="item.h"/>
      </g>

      <!-- Text in rectangels -->
      <foreignObject v-for="(item, i5) in svgdata.rects.items" :key="`e`+i5" :x="item.x" :y="item.y" :width="item.w" :height="item.h">
        <div xmlns="http://www.w3.org/1999/xhtml" style="text-align: center; vertical-align: middle;">
            {{ item.title }}
        </div>
      </foreignObject>


    </svg>
  </div>
</template>

<script>

export default {
  props: {
    data: {
        type: Object
    },
    options: {
        type: Object
    },
    initheight: Number,
  },  
  mounted: function () {
    this.svgheight = this.initheight
},
computed: {
  svgdata: function () {
    return this.$func.SVGDiagram(this.data, this.options)
  }
},
  data() {
    return {
      svgheight: 100,
    };
  },
};
</script>