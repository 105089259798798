<template>
  <!-- Filters -->
  <v-sheet rounded class="pa-3" v-if="gridready">
    <v-row>
      <v-col>
        <h3 class="float-left font-weight-medium grey--text text--darken-1 pb-2">{{ title }}</h3>
        <div class="float-right">
          <span class="caption purple--text text--lighten-2" v-if="readat">Data lest {{new Date(readat*1000).toLocaleDateString("nb-NO")}} {{new Date(readat*1000).toLocaleTimeString("nb-NO")}}</span>
          <v-btn v-if="excel" outlined small color="purple" @click="requestMedia($route.path, {media: excel, id: $route.params.page,})" class="ml-2">Excel<v-icon right>mdi-download</v-icon></v-btn>
          <v-btn v-if="refresher" outlined small color="purple" @click="refresh()" class="ml-2">Oppdater<v-icon right>mdi-cloud-refresh</v-icon></v-btn>
        </div>
        <v-progress-linear :active="loading" :indeterminate="loading" bottom height="3" background-color="purple lighten-5" color="deep-purple accent-4" style="height: 3px" class="mb-2"></v-progress-linear>
      </v-col>
    </v-row>

    <v-toolbar light flat class="mb-3" dense>
      <v-text-field dense solo-inverted hide-details flat clearable class="ml-n4" label="Søk" v-model="search" append-icon="mdi-magnify"></v-text-field>
      <span v-for="(header, i) in options" :key="i">
        <div v-if="filters.hasOwnProperty(header.value)" class="pl-2">
          <v-autocomplete chips clearable deletable-chips dense multiple small-chips solo-inverted hide-details flat :label="header.text" :items="columnValueList(header.value)" v-model="filters[header.value]">
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 3" color="purple lighten-4">
                <span>
                  {{ item }}
                </span>
              </v-chip>
              <span v-if="index === 3" class="grey--text caption">
                (+{{ filters[header.value].length - 3 }} flere)
              </span>
            </template>
          </v-autocomplete>
        </div>
      </span>
    </v-toolbar>

    <!-- Data table -->
    <graphic-datatable :options="options" :data="filteredItems" :groupby="groupby" :search="search"></graphic-datatable>

  </v-sheet>
</template>

<script>
/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
import requestData from "@/mixins/requestData.js";
import requestmedia from "@/mixins/requestMedia.js";
import GraphicDatatable from "@/components/GraphicDatatable.vue";

export default {
  mixins: [requestData, requestmedia],
  components: {
    "graphic-datatable": GraphicDatatable,
  },
  created: function() {
    this.layout =
      JSON.parse(
        JSON.stringify(this.$store.getters.navbylink(this.$route.path).layout)
      ) || [];
  },
  mounted: function() {
    this.requestData();
  },
  computed: {
    filteredItems() {
      /* https://codepen.io/wolfpup/pen/gOadmPx */
      return this.items.filter(d => {
        return Object.keys(this.filters).every(f => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
    },
  },
  methods: {
    /*
    summit(col) {      
      if (this.filteredItems.length === 0) {
        return 0;
      }
      var sum = 0
      this.filteredItems.forEach(element => {
        sum += element[col] == undefined ? 0 : element[col]
      });
      return sum
    },
    */
    refresh() {
      this.requestData();
    },
    columnValueList(val) {
      /* https://codepen.io/wolfpup/pen/gOadmPx */
      // filteredItems will narrow down other filters to selected, alternativ use items
      var unique = [...new Set(this.filteredItems.map(d => d[val] || ""))];
      // Remove empty
      unique = unique.filter(function(el) {
        return el != "";
      });
      return unique.sort();
    },
    requestData: function() {
      var self = this;
      const obj = {
        id: self.$route.params.page,
        company: this.$store.state.activecompany,
        format: "datatable"
      };
      //console.log(obj)
      this.requestJSON2("/api/" + this.$route.name, obj).then(function(e) {
        const resp = e[Object.keys(e)[0]];
        /*
        //Convert to date
        var arrayLength = resp.data.length;
        for (var i = 0; i < arrayLength; i++) {
          for (const key in resp.data[i]) {
              if (key.toLowerCase().endsWith('date')) {
                resp.data[i][key] = new Date(Date.parse(resp.data[i][key])).toLocaleString("no-NB", { day: "numeric", month: "short", year: "numeric" })
              }
          }          
        }
        */
        self.title = resp.title;
        self.items = resp.data || [];
        self.options = resp.options || [];
        if (typeof resp.parameters !== "undefined") {
          if (typeof resp.parameters["groupby"] !== "undefined") {
            self.groupby = resp.parameters["groupby"];
          }
          if (
            typeof resp.parameters["readat"] !== "undefined" &&
            resp.parameters["readat"] > 0
          ) {
            self.readat = resp.parameters["readat"];
          }
          if (
            typeof resp.parameters["refresh"] !== "undefined" &&
            resp.parameters["refresh"]
          ) {
            self.refresher = true;
          }
          if (
            typeof resp.parameters["excel"] !== "undefined" &&
            resp.parameters["excel"]
          ) {
            self.excel = resp.parameters["excel"];
          }
        }
        self.options.forEach(function(item) {
          if (item.dropfilter !== "undefined" && item.dropfilter === true) {
            self.$set(self.filters, item.value, []);
          }
        });
        self.gridready = true;
      });
    }
    // eslint-disable-next-line no-unused-vars
  },
  data() {
    return {
      items: [],
      options: [],
      groupby: undefined,
      loading: false,
      title: "",
      refresher: false,
      excel: false,
      search: "",
      filters: {},
      gridready: false,
      readat: false
    };
  }
};
</script>