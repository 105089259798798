<template>
  <div>
    <!-- Autocomplete -->
    <v-row no-gutters>
      <v-col>
        <hent-prosjekt @emitproject="hentetProsjekt" @prechange="prechange" />
      </v-col>
    </v-row>
    <!-- ****************** -->
    <v-row>
      <v-col cols="12" class="pb-0">
        <v-card class="mx-auto" color="blue-grey lighten-5" outlined tile>
          <v-chip-group active-class="deep-purple white--text" v-model="activeCategory">
            <h4 class="pa-2">Utstyr</h4>
            <v-chip color="#E1BEE7" v-for="tag in equipmentCategoryEquipment" :key="tag" :value="tag">
              {{ tag }}
            </v-chip>
          </v-chip-group>
          <v-divider class="mx-4"></v-divider>
          <v-chip-group active-class="deep-purple white--text" v-model="activeCategory">
            <h4 class="pa-2">Forbruksmatriell</h4>
            <v-chip color="#E1BEE7" v-for="tag in equipmentCategoryConsumables" :key="tag" :value="tag">
              {{ tag }}
            </v-chip>
          </v-chip-group>
          <v-divider class="mx-4"></v-divider>
          <v-chip-group active-class="deep-purple white--text" v-model="activeCategory">
            <h4 class="pa-2">Tjeneste</h4>
            <v-chip color="#E1BEE7" v-for="tag in equipmentCategoryService" :key="tag" :value="tag">
              {{ tag }}
            </v-chip>
          </v-chip-group>
        </v-card>
      </v-col>
    </v-row>

    <!-- Varer -->
    <v-row flex-nowrap>
      <v-col cols="7">
        <v-card class="pa-2" flat rounded>
          <v-card-title>
            <div>Utstyrsliste</div>
            <v-spacer></v-spacer>
            <v-text-field v-model="equipmentSearch" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table dense :search="equipmentSearch" :headers="equipmentHeaders" :items="equipmentSelection" hide-default-footer :items-per-page="-1" single-expand :expanded.sync="equipmentExpanded" item-key="xid" show-expand>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon color="blue" small @click="addItemToCart(item)"> mdi-cart </v-icon>
            </template>

            <template v-slot:expanded-item="{ item }" >
              <td :colspan="4">
                Leverandør <strong>{{ item.sup_nm }}</strong>
                <br/>Varenr: {{ item.sup_item_id }}
                <br/>Beskrivelse: {{ item.description }}
                <br/><v-btn v-if="item.equipment_url" :href="item.equipment_url" target="_blank" class="ma-2" outlined color="indigo"><v-icon>mdi-link-variant</v-icon></v-btn>
              </td>
              <td :colspan="5" class="pa-2">
                <v-img :lazy-src="item.image_url" max-width="250" :src="item.image_url"></v-img>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <!-- Handlevogn -->
      <v-col cols="5">
        <v-card class="pa-2" flat rounded>
          <v-card-title>Handlevogn
            <v-spacer></v-spacer>
            <v-icon large color="teal lighten-2"> mdi-cart </v-icon>
          </v-card-title>
          <v-card-subtitle>PO nr: {{ poid }}</v-card-subtitle>

          <table class="mytable">
            <thead>
              <tr>
                <th class="text-left">Utstyr</th>
                <th class="text-left" style="width: 50px">Antall</th>
                <th class="text-right" style="width: 60px">Enh.</th>
                <th class="text-right" style="width: 50px">Pris</th>
                <th class="text-right" style="width: 70px">Sum</th>
                <th class="text-right" style="width: 20px"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in cartItems" :key="i">
                <td class="text-left">{{ item.title }}</td>
                <td>
                  <v-text-field single-line dense v-model.number="item.count" :rules="[rules.required, rules.numberRule]"></v-text-field>
                </td>
                <td class="text-right">{{ item.unitsam * item.count }} {{ item.measure }}</td>
                <td class="text-right">{{ $formatNumber(item.priceam) }}</td>
                <td class="text-right">{{ $formatNumber(multiplisert(i)) }}</td>
                <td class="text-right">
                  <v-btn x-small @click="deleteFromCart(i)" icon color="pink">
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>Total</td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right">
                  {{ $formatNumber(total) }}
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <div class="text-center">
            <v-btn class="ma-2" depressed color="success">Send bestilling</v-btn>
            <v-btn class="ma-2" depressed color="primary">Lagre kladd</v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/myDatatable.scss";
</style>

<script>
/*eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import requestData from "@/mixins/requestData.js";
import HentProsjekt from "@/components/HentProsjekt.vue";

export default {
  mixins: [requestData],
  components: {
    HentProsjekt
  },
  watch: {
    /*
    projectid: function(val, oldVal) {
      if (val !== oldVal) {
        this.hentetProsjekt = false;
        this.projectHeader = {};
      }
    },*/
    activeCategory: function(val, oldVal) {
      if (val !== oldVal) {
        var newArray = this.equipmentAll.filter(function(el) {
          return el.category == val;
        });
        newArray.forEach(function(part, index, theArray) {
          theArray[index].count = 1;
        });
        this.equipmentSelection = newArray;
      }
    }
  },
  mounted: function() {
    var self = this;

    this.$newPOID().then(function(res) {
      self.poid = res.poid;
    });

    /*
    this.requestJSON2("/api/equipment/component", {
      id: "bqcbqsjipt3aimtdrtp0",
      scope: "keyval"
    }).then(function(e) {
      self.autocompleteitems = e[Object.keys(e)[0]] || [];
    });
*/
    this.requestJSON2("/api/equipment/component", {
      id: "btqnqibr5fo2vn0g5pmg",
      scope: "excel"
    }).then(function(e) {
      self.equipmentAll = e[Object.keys(e)[0]];
    });
  },
  data() {
    return {
      rules: {
        required: value => !!value || "Required.",
        numberRule: v => {
          //if (!v.trim()) return true;
          if (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) return true;
          return "Number has to be between 0 and 999";
        }
      },
      poid: "",
      equipmentAll: [],
      equipmentSelection: [],
      equipmentExpanded: [],
      equipmentSearch: "",
      equipmentHeaders: [
        { text: "Underkategori", value: "subcategory" },
        { text: "Vare", value: "title" },
        { text: "Mål", value: "measure", align: "right" },
        { text: "Enheter", value: "unitsam", align: "left" },
        { text: "Innkjøpspris", value: "priceam", align: "right" },
        { text: "Bestill", value: "actions", sortable: false, align: "center" }
      ],
      activeCategory: "",
      cartItems: [],

      projectHeader: [],
      projectid: null,
      // Autocomplete search
      //autocompleteitems: [],

      // Prosjektdetaljer
      //hentetProsjekt: false,
      //projectHeader: {},

      // Selected project
      //projectid: null,

    };
  },
  computed: {
    equipmentCategoryEquipment() {
      return this.uniqueCategoryByClassification("utst");
    },
    equipmentCategoryService() {
      return this.uniqueCategoryByClassification("tjst");
    },
    equipmentCategoryConsumables() {
      return this.uniqueCategoryByClassification("Forbruksmatriell");
    },
    total: function() {
      return this.cartItems.reduce(function(total, item) {
        return total + item.amount;
      }, 0);
    }
  },
  methods: {
    reset() {
      console.log("Must implement reset!");
    },
    prechange(e) {
      console.log("prechange", e);
      this.reset();
    },
    hentetProsjekt(e) {
      // Receive emitted list from component
      var self = this;
      this.reset();
      this.projectHeader = e;
      e.forEach(function(item) {
        if (item.field === "pid") {
          self.projectid = item.value;
        }
      });
    },
    deleteFromCart: function(index) {
      this.cartItems.splice(index, 1);
    },
    multiplisert: function(i) {
      var amount = Math.round(
        this.cartItems[i].priceam * this.cartItems[i].count
      );
      this.cartItems[i].amount = amount;
      return this.cartItems[i].amount;
    },
    uniqueCategoryByClassification(cls) {
      const array = this.equipmentAll || [];
      const result = [];
      const map = new Map();
      for (const item of array) {
        if (item.classification === cls) {
          if (!map.has(item.category)) {
            map.set(item.category, true); // set any value to Map
            result.push(item.category);
          }
        }
      }
      return result;
    },
    addItemToCart(item) {
      this.cartItems.push(Object.assign({ amount: 0 }, item));
    }
    /*customFilter(item, queryText) {
      const textOne = item.text.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },*/
    /*
    hentProsjekt() {
      var self = this;
      this.requestJSON2("/api/equipment/component", {
        id: "bqcbqsjipt3aimtdrtp0",
        scope: "project",
        projectid: this.projectid
      }).then(function(e) {
        self.projectHeader = e[Object.keys(e)[0]];
      });
    }*/
  }
};
</script>