<template>
    <v-app-bar color="transparent" dense flat>
      <!--<v-btn class="ml-0" small :to="{ name: 'home' }" icon>
        <v-icon>mdi-home</v-icon>
      </v-btn>-->
      <v-btn class="pl-1" small @click="$goBack" icon>
        <v-icon color="grey">mdi-arrow-left-thin</v-icon>
      </v-btn>
      <!--<v-divider inset vertical></v-divider>-->
      <v-toolbar-title class="font-weight-regular grey--text text--darken-1 pl-2">{{title}}</v-toolbar-title>
      <!-- <div class="subtitle-1 blue-grey--text text--darken-1 font-italic font-weight-medium">{{page.subtitle}}</div>-->
      <v-spacer></v-spacer>

      <CompanyUser/>
      <!--
      <v-btn @click="disableGrid" icon small>
          <span v-if="gridPreview"><v-icon small>mdi-monitor-edit</v-icon></span>
          <span v-else><v-icon small>mdi-monitor-lock</v-icon></span>              
      </v-btn>
      -->
      <FullscreenButtons/>
    </v-app-bar>
    <!--
  <span>
    <v-btn class="ml-0" small :to="{ name: 'home' }" icon>
      <v-icon>mdi-home</v-icon>
    </v-btn>
    <v-btn class="mx-3" small @click="$goBack" icon>
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <v-divider inset vertical></v-divider>
    <v-toolbar-title
      class="font-weight-medium grey--text text--darken-2 ml-3"
      >{{ this.title }}</v-toolbar-title
    >
  </span>-->
</template>

<script>
export default {
  props: ["title"],
};
</script>