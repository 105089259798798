<template>
  <div>
    <!--<app-bar-simple title="page.title"></app-bar-simple>-->
    <v-container class="ma-0 pa-0" fluid>
      <v-carousel v-model="model" height="100%">
      <FullscreenButtonsAbsolute/>

        <v-carousel-item v-for="(slide, i) in slides" :key="i" style="height: 100vh">
          <v-sheet :color="colors[i]" height="100%" tile style="overflow: scroll; padding-bottom: 50px">
            <v-row class="ma-0" align="center" justify="center">

              <!-- TEASER --> 
              <template v-if="slide.display == 'teaser'">
                <v-col cols="10">                  
                  <div class="display-2 py-5 font-weight-thin">
                    {{ slide.title }}
                  </div>
                </v-col>
                <template v-for="item in slide.children">
                <v-col cols="9" :key="item.xid">
                  <div class="blockquote display-1 pt-5">{{ item.text }}</div>
                </v-col>
                </template>
              </template>

              <!-- LIST --> 
              <template v-if="slide.display == 'list'">
                <v-col cols="11">
                  <div class="display-2 py-5 font-weight-thin">
                    {{ slide.title }}
                  </div>
                </v-col>
                <v-col cols="10">
                   <template v-for="item in slide.children">
                      <v-list-item :key="item.xid">
                        <v-list-item-icon>
                            <v-icon color="yellow darken-2">mdi-bullseye-arrow</v-icon>
                        </v-list-item-icon>                     
                        <v-list-item-content>
                        <v-list-item-title style="font-size: 1.5em">{{ item.text }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon color="blue darken-2">mdi-chevron-right</v-icon>
                        </v-list-item-icon>                     
                        <v-list-item-content>
                        <v-list-item-title style="font-size: 1.2em">{{ item.measuredescr }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item dense v-for="itm in item.children" :key="itm.xid">
                        <v-list-item-icon class="pl-4" >
                            <v-icon color="yellow darken-2">mdi-circle-medium</v-icon>
                        </v-list-item-icon>                     
                        <v-list-item-content>
                        <v-list-item-title style="font-size: 1em; color: rgba(0,0,0,0.7)" >{{ itm.text }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                   </template>
                </v-col>
              </template>

            </v-row>

          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-container>
  </div>
</template>

<style scoped>
.blockquote {
  border-left: 10px solid rgba(0,0,0,0.3);;
  quotes: "\201C""\201D""\2018""\2019";
  line-height: 1.4em;
}
.blockquote:before {
  color: rgba(0,0,0,0.3);
  content: open-quote;
  font-size: 2.5em;
  line-height: 0.0em;
  margin-right: 0.1em;
  vertical-align: -0.4em;
}
.blockquote:after {
  color: rgba(0,0,0,0.3);
  content: close-quote;
  font-size: 2.5em;
  line-height: 0.0em;
  margin-left: 0.1em;
  vertical-align: -0.45em;
}
.blockquote div {
  display: inline;
}
</style>

<script>
import requestData from "@/mixins/requestData.js";

export default {
  mixins: [requestData],
  mounted: function () {
    var self = this;
    //this.requestJSON2("/api/strategy/component", {
    this.requestJSON2("/api/strategy/page", {
      //id: "c1ee6n6cie6qgke54qsg",
      id: self.$route.params.page,
    }).then(function (e) {
      const resp = e[Object.keys(e)[0]];
      //console.log(resp)

      // Get an array with XID, insert children tag
      var ar = [];
      for (var i = 0; i < resp.length; i++) {
        ar.push(resp[i].xid);
        resp[i]["children"] = [];
      }

      // Assign children
      i = resp.length;
      while (i--) {
        if (resp[i].parent.length > 0) {
          var ix = ar.indexOf(resp[i].parent);
          resp[ix].children.unshift(resp[i]);
          resp.splice(i, 1); // Remove element that became children
        }
      }
console.log(resp)
      self.slides = resp;
    });
  },
  data: () => ({
    model: 0,
    colors: [
      "red",
      "pink",
      "purple lighten-1",
      "teal lighten-3",
      "teal lighten-2",
      "teal lighten-1",
      "teal",
      "teal lighten-1",
      "teal lighten-2",
      "teal lighten-3",
    ],
    slides: [],
  }),
};
</script>