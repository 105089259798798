function chartoptdefault(opt = {}) {
  var chartoptionsdefault = {
    chart: {
      width: 0,
      height: 0,
      xOffset: 70,
      yOffset: 20,
    },
    today: true,
    timeline: {},
    plot: {
      reservation: {
        rectangleHeight: 13,
        rectangleGap: 4,
      }
    },
    sorter: "key",
    font: "14px Roboto",
    colors: [
      "rgba(156, 39, 176, 0.5)",
      "rgba(33, 150, 243, 0.5)",
      "rgba(0, 150, 136, 0.5)",
      "rgba(205, 220, 57, 0.5)",
      "rgba(0, 188, 212, 0.5)",
      "rgba(255, 152, 0, 0.5)",
      "rgba(76, 175, 80, 0.5)",
      "rgba(121, 85, 72, 0.5)",
      "rgba(96, 125, 139, 0.5)",
      "rgba(244, 67, 54, 0.5)",
    ]
  };
  //return {...chartoptionsdefault, ...opt}
  return extend(true, chartoptionsdefault, opt);

}

// Pass in the objects to merge as arguments.
// For a deep extend, set the first argument to `true`.
// https://gomakethings.com/vanilla-javascript-version-of-jquery-extend/
var extend = function () {

  // Variables
  var extended = {};
  var deep = false;
  var i = 0;
  var length = arguments.length;

  // Check if a deep merge
  if (Object.prototype.toString.call(arguments[0]) === '[object Boolean]') {
    deep = arguments[0];
    i++;
  }

  // Merge the object into the extended object
  var merge = function (obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        // If deep merge and property is an object, merge properties
        if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]') {
          extended[prop] = extend(true, extended[prop], obj[prop]);
        } else {
          extended[prop] = obj[prop];
        }
      }
    }
  };

  // Loop through each object and conduct a merge
  for (; i < length; i++) {
    var obj = arguments[i];
    merge(obj);
  }

  return extended;

};

function isValidDate(dateString) {
  if (dateString === undefined) {return false}
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if(!dateString.match(regEx)) return false;  // Invalid format
  var d = new Date(dateString);
  var dNum = d.getTime();
  if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0,10) === dateString;
}

function numberOfDays(start, end) {
  const date1 = new Date(start);
  const date2 = new Date(end);

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay)+1;

  return isNaN(diffInDays) ? 0 : diffInDays;
}

function canvasTimelineMeta(chartoptions) {
  var date = new Date();

  // Set start date and end date
  //var startdate = new Date(date.getFullYear(), 0, 1);
  //startdate.setMonth(startdate.getMonth() + 3); // replace 3 from options
  var startdate = new Date(date.getFullYear(), date.getMonth() - 2, 1);

  //var enddate = new Date(date.getFullYear(), 12, 0);
  var enddate = new Date(startdate.getFullYear(), startdate.getMonth() + 12, 0); // replace 12 from options
  //console.log(startdate, enddate)

  // Calculate the time difference of two dates
  var daysBetween = (enddate.getTime() - startdate.getTime()) / (1000 * 3600 * 24) + 1;

  var elastic = (chartoptions.chart.width - chartoptions.chart.xOffset) / daysBetween || 1;
  var dayscale = 86400;

  // var startdate = new Date(date.getFullYear(), date.getMonth()-1, 1).valueOf() / 1000;
  // var enddate = new Date(date.getFullYear(), date.getMonth() + 12, 1).valueOf() / 1000;

  return {
    xOffset: chartoptions.chart.xOffset,
    elastic: elastic,
    dayscale: dayscale,
    startdate: startdate,
    cstdate: startdate.valueOf() / 1000,
    cendate: enddate.valueOf() / 1000,
  };
}


function xPosFromStart(obj, stStr, enStr) {
  // Date from dd.mm.yyyy
  var stParts = stStr.split(".");
  var enParts = enStr.split(".");

  // month is 0-based, that's why we need dataParts[1] - 1
  var stdate = new Date(+stParts[2], stParts[1] - 1, +stParts[0]).valueOf() / 1000;
  var endate = new Date(+enParts[2], enParts[1] - 1, +enParts[0]).valueOf() / 1000;

  var st = Math.round(((stdate - obj.cstdate) / obj.dayscale) * obj.elastic) + obj.xOffset
  var en = Math.round(((endate - stdate) / obj.dayscale) * obj.elastic)
  if (isNaN(st)) {
    st = obj.xOffset + 50
    en = 1
  }

  return [st, en];
}

function timelineData(data, obj) {

  const chartoptions = chartoptdefault(obj)
  chartoptions.timeline = canvasTimelineMeta(chartoptions);
  var plot = 'reservation';

  var prints = 12;
  //var date = new Date();
  var i, j;
  var monthNameShort = ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Des"]
  var r = {
    xlabels: [],
    xgrids: [],
    xgrid: [],
    xlines: [],
    ylabels: [],
    series: []
  };

  // Vertical
  for (i = 0; i < prints; i++) {
    //var sdate = new Date(date.getFullYear(), i, 1);
    var sdate = new Date(chartoptions.timeline.startdate.getFullYear(), chartoptions.timeline.startdate.getMonth() + i, 1)
    //console.log(new Date(sdate.getFullYear(), i, 1), sdate)
    var x = Math.round(
      ((sdate.valueOf() / 1000 - chartoptions.timeline.cstdate) / chartoptions.timeline.dayscale) * chartoptions.timeline.elastic
    );
    // Text labels
    // r.xlabels.push({x: x + chartoptions.chart.xOffset, y: 10, text: sdate.toLocaleString("no-NB", { day: "numeric", month: "short" })})
    r.xlabels.push({
      x: x + chartoptions.chart.xOffset,
      y: 10,
      text: sdate.getDate() + '. ' + monthNameShort[sdate.getMonth()]
    })
    // Vertical line
    r.xgrids.push({
      x1: x + chartoptions.chart.xOffset,
      x2: x + chartoptions.chart.xOffset,
      y1: chartoptions.chart.yOffset - 4,
      y2: chartoptions.chart.height
    })
  }

  // Horizontal top line
  r.xgrid.push({
    x1: 0,
    x2: x + chartoptions.chart.width,
    y1: chartoptions.chart.yOffset - 3,
    y2: chartoptions.chart.yOffset - 3
  })

  // DATA

  // Sort data by short name
  data.sort(function (a, b) {
    //console.log(a[chartoptions.sorter], b[chartoptions.sorter])
    if (a[chartoptions.sorter] < b[chartoptions.sorter]) {
      return -1;
    }
    if (a[chartoptions.sorter] > b[chartoptions.sorter]) {
      return 1;
    }
    return 0;
  });

  // Sett posision to same for same equipment (eid)
  // For plot = reservation
  var id = "";
  i = 0;
  data.forEach(function (element) {
    if (element.id == id) {
      element.pos = i;
    } else {
      id = element.id;
      i++;
      element.pos = i;
    }
  });

  j = -1;
  data.forEach(function (element) {
    var values = xPosFromStart(chartoptions.timeline, element.stdt, element.endt);
    var rcolor = chartoptions.colors[element.pos % 10];

    // Label left
    r.ylabels.push({
      text: element.key,
      x: 10,
      y: element.pos * (chartoptions.plot[plot].rectangleHeight + chartoptions.plot[plot].rectangleGap),
      style: "font: " + chartoptions.font,
    })

    // Rectangel
    var y = element.pos * (chartoptions.plot[plot].rectangleHeight + chartoptions.plot[plot].rectangleGap)
    r.series.push({
      style: "fill: " + rcolor,
      stroke: rcolor.replace("0.5", "0.3"),
      x: Math.max(values[0], 0),
      y: y,
      w: Math.max(Math.min(values[0], 0) + values[1] || chartoptions.chart.width, 0),
      h: chartoptions.plot[plot].rectangleHeight,
      dataTooltip: element.stdt + ' - ' + element.endt,
      txt: element.title,
      txtx: Math.max(values[0], chartoptions.chart.xOffset),
      txtstyle: "font: " + chartoptions.font,
    })

    // Horizontal helper lines
    if (j % 3 == 2) {
      r.xlines.push({
        x1: 0,
        x2: x + chartoptions.chart.width,
        y1: y - (chartoptions.plot[plot].rectangleGap/2),
        y2: y - (chartoptions.plot[plot].rectangleGap/2)
      })      
    }
    j++
  });

  // Today
  if (chartoptions.today) {
    var dt = new Date();
    x = Math.round(
      ((dt.valueOf() / 1000 - chartoptions.timeline.cstdate) / chartoptions.timeline.dayscale) * chartoptions.timeline.elastic
    );
    r.xgrids.push({
      cls: "today",
      x1: x + chartoptions.chart.xOffset,
      x2: x + chartoptions.chart.xOffset,
      y1: chartoptions.chart.yOffset - 4,
      y2: chartoptions.chart.height
    })
  }

  return r

}

function svgDimensions(opt) {

  var optDefault = {
    boxx: 0,
    boxy: 0,
    pad: {
      l: 0,
      r: 0,
      t: 0,
      b: 0
    },
    height: 0,
    length: 0,
    box: {
      h: 0,
      w: 0
    },
    zero: {
      x: 0,
      y: 0,
      l: 0
    },
  }

  var o = extend(true, optDefault, opt);

  o.length = o.height
  o.zero.x = o.pad.l
  o.zero.y = o.height - o.pad.b
  o.zero.l = o.length - o.pad.l - o.pad.r
  o.box.w = Math.floor((o.length - o.pad.l - o.pad.r) / o.boxx)
  o.box.h = Math.floor((o.zero.y - o.pad.t) / o.boxy)

  return o
}

function svgData(data, opt) {

  var d = {
    title: {
      text: ""
    },
    ylabels: {
      g: {
        transform: "translate(0,0) rotate(-90)"
      },
      items: []
    },
    xlabels: {
      g: {},
      items: []
    },
    rects: {
      g: {
        stroke: "black",
        strokew: 1,
        fill: "transparent"
      },
      items: []
    },
    lines: {
      items: []
    },
  }

  // Return if empty object is passed
  if (Object.keys(data).length === 0) {
    return d
  }

  var i;
  // Title
  if (data.title.text.length > 0) {
    d.title = {
      text: data.title.text,
      x: (opt.length) / 2,
      y: 20
    }
  }
  //if (data.ylabels[i].pos == "middle")  {d.xlabels.items.push({x: (opt.zero.l)/2 + opt.pad.l, y: opt.zero.y + 30, text:  data.xlabels[i].text})} 

  // Y labels
  if (data.ylabels === undefined) {return d}
  for (i = 0; i < data.ylabels.length; i++) {
    if (data.ylabels[i].pos == "high") {
      d.ylabels.items.push({
        x: -opt.pad.t - 30,
        y: opt.zero.x - 15,
        title: data.ylabels[i].title
      })
    }
    if (data.ylabels[i].pos == "low") {
      d.ylabels.items.push({
        x: -opt.zero.y + 30,
        y: opt.zero.x - 15,
        title: data.ylabels[i].title
      })
    }
    if (data.ylabels[i].pos == "middle") {
      d.ylabels.items.push({
        x: -(opt.zero.y + opt.pad.t) / 2,
        y: opt.zero.x - 30,
        title: data.ylabels[i].title
      })
    }
  }
  // X labels
  for (i = 0; i < data.xlabels.length; i++) {
    if (data.ylabels[i].pos == "high") {
      d.xlabels.items.push({
        x: opt.zero.x + 30,
        y: opt.zero.y + 15,
        title: data.xlabels[i].title
      })
    }
    if (data.ylabels[i].pos == "low") {
      d.xlabels.items.push({
        x: opt.length - opt.pad.r - 30,
        y: opt.zero.y + 15,
        title: data.xlabels[i].title
      })
    }
    if (data.ylabels[i].pos == "middle") {
      d.xlabels.items.push({
        x: (opt.zero.l) / 2 + opt.pad.l,
        y: opt.zero.y + 30,
        title: data.xlabels[i].title
      })
    }
  }
  // Boxes
  if (data.rects !== undefined) {
    for (i = 0; i < data.rects.length; i++) {
      d.rects.items.push({
        fill: data.rects[i].fill,
        title: data.rects[i].title,
        x: opt.zero.x + ((data.rects[i].bx - 1) * opt.box.w),
        y: opt.zero.y - (data.rects[i].by * opt.box.h),
        w: opt.box.w,
        h: opt.box.h
      })
    }
  }

  // Lines
  d.lines.items.push({
    x1: opt.zero.x,
    x2: opt.length - opt.pad.r,
    y1: opt.zero.y,
    y2: opt.zero.y
  })
  d.lines.items.push({
    x1: opt.zero.x,
    x2: opt.zero.x,
    y1: opt.zero.y,
    y2: opt.pad.t
  })

  return d
}

function dataToTree(data) {
  // Format of data: l1id | l1nm | l2id | l2nm
  if (data === null) {
    return;
  }
  if (data.length === 0) {
    return;
  }

  // Get level 1 and level 2 unique values to map key
  var map1 = {},
    map2 = {},
    i,
    node = {},
    res = [];
  for (i = 0; i < data.length; i += 1) {
    // Create a distinct map with level 1 items
    map1[data[i].l1id] = {
      id: data[i].l1id,
      name: data[i].l1nm,
      lvl: 1,
      children: [],
    };
    // Create a distinct map with level 2 items, with id to parent
    map2[data[i].l2id] = {
      parentid: data[i].l1id,
      id: data[i].l2id,
      name: data[i].l2nm,
      lvl: 2,
    };
  }

  // Push map2 level 2 children to parent id array
  for (var k2 in map2) {
    node = {
      id: map2[k2].id,
      name: map2[k2].name,
      lvl: map2[k2].lvl
    };
    map1[map2[k2].parentid].children.push(node);
  }

  // Change from map to array by removing key
  for (var key in map1) {
    if (map1.hasOwnProperty(key)) {
      res.push(map1[key]);
    }
  }

  // Sort level 1 by name
  res.sort((a, b) => a.name.localeCompare(b.name));

  // Sort level 2 children by name
  for (i = 0; i < res.length; i += 1) {
    res[i].children.sort((a, b) => a.name.localeCompare(b.name));
  }

  return res;
}

function fnv32a(str) {
  var FNV1_32A_INIT = 0x811c9dc5;
  var hval = FNV1_32A_INIT;
  for (var i = 0; i < str.length; ++i) {
    hval ^= str.charCodeAt(i);
    hval +=
      (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
  }
  return hval >>> 0;
}

function camelcaseToSentence(text) {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export const func = {
  TimelineData(data, opt = {}) {
    /* expected data: {
       id:      id - same id will be placed on same line
       key:     left text on canvas (x-axis)
       title:   printed inside timebar
       stdt:    startdate "dd.MM.yyyy"
       endt:    enddate   "dd.MM.yyyy"
     } */
    return timelineData(data, opt)
  },
  DataToTree(data) {
    /* expected data: {
    } */
    return dataToTree(data)
  },
  Fnv32a(str) {
    /* expected data: {
    } */
    return fnv32a(str)
  },
  CamelcaseToSentence(text) {
    return camelcaseToSentence(text)
  },
  SVGDiagram(data, opt = {}) {
    var dims = svgDimensions(opt)
    var datas = svgData(data, dims)
    return datas
  },
  ApexChartOptions(type) {
    return apexChartOptions(type)
  },
  NumberOfDays(start, end) {
    return numberOfDays(start, end) 
  },
  IsValidDate(dateString) {
    return isValidDate(dateString)  
  },
}

import nb from "apexcharts/dist/locales/nb.json";

function apexChartOptions(type) {
  if (type == 'bar') {
    return {
      title: {
        text: '-',
        align: 'left',
        style: {
          fontSize:  '18px',
          fontWeight:  '500',
          color:  '#757575'
        },        
      },
      chart: {
        locales: [nb],
        defaultLocale: "nb",
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: false
          }
        },
        background: "#fff",
        stacked: true,
        animations: {
          enabled: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "80%"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        type: "datetime"
      },
      yaxis: {
        title: {
          text: "Tkr"
        },
        labels: {
          formatter: function (val) {
            return (val / 1000)
              .toFixed(0)
              .replace(".", ",")
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return (
              (val / 1000)
              .toFixed(0)
              .replace(".", ",")
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " tkr"
            );
          }
        }
      }
    }
  }
  if (type == 'treemap') {
    return {
      title: {
        text: '-',
        style: {
          fontSize:  '18px',
          fontWeight:  '500',
          color:  '#757575'
        },          
      },
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false
        }
      },
      chart: {      
        locales: [nb],
        defaultLocale: "nb",
      animations: {
        enabled: false
      }      
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return (
              (val / 1000)
              .toFixed(0)
              .replace(".", ",")
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " tkr"
            );
          }
        }
      }
    }
  }
  return {}
}