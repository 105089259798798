<template>
  <div>
    <table class="mytable">
      <thead>
        <tr>
          <th v-for="(item, i) in header" :key="i" :class="item.class">{{ item.title }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <v-icon @click="move('activity', item.classification, item.orderint, -1.5)" :disabled="index==0" color="teal lighten-3">mdi-arrow-up-bold</v-icon>
            <v-icon @click="move('activity', item.classification, item.orderint, 1.5)" :disabled="index==(items.length-1)" color="teal lighten-3">mdi-arrow-down-bold</v-icon>
          </td>
          <td v-if="complete">{{ item.classification }}</td>
          <td>{{ item.title }}</td>
          <td><v-text-field single-line dense v-model="item.comment" ></v-text-field></td>
          <td><input v-model="item.frdt" type="date" min="2019-01-01" max="2050-01-01"></td>
          <td><input v-model="item.todt" type="date" min="2019-01-01" max="2050-01-01"></td>
          <td>{{ days(index) }}</td>
          <td><v-text-field single-line :suffix="item.calcpricepr" dense v-model.number="item.countint" :disabled="item.disable == 1 ? true: false"></v-text-field></td>
          <td><v-text-field v-if="item.consume1measure" single-line :suffix="item.consume1measure" style="height: 30px" dense v-model.number="item.consume1am" :disabled="item.disable == 1 ? true: false"></v-text-field></td>
          <td class="text-right pr-4">{{ $formatNumber(mengde1(index)) }} {{item.measure1}}</td>
          <td><v-text-field v-if="item.consume2measure" single-line :suffix="item.consume2measure" style="height: 30px" dense v-model.number="item.consume2am" :disabled="item.disable == 1 ? true: false"></v-text-field></td>
          <td><span v-if="item.consume2measure" class="text-right pr-4">{{ $formatNumber(mengde2(index)) }} {{item.measure2}}</span></td>
          <td class="text-right"><v-btn @click="deleteItem(item.xid)" icon color="pink"><v-icon>mdi-delete-outline</v-icon></v-btn></td>
        </tr>
        <tr>
          <td>Total</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-right total">
            {{ $formatNumber(total()) }} timer
          </td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/myDatatable.scss";
  input[type="date"] {
    color: blue;
  }  

::v-deep .v-text-field {
  min-width: 130px!important;
}  

</style>

<script>
/*eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
export default {
  props: {header: Array, items: Array, complete: Boolean, wpkg: Boolean },
  methods: {
    days: function(i) {
    if (this.$func.IsValidDate(this.items[i].todt) && this.$func.IsValidDate(this.items[i].frdt)) {
      this.items[i].daysint = this.$func.NumberOfDays(this.items[i].frdt, this.items[i].todt)
      return this.items[i].daysint
      }
    },
    move: function(section, classification, orderint, direction) {
      this.$emit('emitmove', section, classification, orderint, direction);
    }, 
    deleteItem: function(i) {
      this.$emit('deleterow', i);
    },
    mengde1: function(i) {
      this.items[i].quantity1am = Math.round(
        (this.items[i].countint === undefined ? 0 : this.items[i].countint) *
        (this.items[i].consume1am === undefined ? 0 : this.items[i].consume1am)
      );
      return this.items[i].quantity1am;
    },
    mengde2: function(i) {
      this.items[i].quantity2am = Math.round(
        (this.items[i].countint === undefined ? 0 : this.items[i].countint) *
          (this.items[i].consume2am === undefined || this.items[i].consume2am === 0 ? 1 : this.items[i].consume2am)
      );
      return this.items[i].quantity2am;
    },
    total: function() {
      return this.items.reduce(function(total, item) {
        return total + item.quantity1am;
      }, 0);
    }
  },
  computed: {
  }
};
</script>