export default {
   methods: {
      cellClick(e, a) {
         // @row-click="myMethod"
         if (a.getField() == "delete") {
           if (confirm('Are you sure you want to delete this entry?')) {
           const tabulatorInstance = Array.isArray(this.$refs.tabulator) ? this.$refs.tabulator[0].getInstance() : this.$refs.tabulator.getInstance();

           var data = a.getData()
           /* console.log({tbl: this.wid, row: data.xid, company: this.$store.state.activecompany, projectid: this.projectid, })*/
           //console.log(a.getField("xid"))
           var path = '/api/' + this.$route.path.split('/')[1] + '/delete'

         // If this.wid is not set - read ID from first component
         const wid = this.wid === undefined ? this.layout[0].i : this.wid

           let req = {
            tbl: wid, 
            row: data.xid, 
            company: this.$store.state.activecompany,              
          }

         // Add properties for projectplan
         if (this.projectid !== null) {
            Object.assign(req, {projectid: this.projectid })
         }

         // Add properties for equipment
         if (this.equipmentid !== null) {
            Object.assign(req, {equipmentid: this.equipmentid})
         }


           this.axios
               .get(path, {params: req})
               .then(function (response) {
                 console.log(response.status)
                 tabulatorInstance.deleteRow(a.getRow())
               })
               .catch(function (error) {
                 console.log(error);
               })
               .finally(function () {
                 // always executed
               });
           }
         }
       },      
      addRow(handling = "") {

         var self = this;
         let req = {}

         // Add properties for projectplan
         if (handling == 'project') {
            Object.assign(req, {section: self.section, project_id: self.projectid})
         }

         const tabulatorInstance = Array.isArray(this.$refs.tabulator) ? this.$refs.tabulator[0].getInstance() : this.$refs.tabulator.getInstance();

         // const tabulatorInstance = this.$refs.tabulator.getInstance();
         this.$newXID().then(function(res) {   
            Object.assign(req, {xid: res.xid})  
            tabulatorInstance.addRow(req, true)
            .then(function(row){
               //row - the row component for the row updated or added
               if (handling == 'project') {
                  self.cellEdited(row, "section")
               }      
            })
            .catch(function(error){
               //handle error updating data
               alert(error)
               //console.log(error)
            });         
         })

         //console.log("component: ", key);
         //console.log(tabulatorInstance);
         //tabulatorInstance.clearData();
         // console.log(this.layout);
         //this.$newXID().then(res => console.log(res))
      },
      cellEdited(a, f) {

         this.loading = true;
         var data = a.getData();
         var field = f
   
         try {
            field = a.getField()
         } catch (e) {
            if (e instanceof TypeError) {
               // true
            } 
         }
         
         // If this.wid is not set - read ID from first component
         const wid = this.wid === undefined ? this.layout[0].i : this.wid

         // Set up message
         let req = {
            tbl: wid,
            //tbl: Object.keys(this.widgets)[0], 
            row: data["xid"],
            company: this.$store.state.activecompany,
            col: field,
            val: data[field],
            // val: data[a.getField()] 
            url: this.$route.path
          }

          // Add properties for projectplan
         if (this.projectid !== null) {
            Object.assign(req, {projectid: this.projectid})
         }

         // Add properties for equipment
         if (this.equipmentid !== null) {
            Object.assign(req, {equipmentid: this.equipmentid})
         }
         
         // Send message
         if (this.$store.state.socket.isConnected) {
           this.$socket.sendObj(req);
         } else {
           //notifier alert("Websocket connection error")
           this.$showMessage({ content: "Websocket connection error", color: "error"})
         }
         this.loading = false;
         /*
         var self = this;
         setTimeout(function(){ self.loading = false; }, 1000);
         */

      /*
      https://github.com/olifolkerd/tabulator/issues/519
      https://github.com/olifolkerd/tabulator/issues/550
      console.log("component")
      console.log(a.getData())
      console.log(a.getElement())
      console.log(a.getRow())
      console.log(a.getRow().getData())
      console.log(this.widgets)
      console.log(Object.keys(this.widgets)[0])
      console.log(this.$refs.tabulator)
      console.log(this.$refs.tabulator[0].getInstance())
      */         
     },
   }
}