<template>
  <div>
    <v-row class="pt-2 px-2">

      <v-col cols="4">
        <hent-prosjekt @emitproject="hentetProsjekt" @prechange="prechange" />
        <div class="pt-3">Prosjektleder <span class="red--text pl-1">{{ extractFieldValue("projectHeader", "projectmanager").value }}</span><br/>Planlagt oppstart <span class="red--text pl-1">{{ extractFieldValue("projectHeader", "startdate").value}}</span> og avslutning <span class="red--text pl-1">{{extractFieldValue("projectHeader", "enddate").value}}</span></div>
      </v-col>

      <v-col cols="8">
        <v-expansion-panels rounded flat accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span>
                <v-badge color="error" :content="this.selected.length">Klikk for å åpne meldingen og sende denne på mail</v-badge>
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider></v-divider>
              <div class="pa-2 body-2" v-html="htmlMessage"></div>
              <v-divider></v-divider>
              <v-row>
                <v-col offset="1">
                  <v-radio-group class="mt-2" v-model="opencase">
                    <template v-slot:label>
                      <div>Opprett oppfølgingssak</div>
                    </template>
                    <v-radio label="Ja" value=true></v-radio>
                    <v-radio label="Nei" value=false></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col class="pt-5">
                  <v-badge class="mt-2" bordered color="error" :content="this.selected.length" overlap>
                    <v-btn :disabled="!opencase" class="white--text" color="primary" depressed @click="createemlfile">
                      Opprett e-post
                      <v-icon right dark> mdi-email-outline </v-icon>
                    </v-btn>
                  </v-badge>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

      </v-col>

    </v-row>
    <v-row>
      <v-col cols="12">

        <v-data-table dense v-model="selected" :headers="headers" :items="items" item-key="xid" show-select class="elevation-1" :items-per-page="-1" hide-default-footer show-group-by>
          <template v-slot:header="{ props }">
            <tr>
              <th style="border-bottom: double rgba(0, 0, 0, 0.12); border-left: 1px solid #EEEEEE" class="text-right" v-for="(header, index) in props.headers" :key="index">
                <span v-if="header.math == 'sum'">
                  <span style="height: 36px; color: blue" class="subtitle-2">{{ $formatNumber(summit(header.value)) }}</span>
                </span>
              </th>
            </tr>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/datatable.scss";
</style>

<script>
import requestData from "@/mixins/requestData.js";
import HentProsjekt from "@/components/HentProsjekt.vue";

export default {
  mixins: [requestData],
  components: { HentProsjekt },
  methods: {
    messageBody: function() {
    return "Hei,<br/>Ref. prosjekt " + this.extractFieldValue("projectHeader", "erp_projectid").value + " " + this.extractFieldValue("projectHeader", "description").value + "<br/>Vi kan ikke se å ha fått returnert utstyr fra prosjektet i hht. listen under.<br/>Vennligst gi en tilbakemelding på hvor utstyret befinner seg, og når det returneres til lager.<br/>"
    },
    createemlfile: function() {
      /* https://github.com/papnkukn/eml-format https://stackoverflow.com/questions/27951843/use-javascript-to-create-an-html-email-in-microsoft-outlook */
      var body = this.message()
      const message = [];
      body.forEach(i => {
        message.push('<tr><td align="left">' + i[0] + '</td><td align="right">' + i[1] + '</td><td align="right">' + i[2] + '</td><td align="left">' + i[3] + '</td><td align="left">' + i[4] + "</td></tr>");
      });

    var emlCont = 'To: '+ this.$store.state.useremail + '\n';
    emlCont += 'Cc: '+ this.$store.state.useremail + '\n';
    emlCont += 'Subject: Prosjektinnkjøp - returkontroll\n';
    emlCont += 'X-Unsent: 1'+'\n';
    emlCont += 'Content-Type: text/html; charset="utf-8"'+'\n';
    emlCont += ''+'\n';

    emlCont += "<!DOCTYPE html><html><head></head><body style='background-color: white;'>"
    emlCont += this.messageBody()
    emlCont += '<table border="0" cellpadding="0" cellspacing="10"><tr><th align="left">Beskrivelse</th><th align="right">Antall</th><th align="right">Sum</th><th align="left">Leverandør</th><th align="left">Fakturanr</th></tr>'
    emlCont +=  message.join("")
    emlCont += "</table></body></html>";

    // console.log(emlCont) ;
    var ts = Date.now()
    var textFile = null;
    var data = new Blob([emlCont], {type: 'text/plain'});
    if (textFile !== null) {
      window.URL.revokeObjectURL(textFile);
    }

    textFile = window.URL.createObjectURL(data);
    // console.log(textFile);

    var a = document.createElement('a'); //make a link in document
    var linkText = document.createTextNode('fileLink_'+ts);
    a.appendChild(linkText) ;
    a.href = textFile;
    a.id = 'fileLink_'+ts;
    a.download = "Returkontroll_"+ Date.now() +".eml"; //'filenameTest.eml' ;
    a.style.visibility = "hidden";
    document.body.appendChild(a) ;
    document.getElementById('fileLink_'+ts).click();
    window.URL.revokeObjectURL(textFile);
    },    
    prechange(e) {
      console.log("prechange", e);
      this.reset();
    },
    hentetProsjekt(e) {
      // Receive emitted list from component
      var self = this;
      this.reset();
      this.projectHeader = e;
      e.forEach(function(item) {
        if (item.field === "pid") {
          self.projectid = item.value;
        }
        if (item.field === "erp_projectid") {
          self.localprojectid = item.value;
        }
      });
      this.requestItems();
    },
    reset: function() {
      this.items = [];
      this.headers = [];
      this.selected = [];
      this.projectHeader = [];
      this.projectid = null;
      this.localprojectid = null;
      this.opencase = null;
    },
    requestItems: function() {
      var self = this;
      this.requestJSON2("/api/equipment/component", {
        id: "c5u41ukbcv48c1h952v0",
        format: "datatable",
        projectid: this.localprojectid,
      }).then(function(e) {
        const resp = e[Object.keys(e)[0]];
        for (let i = 0; i < resp.data.length; i++) {
          resp.data[i].xid = i;
        }
        self.items = resp.data || [];
        self.headers = resp.options || [];
        //console.log(resp);
      });
    },
    summit(col) {
      if (this.items.length === 0) {
        return 0;
      }
      var sum = 0;
      this.items.forEach(element => {
        sum += element[col] == undefined ? 0 : element[col];
      });
      return sum;
    },
    message() {
      const body = [];
      this.selected.forEach(i => {
        body.push([i.beskrivelse, i.antall, i.sum, i.leverandor, i.fakturanr])
      });
      return body
    },
  },
  computed: {
    htmlMessage: function() {
      var body = this.message()
      const message = [];
      body.forEach(i => {
        message.push('<tr><td align="left">' + i[0] + '</td><td align="right">' + i[1] + '</td><td align="right">' + i[2] + '</td><td align="left">' + i[3] + '</td><td align="left">' + i[4] + "</td></tr>");
      });
      return (
        this.messageBody() +
        '<table border="0" cellpadding="0" cellspacing="10"><tr><th align="left">Beskrivelse</th><th align="right">Antall</th><th align="right">Sum</th><th align="left">Leverandør</th><th align="left">Fakturanr</th></tr>' +
        message.join("") +
        "</table>"
      );
    },
  },
  data() {
    return {
      selected: [],
      headers: [],
      items: [],
      projectHeader: [],
      projectid: null,
      localprojectid: null,
      opencase: null
    };
  }
};
</script>
