<template>
  <v-container>
    <v-layout text-center wrap>
      <v-flex xs12>
        <div ref="wdrComponent"></div>
      </v-flex>

      <v-flex mb-4>
        {{ $route.params.table }}
        <button id="btn" class v-on:click="getJokes">Get Jokes</button>
      </v-flex>
      {{ info }}
    </v-layout>
  </v-container>
</template>

<script>
import WebDataRocks from "webdatarocks";

export default {
  methods: {
    /*
    customizeToolbar: function (toolbar) {
        var tabs = toolbar.getTabs(); // get all tabs from the toolbar
        toolbar.getTabs = function() {
            delete tabs[0]; // delete the first tab
            return tabs;
        }
    }, */
    getJokes: function() {
      this.loading = true;
      this.axios.get("/api/economy/pivot/cus").then(
        response => {
          this.loading = false;
          this.info = response;
        } /*, (error)  =>  {
        this.loading = false;
      }*/
      );
    }
  },
  data() {
    return {
      report: null,
      info: null
    };
  },
  mounted() {
    // https://codepen.io/webdatarocks/pen/jvJKoY
    // console.log(this.$route.params.table);
    this.report = new WebDataRocks({
      container: this.$refs.wdrComponent,
      beforetoolbarcreated: customizeToolbar,
      toolbar: true,
      height: 580,
      width: "100%",
      report: {
        dataSource: {
          dataSourceType: "json",
          filename: "/api/economy/pivot/" + this.$route.params.table
        },
        slice: {
          reportFilters: [
            {
              uniqueName: "Dim1"
            }
          ],
          rows: [
            {
              uniqueName: "Cus"
            },
            {
              uniqueName: "Vono"
            }
          ],
          columns: [
            {
              uniqueName: "Acdt.Year"
            },
            {
              uniqueName: "Acdt.Month"
            },
            {
              uniqueName: "Measures"
            }
          ],
          measures: [
            {
              uniqueName: "Netam",
              aggregation: "sum"
            }
          ]
        },
        options: {
          grid: {
            type: "classic",
            showHeaders: false,
            showGrandTotals: "columns"
          }
        },
        conditions: [
          {
            formula: "#value < 0",
            format: {
              backgroundColor: "#FFFFFF",
              color: "#F44336",
              fontFamily: "Arial",
              fontSize: "12px"
            }
          }
        ],
        formats: [        
          {
            name: "",
            thousandsSeparator: ".",
            decimalSeparator: ",",
            decimalPlaces: 0,
            currencySymbol: "",
            currencySymbolAlign: "left",
            nullValue: "",
            textAlign: "right",
            isPercent: false
          }
        ]
      }
    });
  }
};

function customizeToolbar(toolbar) {
  var tabs = toolbar.getTabs(); // get all tabs from the toolbar
  toolbar.getTabs = function() {
    delete tabs[0]; // delete the first tab
    delete tabs[1]; // delete the first tab
    return tabs;
  };
}
</script>
