<template>
  <v-sheet>Hei 2
    <div>{{ projectid }}</div>
    <v-form v-model="valid">
      <v-container>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="poHdr.id" label="PO nr" readonly required></v-text-field>
            <v-text-field v-model="firstname" :rules="nameRules" :counter="10" label="Leverandør" required></v-text-field>
            <v-text-field v-model="firstname" :rules="nameRules" :counter="10" label="Vår referanse" required></v-text-field>
            <v-text-field v-model="firstname" :rules="nameRules" :counter="10" label="Leveringsbetingelser" required></v-text-field>
            <v-text-field v-model="firstname" :rules="nameRules" :counter="10" label="Leveringstidspunkt" required></v-text-field>
            <v-text-field v-model="firstname" :rules="nameRules" :counter="10" label="Bestilt av" required></v-text-field>
            <v-text-field v-model="firstname" :rules="nameRules" :counter="10" label="Godkjent av" required></v-text-field>
            {{ extractFieldValue('projectHeader', 'erp_projectid').value }}-{{ poid }}
          </v-col>

          <v-col cols="12" md="4">
            <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="date" label="Dato" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="date" @input="menu2 = false"></v-date-picker>
            </v-menu>
            <v-text-field v-model="lastname" :rules="nameRules" :counter="10" label="Deres referanse" required></v-text-field>
            <v-text-field v-model="firstname" :rules="nameRules" :counter="10" label="Leveringsadresse" required></v-text-field>

            <v-text-field v-model="lastname" :rules="nameRules" :counter="10" label="Last name" required></v-text-field>
            <v-text-field v-model="lastname" :rules="nameRules" :counter="10" label="Deres epost" required></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field v-model="firstname" :rules="nameRules" :counter="10" label="Prosjekt" required></v-text-field>
            <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
            <v-text-field v-model="firstname" :rules="nameRules" :counter="10" label="Fakturaadresse" required></v-text-field>
          </v-col>

        </v-row>
      </v-container>
    </v-form>
    <div>{{ projectHeader }}</div>
  </v-sheet>
</template>

<script>
import requestData from "@/mixins/requestData.js";

export default {
  mixins: [requestData],
  props: {
    projectHeader: Array,
    projectid: String,
    poid: String
  },
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu2: false,
    poHdr: {
      projectid: null,
      id: null,
    },
    valid: false,
    firstname: "",
    lastname: "",
    nameRules: [
      v => !!v || "Name is required",
      v => v.length <= 10 || "Name must be less than 10 characters"
    ],
    email: "",
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+/.test(v) || "E-mail must be valid"
    ]
  }),
  watch: {
    poid: function(val) {
        this.poHdr.id = this.extractFieldValue('projectHeader', 'erp_projectid').value + '-' + val;
      }
    }
};
</script>