export default {
   methods: {
     requestMedia: function (source, obj) {
      //console.log(source, obj)
      var path = '/api/' + source.split('/')[1] + '/page'
      const req = {
         ...{company: this.$store.state.activecompany},
         ...obj,
      };
      if (this.projectid !== null) {
         req.projectid = this.projectid;
      }
      // Add properties for equipment
      if (this.equipmentid !== null) {
         req.equipmentid = this.equipmentid;
      }

      var str = Object.keys(req).map(function (key) {
         return key + '=' + req[key]
      }).join('&');
      window.open(path + '?' + str, '_blank')
   }
   }
}