<template>
  <!-- Filters -->
  <v-sheet rounded class="pa-3">

    <v-row>
      <v-col>
        <h3 class="float-left font-weight-medium grey--text text--darken-1 pb-2">{{ title }}</h3>
        <div class="float-right">
          <span class="caption purple--text text--lighten-2" v-if="readat">Data lest {{new Date(readat*1000).toLocaleDateString("nb-NO")}} {{new Date(readat*1000).toLocaleTimeString("nb-NO")}}</span>
          <v-btn v-if="refresher" outlined small color="purple" @click="refresh()" class="ml-2">Oppdater<v-icon right>mdi-cloud-refresh</v-icon>
          </v-btn>
        </div>
        <!--<v-progress-linear :active="loading" :indeterminate="loading" bottom height="3" background-color="purple lighten-5" color="deep-purple accent-4" style="height: 3px" class="mb-2"></v-progress-linear>-->
      </v-col>
    </v-row>


      <dropdown-filters :data="items" v-on:dropdownFiltersChanged="newFiltersFromChild"></dropdown-filters>

      <grid-layout :layout.sync="layout" :col-num="12" :row-height="30" :is-draggable="gridIsDraggable" :is-resizable="gridIsResizable" :is-mirrored="false" :vertical-compact="true" :margin="[10, 10]" :use-css-transforms="false" :responsive="false" @layout-updated="layoutUpdatedEvent">

        <grid-item style v-for="item in layout" :x="item.x" :y="item.y" :w="item.w" :h="item.h" :i="item.i" :key="item.i" :autoSize="false">

          <div v-if="gridready">

          <apex-charts v-if="item.wtype === 'treemap'" type="treemap" height="300" :options="widgets[item.i].options" :series="widgets[item.i].data"></apex-charts>

          <apex-charts v-if="item.wtype === 'bar'" type="bar" height="300" :options="widgets[item.i].options" :series="widgets[item.i].data"></apex-charts>

          <graphic-kamishibai v-if="item.wtype === 'kamishibai'" :options="widgets[item.i].options" :data="widgets[item.i].data"></graphic-kamishibai>

          <timeline-reservation-chart v-if="item.wtype === 'timelinebar'" :options="timelineReservationOptions" :data="widgets[item.i].data" :initheight="height" :initwidth="width" />

          <graphic-datatable v-if="item.wtype === 'datatable'" :options="widgets[item.i].options" :data="filteredItems"></graphic-datatable><!-- data[item.i].data -->

          </div>

        </grid-item>

      </grid-layout>


  </v-sheet>
</template>

<script>
/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
import requestData from "@/mixins/requestData.js";
import VueGridLayout from "vue-grid-layout";
import GraphicDatatable from "@/components/GraphicDatatable.vue";
import DropdownFilters from "@/components/DropdownFilters.vue";
import ApexCharts from "vue-apexcharts";
// import nb from "apexcharts/dist/locales/nb.json";

export default {
  mixins: [requestData],
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    "graphic-datatable": GraphicDatatable,
    "apex-charts": ApexCharts,
    "dropdown-filters": DropdownFilters
  },
  created: function() {
    this.layout =
      JSON.parse(
        JSON.stringify(this.$store.getters.navbylink(this.$route.path).layout)
      ) || [];
  },
  mounted: function() {
    this.requestData();
  },
  computed: {
    filteredItems() {
      /* https://codepen.io/wolfpup/pen/gOadmPx */
      // data will narrow down other filters to selected, alternativ use items
      return this.items.filter(d => {
        return Object.keys(this.filters).every(f => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
    }
  },
  watch: {
    filteredItems() {
      this.sumbykey();
    }
  },
  methods: {
    newFiltersFromChild(obj) {
      this.filters = obj;
    },
    sumbykey() {

      for (const key in this.widgets) {

        // Treemap
        if (this.widgets[key].wtype== "treemap") {
          // Sum and group by segment
          const res = Array.from(
            this.filteredItems.reduce(
              (m, { segment, revenueam }) =>
                m.set(segment, (m.get(segment) || 0) + revenueam),
              new Map()
            ),
            ([x, y]) => ({ x, y })
          );
          this.widgets[key].data = [{ data: res }];
        }

        // Bar
      // Sum and group by endofmonth
        if (this.widgets[key].wtype== "bar") {
          const col = Array.from(
            this.filteredItems.reduce(
              (m, { eomonth, revenueam }) =>
                m.set(eomonth, (m.get(eomonth) || 0) + revenueam),
              new Map()
            ),
            ([x, y]) => ({ x, y })
          );
          this.widgets[key].data = [{ data: col }];
        }

      }      
    },
    layoutUpdatedEvent: function(newLayout) {
      console.log("New layout:", JSON.stringify(newLayout));
    },
    /*
    refresh() {
      this.requestData();
    },
    */
    requestData: function() {
      var self = this;
      const obj = {
        id: self.$route.params.page,
        company: this.$store.state.activecompany,
        media: "json",
        format: "datatable"
      };
      this.requestJSON2("/api/" + this.$route.name, obj)
        .then(function(e) {
          self.widgets = e;
          const resp = e[Object.keys(e)[0]];
          self.items = resp.data || [];

          for (const key in e) {
            if (e[key].wtype === 'treemap') {
              self.widgets[key].options = self.$func.ApexChartOptions("treemap")
              self.widgets[key].options.title.text = e[key].title
              self.widgets[key].data = []
            }
            if (e[key].wtype === 'bar') {
              self.widgets[key].options = self.$func.ApexChartOptions("bar")
              self.widgets[key].options.title.text = e[key].title
              self.widgets[key].data = []
            }
          }          
          /*
        self.options = resp.options || [];
        self.title = resp.title;
        if (typeof resp.parameters !== "undefined") {
          if (typeof resp.parameters["groupby"] !== "undefined") {
            self.groupby = resp.parameters["groupby"];
          }
          if (
            typeof resp.parameters["readat"] !== "undefined" &&
            resp.parameters["readat"] > 0
          ) {
            self.readat = resp.parameters["readat"];
          }
          if (
            typeof resp.parameters["refresh"] !== "undefined" &&
            resp.parameters["refresh"]
          ) {
            self.refresher = true;
          }
        }
        */
          /*
        self.options.forEach(function(item) {
          if (item.dropfilter !== "undefined" && item.dropfilter === true) {
            self.$set(self.filters, item.value, []);
          }
        });
        */
        })
        .then(function() {
          self.gridready = true;
        });
    }
  },
  data() {
    return {
      items: [],
      widgets: {},
      //options: [],
      //groupby: undefined,
      //search: "",
      loading: false,
      title: "",
      refresher: false,
      filters: {},
      gridready: false,
      readat: false,
      gridIsDraggable: false,
      gridIsResizable: false,
      layout: [],
    };
  }
};
</script>