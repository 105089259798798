<template>
  <v-main id="grad">
    <Notifier></Notifier>
    <router-view></router-view>
  </v-main>
</template>

<style scoped>
#grad {
  background-image:#e2d8d8;background-image:  
  radial-gradient(at 98% 76%, hsla(262,56%,77%,1) 0, transparent 50%),  
  radial-gradient(at 18% 87%, hsla(189,75%,85%,1) 0, transparent 50%),  
  radial-gradient(at 84% 26%, hsla(297,41%,75%,1) 0, transparent 62%),  
  radial-gradient(at 0% 0%, hsla(23,40%,76%,1) 0, transparent 50%);  
  height: 100%;
}
</style>

<script>
import Notifier from "@/components_global/Notifier.vue";

export default {
  components: { Notifier },
};
</script>