<template>
      <v-toolbar flat rounded dark color="blue darken-4" dense>
        <v-toolbar-title>Prosjekt</v-toolbar-title>
      <v-autocomplete
        class="mx-2"
        dense
        hide-no-data
        hide-details
        label="Velg prosjekt"
        solo-inverted
        :items="autocompleteitems"
        :filter="customFilter"
        color="black"
        v-model="projectid"
        style="height: 38px"
      ></v-autocomplete>
      <v-btn
        raised
        :disabled="disabled"
        @click="hentProsjekt"
        color="blue"
        class="mr-0"                
        >Hent<v-icon class="sm" right light>mdi-cursor-default-click</v-icon>
        </v-btn>
      </v-toolbar>
</template>

<script>
/*
USAGE:
<hent-prosjekt @emitproject="hentetProsjekt" @prechange="prechange"/>
import HentProsjekt from "@/components/HentProsjekt.vue";
components: {HentProsjekt}
methods: {
    prechange(e) {
      console.log("prechange", e)
      this.reset();
    },
    hentetProsjekt(e) {
      // Receive emitted list from component
      var self = this;
      this.reset();
      this.projectHeader = e;
      e.forEach(function (item) {
        if (item.field === "pid") {
          self.projectid = item.value;
        }
      });
      //this.getProjectWish();
      //this.projectToDropList();
    },
  }
  data() {
    return {
      projectHeader: [],
      projectid: null,
    };
  },

*/
import requestData from "@/mixins/requestData.js";

export default {
  mixins: [requestData],
  mounted: function () {
      var self = this;
      this.requestJSON2('/api/equipment/component', { id: "bqcbqsjipt3aimtdrtp0", scope: "keyval",})
      .then(function(e) { self.autocompleteitems = e[Object.keys(e)[0]] || []});      
  },
  watch: {
    projectid: function (val) {
      //console.log("watch", val)
      this.disabled = false
      this.$emit('prechange', val);
    },
  },  
  methods: {
    customFilter(item, queryText) {
      const textOne = item.text.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    hentProsjekt() {
      var self = this
      this.requestJSON2('/api/equipment/component',
        { id: "bqcbqsjipt3aimtdrtp0",
          scope: "project",
          projectid: this.projectid,
        },
      ).then(function(e) {
        // Emits data array
        var data = e[Object.keys(e)[0]]
        data.push({field: "pid", value: self.projectid})
        self.$emit('emitproject', data);
        self.disabled = true
      });  
    },
  },
  data() {
    return {
      autocompleteitems: [],
      projectid: null,
      disabled: true,
    };
  },
};
</script>
