<template>
  <div>
      <!-- Timeline canvas -->
      <v-row>
        <v-col cols="12">
          <v-card flat class="pa-2" width="700">

            <graphic-xydiagram
                :initheight="svgheight"
                :data="chartdata"            
                :options="chartoptions"     
            ></graphic-xydiagram>

          </v-card>
        </v-col>
      </v-row>
  </div>
</template>


<script>
import requestData from "@/mixins/requestData.js";
import graphicxydiagram from "@/components/GraphicXYDiagram.vue";

export default {
  mixins: [requestData],
  components: {
    "graphic-xydiagram": graphicxydiagram,
  },
  created: function () {
    this.svgheight = 600
    //this.svgheight = Math.max(document.documentElement.clientHeight-100 || 0, window.innerHeight-100 || 0)
    //this.svgheight = this.$refs["svgelement1"].clientHeight - does not work - ref is after render
  },
  mounted: function () {
    var data = {
      title: {text: "McKinsey Directional Growth Matrix"},
      ylabels: [{pos: "high", title: "Høy"}, {pos: "low", title: "Lav"}, {pos: "middle", title: "Hvor attraktivt er markedet"}],
      xlabels: [{pos: "low", title: "Høy"}, {pos: "high", title: "Lav"}, {pos: "middle", title: "Konkurransestyrke"}],
      rects: [ 
        {fill: "rgba(158,158,158, 0.5)", title: "Utnytte mens det varer", bx: 1, by: 1}, 
        {fill: "rgba(76, 175, 80, 0.5)", title: "Hold posisjonen", bx: 1, by: 2}, 
        {fill: "rgba(76, 175, 80, 0.5)", title: "Invester / vekst", bx: 1, by: 3}, 
        {fill: "rgba(255,152,0, 0.5)", title: "Verdt å holde på?", bx: 2, by: 1}, 
        {fill: "rgba(158,158,158, 0.5)", title: "Selektiv investering / holde inntjening", bx: 2, by: 2}, 
        {fill: "rgba(76, 175, 80, 0.5)", title: "Opprettholde eller forbedre", bx: 2, by: 3}, 
        {fill: "rgba(255,152,0, 0.5)", title: "Høste / selge", bx: 3, by: 1}, 
        {fill: "rgba(255,152,0, 0.5)", title: "Verdt å forbedre?", bx: 3, by: 2}, 
        {fill: "rgba(158,158,158, 0.5)", title: "Forbedre eller samarbeide", bx: 3, by: 3}, 
      ]
    }
    var options = {boxx: 3, boxy: 3, height: this.svgheight, pad: {l: 50, r: 10, t: 50, b: 50}}
    this.chartdata = data;
    this.chartoptions = options;
},
  data() {
    return {
      svgheight: 0,
      chartdata: {},
      chartoptions: {},
    };
  },
};
</script>