<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-btn outlined color="primary" dark v-bind="attrs" v-on="on">Velg {{ title }}</v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Søk" single-line hide-details></v-text-field>
      </v-card-text>
      <v-card-text>
        <v-data-table dense :headers="tableHeaders" :items="items" :items-per-page="-1" :search="search" hide-default-footer @click:row="clickRow"></v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import requestData from "@/mixins/requestData.js";

export default {
  props: {
    title: String,
    request: Object,
    tableHeaders: Array,
  },
  mixins: [requestData],
  mounted: function() {
    this.requestData();
  },
  methods: {
    reset: function() {
      this.search = ''
    },
    requestData: function() {
      var self = this;
      var url = '';
      var params = {}
      if ("componentid" in this.request) {params.id = this.request.componentid}
      if ("scope" in this.request) {params.scope = this.request.scope}
      if ("url" in this.request) {url = this.request.url}

      this.requestJSON2(url, params).then(function(e) {
        const resp = e[Object.keys(e)[0]];
        self.items = resp || [];
      });
    },
    clickRow(value) {
      this.$emit("emitselected", value);
      this.reset();
      this.dialog = false
    }
  },
  data() {
    return {
      dialog: false,
      search: "",
      items: [],
    };
  }
};
</script>
