
<template>
  <v-card flat>
    <v-card-title>
      {{ widget.title }}
      <v-spacer></v-spacer>
      <v-btn outlined color="deep-purple" small @click="addRow('project')">Legg til rad</v-btn>
    </v-card-title>
    <v-card-subtitle>{{ widget.subtitle }}</v-card-subtitle>
    <v-card-text>
      <VueTabulator ref="tabulator" v-model="widget.data" :options="options" @cell-edited="cellEdited" @cell-click="cellClick"/>
    </v-card-text>
  </v-card>
</template>

<script>
/*eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { TabulatorComponent } from "vue-tabulator";
import tabulatoredit from "@/mixins/tabulatorEdit.js";
import requestmedia from "@/mixins/requestMedia.js";

export default {
  components: {VueTabulator: TabulatorComponent,},
  mixins: [tabulatoredit, requestmedia],
  props: ["section", "wid", "projectid", "widget", "options"],
};
</script>
