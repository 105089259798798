<template>
    <!-- Data table -->
    <v-data-table dense :headers="options" :items="data" :items-per-page="-1" :search="search" hide-default-footer :group-by="groupby" show-group-by>
      <template v-slot:header="{ props }">
        <tr>
          <th style="border-bottom: double rgba(0, 0, 0, 0.12); border-left: 1px solid #EEEEEE" class="text-right" v-for="(header, index) in props.headers" :key="index">
              <!--{{ header }}-->
              <span v-if="header.math == 'sum'">
                <span style="height: 36px; color: blue" class="subtitle-2">{{ $formatNumber(summit(header.value)) }}</span>
              </span>
          </th>
        </tr>
      </template>

      <template v-slot:[`item.revenueam`]="{ item }">
        <span>{{$formatNumber(item.revenueam)}}</span>
      </template>
      <template v-slot:[`item.pam`]="{ item }">
        <span>{{$formatNumber(item.pam)}}</span>
      </template>
      <template v-slot:[`item.ram`]="{ item }">
        <span>{{$formatNumber(item.ram)}}</span>
      </template>

    </v-data-table>
</template>

<style lang="scss" scoped>
@import "@/assets/datatable.scss";
</style>


<script>
export default {
  props: {
    data: {
      type: Array,
    },
    options: {
      type: Array,
    },
    groupby: {
      type: String
    },
    search: {
      type: String
    }
  },
  methods: {
    summit(col) {      
      if (this.data.length === 0) {
        return 0;
      }
      var sum = 0
      this.data.forEach(element => {
        sum += element[col] == undefined ? 0 : element[col]
      });
      return sum
    },
  },
};
</script>