import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    snackbar: {
      content: '',
      color: '',      
    },
    // token: localStorage.getItem('user-token') || '',
    socket: {
      isConnected: false,
      message: '',
      reconnectError: false,
    },    
    loggedIn: false,
    navitems: [],
    paths: {},
    companies: {},
    userfullname: null,
    useremail: null,
    activecompany: null,
    activecompanyname: null,
    titles: {
      economy:  {page: "Økonomi", drawer: "Økonomi", drawersub: "Innsikt"},
      contract: {page: "Kontrakt", drawer: "Kontrakt", drawersub: "Innsikt"},
      bid:      {page: "Tilbud", drawer: "Tilbud", drawersub: "Innsikt"},
      project:  {page: "Prosjekt", drawer: "Prosjekt", drawersub: "Innsikt"},
      strategy: {page: "Strategi", drawer: "Strategi", drawersub: "Innsikt"},
      staging: {page: "Staging", drawer: "Staging", drawersub: "Innsikt"},
      equipment:{page: "Utstyr", drawer: "Utstyr", drawersub: "Innsikt"},
      personal:     {page: "Personlig", drawer: "Personlig", drawersub: ""},
      admin:    {page: "Admin", drawer: "Admin", drawersub: "Granularity"},
      info:    {page: "Info", drawer: "Info", drawersub: "Granularity"},
      measure:    {page: "Måling", drawer: "Måling", drawersub: ".."},
      production:    {page: "Produksjon", drawer: "Produksjon", drawersub: ".."},
      personell:    {page: "Personell", drawer: "Personell", drawersub: ".."},
      master:    {page: "Masterdata", drawer: "Masterdata", drawersub: ".."},
    },
    history: [
      { text: "Hittil i år", value: "ytd" },
      { text: "Hittil i år og fjorår", value: "ytd2yr" },
      { text: "Siste 3 år hittil", value: "ytd3yr" },
      { text: "Siste 5 år hittil", value: "ytd5yr"  },
      { text: "Siste 10 år hittil", value: "ytd10yr" },
      { text: "Alle år hittil", value: "ytdall"  },
      { text: "Siste 2 år", value: "2yr"  },
      { text: "Siste 5 år", value: "5yr"  },
      { text: "Siste 10 år", value: "10yr"  },
      { text: "Alle år", value: "yrall"  },
    ],    
    frequency: [
      { text: "Måned", value: "mo" },
      { text: "Termin", value: "tm" },
      { text: "Kvartal", value: "qr" },
      { text: "Tertial", value: "tt" },
      { text: "Halvår", value: "hy" },
      { text: "År", value: "yr" },
    ],
    navtreeSelected: null,
    active: {
      history: null,
      frequency: null,
      yrpr: null,  
    },
  },
  getters: {
    isAuthenticated: state => state.loggedIn,
    //authStatus: state => state.status,    
    navsection: (state) => (id) => { return state.navitems.filter(items => (items.section === id)) },
    navbyid: (state) => (id) => { return state.navitems.find(item => (item.id === id)) },
    navbylink: (state) => (link) => { return state.navitems.find((item) => item.link === link); },
    navroot: state => { return state.navitems },
    navtreeselected: state => { return state.navtreeSelected },
    isLoggedIn: state => { return state.loggedIn },
    companiesList: state => { 
      var x; var y = [];
      for (x in state.companies) {
        y.push({value: x, text: state.companies[x].name});
      }
      return y
    },
    pagetitle: (state) => (id) => { 
      //console.log(id)
      if (typeof state.titles[id.replace('/','')] !== 'undefined' && typeof state.titles[id.replace('/','')].page !== 'undefined') {
        return state.titles[id.replace('/','')].page
      } else {
        return "Title not set in store"
      }
    },
    pagedrawer: (state) => (id) => { 
      //console.log(id)
      //console.log(id.replace('/',''))
      //console.log(state.titles[id.replace('/','')])
      return state.titles[id.replace('/','')].drawer || "Not set"
    },
    pagedrawersub: (state) => (id) => { 
      return state.titles[id.replace('/','')].drawersub || "Not set"
    },
  },
  actions: {
    /*
    AUTH_REQUEST: ({commit, dispatch}, user) => {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        commit('AUTH_REQUEST')
        axios({url: '/auth/login', data: user, method: 'POST' })
          .then(resp => {
            const token = resp.data.token
            localStorage.setItem('user-token', token) // store the token in localstorage
            commit('AUTH_SUCCESS', token)
            // you have your token, now log in your user :)
            dispatch('USER_REQUEST')
            resolve(resp)
          })
        .catch(err => {
          commit('AUTH_ERROR', err)
          localStorage.removeItem('user-token') // if the request fails, remove any possible user token if possible
          reject(err)
        })
      })
    },
    AUTH_LOGOUT: ({commit}) => {
      return new Promise((resolve) => {
        commit('AUTH_LOGOUT')
        localStorage.removeItem('user-token') // clear your user's token from localstorage
        resolve("ok")
      })
    }    
    */
   SET_ACTIVECOMPANY2 (n, val) {
    return new Promise((resolve) => {        
        this.commit('SET_ACTIVECOMPANY', val)
        resolve(this.state.activecompany)
    })
  }
  },
  mutations: {
    showMessage (state, payload) {
      state.snackbar.content = payload.content
      state.snackbar.color = payload.color
    },
    /*
    AUTH_REQUEST: (state) => {
      state.status = 'loading'
    },
    AUTH_SUCCESS: (state, token) => {
      state.status = 'success'
      state.token = token
    },
    AUTH_ERROR: (state) => {
      state.status = 'error'
    },*/    
    AUTH_LOGOUT: (state) => {
      state.loggedIn = false
      document.cookie = 'incorp=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      localStorage.removeItem('vuex') 
    },    
    AUTH_SUCCESS: (state) => {
      state.loggedIn = true
      // state.token = token
    },
    SOCKET_ONOPEN (state, event)  {
      //console.log("SOCKET_ONOPEN", state, event)
      Vue.prototype.$socket = event.currentTarget
      state.socket.isConnected = true
    },
    SOCKET_ONCLOSE (state, event)  {
      console.log("SOCKET_ONCLOSE", event)
      state.socket.isConnected = false
    },
    SOCKET_ONERROR (state, event)  {
      console.error("SOCKET_ONERROR", state, event)
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE (state, message)  {
      //console.log("SOCKET_ONMESSAGE", message)
      state.socket.message = message
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
      console.info("SOCKET_RECONNECT", state, count)
    },
    SOCKET_RECONNECT_ERROR(state) {
      console.error("SOCKET_RECONNECTERROR", state)
      state.socket.reconnectError = true;
      //this.$router.push({ name: 'logout'});   
      router.push({ name: 'logout'})   
    },    
    SET_PAGECONTENT(state, n) { state.navitems = n },
    SET_PATHSCONTENT(state, n) { state.paths = n },
    SET_USER(state, n) { state.userfullname = n },
    SET_USEREMAIL(state, n) { state.useremail = n },
    SET_COMPANYCONTENT(state, n) { state.companies = n },
    SET_ACTIVECOMPANY(state, n) { 
      if (state.activecompany === n) {
        return
      }
      state.activecompany = n; 
      if (state.activecompany !== null && typeof(state.companies[n].name) !== 'undefined') {
        state.activecompanyname = state.companies[n].name
      }
    },
    SET_FREQUENCY(state, payload) { state.active.frequency = payload },
    SET_HISTORY(state, payload) { state.active.history = payload },
    SET_YRPR(state, payload) { state.active.yrpr = payload},
    SET_NAVTREESELECTED(state, payload) { state.navtreeSelected = payload},
    SET_LOGGEDIN(state) { state.loggedIn != state.loggedIn},
  },
})