<template>
      <v-container>
        Logout
      </v-container>
</template>

<script>
export default {
  mounted: function() {
      this.$disconnect()
      this.$store.commit('AUTH_LOGOUT')
      this.$router.replace({ name: 'public'});
  },
  data() {
    return {}
  }
};


</script>