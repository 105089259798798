<template>
  <v-container>
    <v-layout text-center wrap>
      <v-flex xs12>
        <!--
        <WebDataRocks
         report="https://cdn.webdatarocks.com/reports/report.json"
         toolbar>
        </WebDataRocks>
        -->

        <!--<Pivot report="https://cdn.webdatarocks.com/reports/report.json" :toolbar="toolbar" height="400"></Pivot>-->
        <Pivot :report="report" :toolbar="toolbar" height="400"></Pivot>

      </v-flex>

      <v-flex xs12>
        <div ref="wdrComponent"></div>
      </v-flex>

      <v-flex mb-4>
        <!--
        {{ $route.params.table }}
        <button id="btn" class v-on:click="getJokes">Get Jokes</button>
        -->
      </v-flex>
      {{ info }}
    </v-layout>
  </v-container>
</template>

<script>
// import WebDataRocks from "webdatarocks";
import { Pivot } from "vue-webdatarocks";
import "webdatarocks/webdatarocks.css";
import requestData from "@/mixins/requestData.js";

export default {
  mixins: [requestData],
  components: {
    //WebDataRocks: WebDataRocks,
    Pivot
  },
  created: function() {
    this.layout =
      JSON.parse(
        JSON.stringify(this.$store.getters.navbylink(this.$route.path).layout)
      ) || [];
  },
  methods: {
    requestData: function() {
      var self = this;
      const obj = {
        id: self.$route.params.page,
        company: this.$store.state.activecompany,
        //format: "datatable"
      };
      console.log(obj)
      this.requestJSON2("/api/" + this.$route.name, obj).then(function(e) {
        const resp = e[Object.keys(e)[0]];
        self.items = resp
        /*
        //Convert to date
        var arrayLength = resp.data.length;
        for (var i = 0; i < arrayLength; i++) {
          for (const key in resp.data[i]) {
              if (key.toLowerCase().endsWith('date')) {
                resp.data[i][key] = new Date(Date.parse(resp.data[i][key])).toLocaleString("no-NB", { day: "numeric", month: "short", year: "numeric" })
              }
          }          
        }
        */

       /*
        self.title = resp.title;
        self.items = resp.data || [];
        self.options = resp.options || [];
        if (typeof resp.parameters !== "undefined") {
          if (typeof resp.parameters["groupby"] !== "undefined") {
            self.groupby = resp.parameters["groupby"];
          }
          if (
            typeof resp.parameters["readat"] !== "undefined" &&
            resp.parameters["readat"] > 0
          ) {
            self.readat = resp.parameters["readat"];
          }
          if (
            typeof resp.parameters["refresh"] !== "undefined" &&
            resp.parameters["refresh"]
          ) {
            self.refresher = true;
          }
          if (
            typeof resp.parameters["excel"] !== "undefined" &&
            resp.parameters["excel"]
          ) {
            self.excel = resp.parameters["excel"];
          }
        }
        self.options.forEach(function(item) {
          if (item.dropfilter !== "undefined" && item.dropfilter === true) {
            self.$set(self.filters, item.value, []);
          }
        });
        self.gridready = true;
        */
      });
    }    
    /*
    customizeToolbar: function (toolbar) {
        var tabs = toolbar.getTabs(); // get all tabs from the toolbar
        toolbar.getTabs = function() {
            delete tabs[0]; // delete the first tab
            return tabs;
        }
    }, */
    /*
    getJokes: function() {
      this.loading = true;
      this.axios.get("/api/economy/pivot/cus").then(
        response => {
          this.loading = false;
          this.info = response;
        } , (error)  =>  {
        this.loading = false;
      }
      );
    }*/
  },
  data() {
    return {
      //report: null,
      info: null,
      toolbar: false,
      items: [],
      report: {
        dataSource: {
          data: this.items,
          /*
          dataSourceType: "csv",
          filename: "https://cdn.webdatarocks.com/data/data.csv"
          */
        },
        slice: {
          rows: [
            {
              uniqueName: "Country"
            },
            {
              uniqueName: "Category"
            }
          ],
          columns: [
            {
              uniqueName: "agedist"
            },
            {
              uniqueName: "Measures"
            }
          ],
          measures: [
            {
              uniqueName: "Price",
              aggregation: "sum"
            }
          ]
        },
        conditions: [
          {
            formula: "#value > 2000000",
            measure: "Price",
            format: {
              backgroundColor: "#0598df",
              color: "#FFFFFF",
              fontFamily: "Arial",
              fontSize: "12px"
            }
          }
        ]
      }
    };
  },
  mounted() {
    console.log(this.$route.params)
    this.requestData()
    // https://codepen.io/webdatarocks/pen/jvJKoY
    // console.log(this.$route.params.table);
    /*
    this.report = new WebDataRocks({
      container: this.$refs.wdrComponent,
      beforetoolbarcreated: customizeToolbar,
      toolbar: true,
      height: 580,
      width: "100%",
      report: {
        dataSource: {
          dataSourceType: "json",
          filename: "/api/economy/pivot/" + this.$route.params.table
        },
        slice: {
          reportFilters: [
            {
              uniqueName: "Dim1"
            }
          ],
          rows: [
            {
              uniqueName: "Cus"
            },
            {
              uniqueName: "Vono"
            }
          ],
          columns: [
            {
              uniqueName: "Acdt.Year"
            },
            {
              uniqueName: "Acdt.Month"
            },
            {
              uniqueName: "Measures"
            }
          ],
          measures: [
            {
              uniqueName: "Netam",
              aggregation: "sum"
            }
          ]
        },
        options: {
          grid: {
            type: "classic",
            showHeaders: false,
            showGrandTotals: "columns"
          }
        },
        conditions: [
          {
            formula: "#value < 0",
            format: {
              backgroundColor: "#FFFFFF",
              color: "#F44336",
              fontFamily: "Arial",
              fontSize: "12px"
            }
          }
        ],
        formats: [        
          {
            name: "",
            thousandsSeparator: ".",
            decimalSeparator: ",",
            decimalPlaces: 0,
            currencySymbol: "",
            currencySymbolAlign: "left",
            nullValue: "",
            textAlign: "right",
            isPercent: false
          }
        ]
      }
    });*/
  }
};

/*
function customizeToolbar(toolbar) {
  var tabs = toolbar.getTabs(); // get all tabs from the toolbar
  toolbar.getTabs = function() {
    delete tabs[0]; // delete the first tab
    delete tabs[1]; // delete the first tab
    return tabs;
  };
}
*/
</script>
