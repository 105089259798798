<template>

  <v-row no-gutters>

    <!-- Venstre meny kolonne -->
    <v-col v-show="showleft" cols="2">
      <v-list style="background: transparent" dense shaped>
        <v-list-item-group v-model="menuitem" color="blue darken-1">
          <v-list-item v-for="(item, i) in navitems" :key="i" @click="getsection(item.link)">
            <v-list-item-icon>
              <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
              <v-icon v-else>mdi-chevron-right</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-col>

    <!-- Høyre handling kolonne -->
    <v-col cols="10">

      <v-row no-gutters>
        <v-col>
          <hent-prosjekt @emitproject="hentetProsjekt" @prechange="prechange" />
        </v-col>
      </v-row>

      <!-- Oversikt -->
      <v-sheet v-if="section=='#overview'" class="pa-2" style="background: rgba(255,255,255,0.5)">
        overview
      </v-sheet>

      <!-- Bestilling -->
      <v-sheet v-if="section=='#neworder'" rounded class="pa-2" style="background: rgba(255,255,255,0.5)">
        <v-btn v-if="projectid" class="ma-2" outlined color="indigo" @click="createOrder('pvu')">Opprett PVU bestilling</v-btn>
        <div v-if="orderPVU">
          <RequisitionPPE :poid="orderPVU" :projectHeader="projectHeader" :projectid="projectid" />
        </div>
      </v-sheet>

      <v-sheet v-if="section=='#underorder'" class="pa-2" style="background: rgba(255,255,255,0.5)">
        underorder
      </v-sheet>

      <!-- Rekvisisjon -->
      <v-sheet v-if="section=='#purchaseorder'" rounded class="pa-2" style="background: rgba(255,255,255,0.5)">
        <v-btn v-if="projectid" class="ma-2" outlined color="indigo" @click="createOrder('po')">Opprett PO bestilling</v-btn>
        <div v-if="orderPO">
          <RequisitionPO :poid="orderPO" :projectHeader="projectHeader" :projectid="projectid" />
        </div>
      </v-sheet>

      <v-sheet v-if="section=='#history'" class="pa-2" style="background: rgba(255,255,255,0.5)">Hist</v-sheet>

    </v-col>
  </v-row>

</template>


<script>
/*eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import requestData from "@/mixins/requestData.js";
import HentProsjekt from "@/components/HentProsjekt.vue";
import RequisitionPO from "@/views/project/RequisitionPO.vue";
import RequisitionPPE from "@/views/project/RequisitionPPE.vue";

export default {
  mixins: [requestData],
  components: {
    HentProsjekt,
    RequisitionPO,
    RequisitionPPE
  },

  mounted: function() {
  },
  data() {
    return {
      section: false,
      showleft: true,
      navitems: [
        { icon: "mdi-eye-outline", link: "#overview", title: "Oversikt" },
        {
          icon: "mdi-account-hard-hat",
          link: "#neworder",
          title: "Bestilling PVU"
        },
        {
          icon: "mdi-package-variant",
          link: "#newpacklist",
          title: "Bestilling pakkliste"
        },
        {
          icon: "mdi-package-variant",
          link: "#suggestion",
          title: "Best.forslag kalkyle"
        },
        {
          icon: "mdi-package-variant",
          link: "#suggestion",
          title: "Best. maskin"
        },
        { icon: "mdi-pencil", link: "#purchaseorder", title: "Rekvisisjon" },
        {
          icon: "mdi-cube-send",
          link: "#underorder",
          title: "Under bestilling"
        },
        { icon: "mdi-archive-outline", link: "#history", title: "Historikk" }
      ],
      menuitem: null,

      projectHeader: [],
      projectid: null,

      orderPVU: undefined,
      orderPO: undefined
    };
  },
  methods: {
    createOrder(what) {
      var self = this;
      this.$newPOID().then(function(res) {
        if (what === "pvu") {
          self.orderPVU = res.poid;
        }
        if (what === "po") {
          self.orderPO = res.poid;
        }
      });
    },
    getsection(s) {
      this.section = s;
    },
    reset() {
      console.log("Must implement reset!");
    },
    prechange(e) {
      console.log("prechange", e);
      this.reset();
    },
    hentetProsjekt(e) {
      // Receive emitted list from component
      var self = this;
      this.reset();
      this.projectHeader = e;
      e.forEach(function(item) {
        if (item.field === "pid") {
          self.projectid = item.value;
        }
      });
    }
  }
};
</script>