import axios from 'axios';
import router from '@/router'

//import store from 'your/store/path/store'

export default function setup() {
   axios.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
   }, function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      //console.log(error.response)

      // Handle status Bad request
      if ((error.response.status === 400) && error.response.config.url !== "/auth/login") {
         if (error.response.data.length > 0) {
            alert(error.response.data)
         }
      }

      // Handle status Unauthorized or Forbidden
      if ((error.response.status === 401 || error.response.status === 403) && error.response.config.url !== "/auth/login") {
         //store.dispatch('logout')
         router.push({
            name: 'logout'
         })
      }

      return Promise.reject(error)
   })
}