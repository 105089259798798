<template>
  <v-main id="image">

    <!-- TOP APP BAR -->
    <v-app-bar style="backdrop-filter: blur(4px); background: linear-gradient(to bottom,rgba(255, 255, 255, 0.4),rgba(255, 255, 255, 0.1))" dense flat>
      <v-icon color="grey darken-3">mdi-flare</v-icon>
      <v-toolbar-title class="pl-1 content grey--text text--darken-4 font-weight-bold">
        InSee
        <span style="vertical-align: super; font-size: 12px; padding-left: 8px">BETA</span>
      </v-toolbar-title>
      <div class="flex-grow-1"></div>
      <v-col cols="2" sm="2">
        <v-select class="mt-6" dense item-color="green" :value="$store.state.activecompany" @input="SET_COMPANY" :items="$store.getters.companiesList"></v-select>
      </v-col>
      <v-btn @click="logout" icon>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- CONTENT -->
    <v-container>
      <v-layout text-center wrap class="mt-10">
        <div v-for="(item, index) in navitems" v-bind:key="index">
          <navcard :headline="item.title" :subtitle="item.subtitle" :to="item.link" :image="item.image" :icon="item.icon" :index="index" :color="item.color" />
        </div>
      </v-layout>
    </v-container>

  </v-main>

</template>

<style scoped>
#image {
  background-image: url("/559aea234d8673c67b3d12a617c942f0400a6015.jpg");
  background-size: cover;
  background-color: #cccccc;
  height: 100%;
}
</style>

<script>
import navcard from "@/components/NavCardIndex.vue";

export default {
  components: {
    navcard
  },
  methods: {
    logout: function() {
      this.$router.replace({ name: "logout" });
    },
    SET_COMPANY(val) {
      this.$store.dispatch("SET_ACTIVECOMPANY2", val).then(resolve => {
        if (resolve == val) {
          this.$router.push({ name: "cache" });
        }
      });
    }
  },
  computed: {
    navitems() {
      return this.$store.getters.navsection("/");
    }
  }
};
</script>
