<template>
  <div>
    <v-row>
      <v-col cols="3" v-for="(l, index) in dropLists" :key="index">
        <drag-drop-container :listid="l.xid" :title="l.title" :items="dynList(l.xid)" @childdrag="childDrag" @childdrop="childDrop"></drag-drop-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/*eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
import DragDropContainer from "@/components/DropContainer.vue";

export default {
  components: {
    DragDropContainer
  },
  computed: {
    dynList() {
      return function(listid) {
        return this.displayedItems.filter(function(item) {
          return item.list == listid;
        });
      };
    }
  },
  methods: {
    childDrag: (evt, item) => {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemID", item.xid);
    },
    childDrop(evt, list) {
      const itemID = evt.dataTransfer.getData("itemID");
      const item = this.displayedItems.find(item => item.xid == itemID);
      if (item === undefined) {
        this.$showMessage({
          content: "Velg korrekt utstyrskategori",
          color: "info"
        });
        return;
      }
      item.list = list;
      //console.log("Item", item.xid, item.title, "now belongs to list", list);
    }
  },
  data: () => ({
    dropLists: [
      { xid: "1", title: "To do" },
      { xid: "2", title: "Doing" },
      { xid: "3", title: "Done" }
    ],
    displayedItems: [
      { xid: "A", title: "Hei", list: "1" },
      { xid: "B", title: "Du", list: "2" },
      { xid: "C", title: "Der", list: "1" }
    ]
  })
};
</script>