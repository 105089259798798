<template>
  <v-container fluid>
    <v-card class="mx-auto" style="background: rgba(255, 255, 255, 0.4)">
      
      <!--
      <v-app-bar dark flat color="deep-purple lighten-1" dense>
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
        <v-toolbar-title>To do</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon><v-icon>mdi-magnify</v-icon></v-btn>
      </v-app-bar>
      -->

    <v-system-bar
      window
      color="red lighten-1"
      dark
    >
      <v-btn color="" :to="{ name: 'home' }" icon>
        <v-icon>mdi-home</v-icon>
      </v-btn>      
      <span><strong class="pr-3">TODO</strong> {{ this.$store.state.userfullname }} / {{ this.$store.state.activecompanyname }}</span>
      <v-spacer></v-spacer>
      <FullscreenButtons/>
    </v-system-bar>

      <v-row no-gutters>

        <!-- KATEGORIER -->
        <v-col cols="3" style="border-right: 1px #E0E0E0 solid;">
          <v-list style="background: transparent" dense>
            <v-list-item-group v-model="selectedCategory" color="primary">

              <!-- Fixed categories -->
              <v-list-item @click="getCategory('Alt')">
                <v-list-item-icon class="mr-4"><v-icon color="blue lighten-2">mdi-tray-full</v-icon></v-list-item-icon>
                <v-list-item-content><v-list-item-title>Alt</v-list-item-title></v-list-item-content>
                <v-list-item-action><v-list-item-action-text>{{ remainingTasks }}</v-list-item-action-text></v-list-item-action>
              </v-list-item>

              <v-list-item @click="getCategory('Viktig')">
                <v-list-item-icon class="mr-4"><v-icon color="yellow darken-3">mdi-star-outline</v-icon></v-list-item-icon>
                <v-list-item-content><v-list-item-title>Viktig</v-list-item-title></v-list-item-content>
                <v-list-item-action><v-list-item-action-text>{{ importantTasks }}</v-list-item-action-text></v-list-item-action>
              </v-list-item>

              <v-list-item @click="getCategory('I dag')">
                <v-list-item-icon class="mr-4"><v-icon color="green lighten-2">mdi-calendar-today</v-icon></v-list-item-icon>
                <v-list-item-content><v-list-item-title>I dag</v-list-item-title></v-list-item-content>
                <v-list-item-action><v-list-item-action-text>{{ todayTasks }}</v-list-item-action-text></v-list-item-action>
              </v-list-item>

              <v-list-item @click="getCategory('Neste 7 dager')">
                <v-list-item-icon class="mr-4"><v-icon color="green lighten-2">mdi-calendar-week</v-icon></v-list-item-icon>
                <v-list-item-content><v-list-item-title>Neste 7 dager</v-list-item-title></v-list-item-content>
                <v-list-item-action><v-list-item-action-text>{{ day7Tasks }}</v-list-item-action-text></v-list-item-action>
              </v-list-item>

              <!-- Custom categories -->
              <v-list-group :value="true" sub-group>

                <template v-slot:activator>
                  <v-list-item-title class="grey--text">Kategorier</v-list-item-title>
                </template>

                <v-list-item v-for="(item, i) in categories" :key="i" @click="getCategory(item)">
                  <v-list-item-icon><v-icon color="purple lighten-2" small>mdi-circle-outline</v-icon></v-list-item-icon>
                  <v-list-item-content><v-list-item-title v-text="item"></v-list-item-title></v-list-item-content>
                  <v-list-item-action><v-list-item-action-text v-text="categoryTasks(item)"></v-list-item-action-text></v-list-item-action>
                </v-list-item>

                <v-divider class="mx-4"></v-divider>

                <v-list-item>
                  <v-list-item-icon><v-icon>mdi-plus</v-icon></v-list-item-icon>
                  <v-list-item-content><v-list-item-title class="grey--text">Legg til kategori</v-list-item-title></v-list-item-content>
                </v-list-item>

              </v-list-group>

              <v-divider class="mx-4"></v-divider>

              <v-list-item>
                <v-list-item-content>
                  <v-checkbox disabled color="grey" class="px-2 py-0" v-model="showCompleted" label="Vis fullførte"></v-checkbox>
                </v-list-item-content>
              </v-list-item>

            </v-list-item-group>
          </v-list>
        </v-col>

        <!-- OPPGAVER -->
        <v-col cols="5" style="border-right: 1px #E0E0E0 solid;">
          <v-sheet class="pa-3" style="background: rgba(255, 255, 255, 0.6); height: 100vh">
          <h3 class="mb-2">
            {{ Category }}
            <v-icon class="float-right">mdi-dots-horizontal</v-icon>
          </h3>

          <v-text-field
            v-model="newTask"
            label="Hva vil du gjøre?"
            solo
            @keydown.enter="taskCreate($event)"
            dense
            hide-details
          >
          </v-text-field>

    <v-divider class="my-4"></v-divider>

    <v-card v-if="tasks.length > 0">
      <template v-for="(task, i) in tasks">
        <template v-if="!task.Hide">
          <v-divider v-if="i !== 0" :key="`${i}-divider`"></v-divider>
          <v-list-item :key="i"  @click="taskSelect(i)" :ripple="false">

            <v-list-item-avatar class="my-0">
              <v-checkbox @click="taskDone(i)" v-model="task.Done" :color="task.Done && 'grey' || 'primary'" ></v-checkbox>
            </v-list-item-avatar>    

            <v-list-item-content class="py-0">
              <v-text-field solo-inverted ref="inpBox" :readonly="task.Readonly" @click:append="taskOpenEdit(i, $event)" @blur="taskCloseEdit(i)" @keydown.enter="taskCloseEdit(i)" hide-details :disabled="task.Done" v-model="task.Text" append-icon="mdi-pencil" dense @change="taskTextChange(i)"></v-text-field>          
            </v-list-item-content>

            <v-list-item-action class="my-0">
              <v-btn icon :disabled="task.Done" @click="taskImportant(i)" >
                <v-icon v-if="!task.Important" color="grey lighten-1">mdi-star-outline</v-icon>
                <v-icon v-else color="yellow darken-3">mdi-star</v-icon>
              </v-btn>
            </v-list-item-action>

          </v-list-item>
        </template>
      </template>
    </v-card>

          </v-sheet>
        </v-col>

        <!-- DETALJER -->
        <v-col cols="4">
          <v-sheet class="pa-3" style="background: rgba(255, 255, 255, 0.2); height: 100vh">
            <h3 class="d-block mb-2 red--text">{{task.Text}}.</h3>
            <v-row>
            <v-col>
              <!-- CATEGORY SELECT -->
              <v-select :disabled="task.Done" :items="categories" v-model="task.Category" label="Kategori"></v-select>            
            </v-col>
            <v-col>
            <!-- DUE DATE -->
            <v-menu
              v-model="openDatePicker"
              :close-on-content-click="false"
              :nudge-left="80"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="task.Duedate"
                  label="Forfallsdato"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :disabled="task.Done"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="task.Duedate"
                no-title
                @input="openDatePicker = false"
              ></v-date-picker>
            </v-menu>            
            </v-col>
            </v-row>
            <!-- NOTES -->
            <v-textarea :disabled="task.Done" outlined name="input-7-4" label="Notater" v-model="task.notes" ></v-textarea>            
            <!--
            <v-text-field class="d-none" v-model="task.assignedto" label="Tilordne"></v-text-field>
            <v-combobox class="d-none" v-model="task.tags" multiple label="Tags"></v-combobox>
            -->
            <h5>Opprettet den {{task.Createdate}}</h5>
            <h5>Utført den {{task.Donedate}}</h5>
          </v-sheet>
        </v-col>

      </v-row>
    </v-card>
  </v-container>
</template>

<script>

import requestData from "@/mixins/requestData.js";

export default {
  mixins: [requestData],
  mounted: function() {
    this.categories = [...new Set(this.tasks.map(x => x.Category))].sort()
    this.getCategory("Alt")  
    this.$options.sockets.onmessage = function(msg) {
      //console.log("received in component: ", msg.data)
      var obj = JSON.parse(msg.data)
      if (obj.status !== "ok") {
        this.$showMessage({ content: obj.status, color: "error"})
      }
    }
  },
  computed: {
    /*
    sortedTasks: function() {
        function surnameName(a, b) {
           if (a.Done < b.Done) return -1;
           if (a.Done > b.Done) return 1;
           if (a.Important > b.Important) return -1;
           if (a.Important < b.Important) return 1;
           return 0;
        }
        // return this.users.sort(surnameName); // sorts in-place
        return [...this.tasks].sort(surnameName); // shallow clone + sort
    },
    */
    importantTasks() {
      return this.tasks.filter((task) => (task.Important && task.Done == false)).length;
    },
    todayTasks() {
      var today = new Date();
      return this.tasks.filter((task) => (today.toLocaleDateString("ro-RO") == task.Duedate)).length;
    },
    day7Tasks() {
      var today = new Date();
      return this.tasks.filter((task) => (today.toLocaleDateString("ro-RO") == task.Duedate)).length;
    },
    completedTasks() {
      return this.tasks.filter((task) => task.Done).length;
    },
    progress() {
      return (this.completedTasks / this.tasks.length) * 100;
    },
    remainingTasks() {
      return this.tasks.length - this.completedTasks;
    },
    categoryTasks() {
      return function (salut){
        //return `${salut}`;
        var length = this.tasks.filter(function(item){
          return (item.Category == salut && item.Done !== true);
        }).length;
        return length;
      }
    },    
  },
  beforeDestroy: function() {
    delete this.$options.sockets.onmessage
  },
  watch: {
    // whenever question changes, this function will run
    /*
    task: function (ny, gm) {
      deep: true,
      console.log(ny)
      console.log(gm)
    },
    */
  },
  methods: {
    /*
    sortDefault: function() {
      console.log("yo")
        function surnameName(a, b) {
           if (a.Done < b.Done) return -1;
           if (a.Done > b.Done) return 1;
           if (a.Important > b.Important) return -1;
           if (a.Important < b.Important) return 1;
           return 0;
        }
        // return this.users.sort(surnameName); // sorts in-place
        return [...this.tasks].sort(surnameName); // shallow clone + sort
    },
    */
    sendChange(a) {
      var data = Object.assign({tbl: "bvu7pv6vvhfs1sc5c850", company: this.$store.state.activecompany }, a) // url: this.$route.path
      // Send message
      if (this.$store.state.socket.isConnected) {
        this.$socket.sendObj(data)
      } else {
        this.$showMessage({ content: "Websocket connection error", color: "error"})
      }
    },   
    taskCreate(event) {
        var self = this;
         this.axios
            .get('/api/todo/create', {params: {Text: this.newTask, company: this.$store.state.activecompany}})
            .then(function (response) {
              self.task = {}
              self.tasks.unshift(Object.assign(response.data, {Hide: false, Readonly: true}));
              self.newTask = null;
              event.target.blur();
            })
            .catch(function (error) {
               console.log(error.response);
            })
            .finally(function () {
               // always executed
            });
    },
    taskSelect(i) {
      this.task = this.tasks[i]
    },
    taskTextChange(i) {
      console.log("Changed", this.tasks[i].Text)
    },
    taskDone(i) {
      if (this.tasks[i].Done) {
        this.tasks[i].Donedate = new Date().toLocaleDateString(("ro-RO"))
      } else {
        this.tasks[i].Donedate = null
      }
      this.sendChange({row: this.tasks[i].XID, col: "Done", val: this.tasks[i].Done})
    },
    taskImportant(i) {
      this.tasks[i].Important = !this.tasks[i].Important
      this.sendChange({row: this.tasks[i].XID, col: "Important", val: this.tasks[i].Important})
    },
    taskCloseEdit(i) {
      this.tasks[i].Readonly = true
    },
    taskOpenEdit(i, event) {
      //console.log(i)
      //console.log(this.tasks[i])
      //this.$refs.readonly = false
      //this.tasks[i].Text = "Heu"
      if (this.tasks[i].Readonly == false) {
        this.tasks[i].Readonly = true
        event.target.blur();
        return
      }
      this.taskSelect(i)
      this.tasks[i].Readonly = false
      this.$refs.inpBox[i].focus()
    },
    getCategory(s) {      
      this.Category = s
      this.task = {}
      switch(s) {
        case "Viktig":
          for (let key in this.tasks) {
            this.tasks[key].Hide = this.tasks[key].Important ? false : true
          }
          break;
        case "I dag":
          var today = new Date();
          for (let key in this.tasks) {
            this.tasks[key].Hide = today.toLocaleDateString("ro-RO") == this.tasks[key].Duedate ? false : true
          }
          break;
        case "Alt":
          for (let key in this.tasks) {
            this.tasks[key].Hide = false
          }
          break;
        default:
          for (let key in this.tasks) {
            this.tasks[key].Hide = this.tasks[key].Category == s ? false : true
          }      
        }      
    },
  },
  data: () => ({
    openDatePicker: false,
    //ws: null,
    tasks: [
      {
        Done: true,
        Text: "Foobar Regnskap",
        Category: "Regnskap",
        Important: false,
        Hide: false,
        Readonly: true,
      },
      {
        Done: false,
        Text: "Foobar 2 Power BI",
        Category: "Power BI",
        Important: true,
        Hide: false,
        Readonly: true,
      },
      {
        Done: false,
        Text: "Foobar 3 .. mangler",
        Category: "...mangler",
        Important: true,
        Hide: false,
        Readonly: true,
      },
      {
        Done: false,
        Text: "Fizzbuzz Regnskap",
        Category: "Regnskap",
        Notes: "The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through.",
        Duedate: "09.01.2021",
        Donedate: "02.01.2021",
        Createdate: "01.01.2021",
        Important: false,
        Hide: false,
        Readonly: true,
      },
    ],
    newTask: null,
    showCompleted: false,
    selectedCategory: null,
    Category: '',
    task: {},
    categories: [],
  }),
};
</script>
