<template>
  <v-container fluid ref="containerelement">
      <v-btn text icon color="blue-grey lighten-2" @click="disableGrid">
        <span v-if="gridPreview"><v-icon>mdi-monitor-edit</v-icon></span>
        <span v-else><v-icon>mdi-monitor-lock</v-icon></span>
      </v-btn>

    <div v-if="gridready">

      <grid-layout
        :layout.sync="layout"
        :col-num="12"
        :row-height="30"
        :is-draggable="gridIsDraggable"
        :is-resizable="gridIsResizable"
        :is-mirrored="false"
        :vertical-compact="true"
        :margin="[10, 10]"
        :use-css-transforms="false"
        :responsive="false"
        @layout-updated="layoutUpdatedEvent"
      >

        <grid-item
          style
          v-for="item in layout"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :key="item.i"
          :autoSize="true"
        >

        <graphic-kamishibai
          v-if="item.wtype === 'kamishibai'"
          :options="data[item.i].options"
          :data="data[item.i].data"
        ></graphic-kamishibai>

        <timeline-reservation-chart
          v-if="item.wtype === 'timelinebar'"
          :initheight="height"
          :initwidth="width"
          :options="timelineReservationOptions"
          :data="data[item.i].data"
        />
        </grid-item>

      </grid-layout>


    </div>
  </v-container>
</template>

<script>
import requestData from "@/mixins/requestData.js";
import VueGridLayout from "vue-grid-layout";

import GraphicKamishibai from "@/components/GraphicKamishibai.vue";
import TimelineReservationChart from "@/components/GraphicTimelineReservation2.vue";

export default {
  mixins: [requestData],
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    "graphic-kamishibai": GraphicKamishibai,
    "timeline-reservation-chart": TimelineReservationChart,
  },
  created: function () {
    this.layout =
      JSON.parse(
        JSON.stringify(this.$store.getters.navbylink(this.$route.path).layout)
      ) || [];
  },  
  mounted: function () {
    this.requestData();
    //this.width = this.$refs.containerelement.clientWidth - 20;
    this.height = window.innerHeight - 50;
    this.timelineReservationOptions = {
        sorter: "startdate",
        chart: {
          xOffset: 180,
        },
        plot: {
          reservation: {
            rectangleHeight: 21,
            rectangleGap: 7,
          },
        },
        font: "15px Roboto",
      };
  },
  methods: {
    layoutUpdatedEvent: function (newLayout) {
      console.log("New layout:", JSON.stringify(newLayout));
    },
    disableGrid() { 
      this.gridIsDraggable = !this.gridIsDraggable;
      this.gridIsResizable = !this.gridIsResizable;
      this.gridPreview = !this.gridPreview;
      //this.contenteditable = !this.contenteditable
    },    
    requestData: function () {
      var self = this;
      const req = {
        id: self.$route.params.page,
        company: this.$store.state.activecompany,
        media: "json",
        format: "datatable",
      };
      this.requestJSON2("/api/" + this.$route.name, req)
        .then(function (e) {
          self.data = e;
        })
        .then(function () {
          self.gridready = true;
        });
    },
  },
  data() {
    return {
      gridIsDraggable: false,
      gridIsResizable: false,
      gridPreview: true,
      gridready: false,

      width: 1000,
      height: 500,
      layout: [],
      data: {},
      timelineReservationOptions: {},
      model: 0,
    colors: [
      "purple lighten-4",
      "blue lighten-4",
      "pink lighten-4",
      "indigo lighten-4",
      "teal lighten-4",
    ],
    };
  },
};
</script>