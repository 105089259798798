<template>
  <div>
    <vue-apex-charts width="500" type="line" :options="options" :series="series"></vue-apex-charts>
    <vue-apex-charts width="500" type="bar" :options="options" :series="series"></vue-apex-charts>
    <vue-apex-charts width="500" type="area" :options="options" :series="series"></vue-apex-charts>
    <vue-apex-charts width="500" type="radar" :options="options" :series="series"></vue-apex-charts>
  </div>
</template>

<script>
/*eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    "vue-apex-charts": VueApexCharts
  },
  data: () => ({
    options: {
      chart: {
        id: "vuechart-example"
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
      },
      title: {
        text: "Product Trends by Month",
        align: "left"
      },
      grid: {
        row: {
          colors: ["#ffffff", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.8
        }
      }
    },
    series: [
      {
        name: "Desktop",
        data: [30, 40, 45, 50, 49, 60, 70, 91]
      }
    ]
  }),
};
</script>