import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import interceptorsSetup from './plugins/interceptors'
import "./plugins/echarts";
import store from './store'
import VueNativeSock from 'vue-native-websocket'

// Layouts
import DefaultLayout from "@/layouts/Default";
import TransparentLayout from "@/layouts/Transparent";
import PublicLayout from "@/layouts/Public";
import IndexLayout from "@/layouts/Index";

// Code blocks
import CompanyUser from '@/components_global/CompanyUserInfo.vue'
import FullscreenButtons from '@/components_global/FullscreenButtons.vue'
import FullscreenButtonsAbsolute from '@/components_global/FullscreenButtonsAbsolute.vue'
import ToolbarLeftNavTitle from '@/components_global/ToolbarLeftNavTitle.vue'

// Charts
// import './plugins/apexcharts'

import {func} from './func.js'


Vue.use(VueRouter)
Vue.use(VueAxios, axios)

// Layouts - make public to app
Vue.component('default-layout', DefaultLayout)
Vue.component('transparent-layout', TransparentLayout)
Vue.component('public-layout', PublicLayout)
Vue.component('index-layout', IndexLayout)

// Code blocks
Vue.component('CompanyUser',CompanyUser)
Vue.component('FullscreenButtons',FullscreenButtons)
Vue.component('FullscreenButtonsAbsolute',FullscreenButtonsAbsolute)
Vue.component('ToolbarLeftNavTitle',ToolbarLeftNavTitle)

let ws = 'wss://' + window.location.host + '/ws'
if (location.protocol !== 'https:') {
  ws = ws.replace("wss:", "ws:");
}

Vue.use(VueNativeSock, ws, {
  connectManually: true,
  store: store,
  format: 'json',
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: 6, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 10000, // (Number) how long to initially wait before attempting a new (1000)
})

Vue.config.productionTip = true
interceptorsSetup()

// Add cusom functions, use: this.$func.functionName(somedata)
Vue.prototype.$func = func

// Show snackbar, call with: this.$showMessage({ content: "Feil", color: "error"})
Vue.prototype.$showMessage =  function({ content, color}) {
    store.commit('showMessage', { content, color })
}  
// Return an XID from server
Vue.prototype.$newXID =  function() {
  return axios.get("/data/xid")
  .then(function(response) {
    return response.data
  })
  .catch(function (error) {
    return 'An error occured..' + error;
  })  
};
// Return an PO ID from server
Vue.prototype.$newPOID =  function() {
  return axios.get("/data/poid")
  .then(function(response) {
    return response.data
  })
  .catch(function (error) {
    return 'An error occured..' + error;
  })  
};

Vue.prototype.$fullscreenOpen = function() {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen();
  }    
}

Vue.prototype.$fullscreenClose = function() {
  if (!document.fullscreenElement) {
      //document.documentElement.requestFullscreen();
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
},

Vue.prototype.$goBack = function() {
  router.go(-1);
},

Vue.prototype.$colors = function() {
  return [
    '#F44336',
    '#E91E63',
    '#9C27B0',
    '#673AB7',
    '#3F51B5',
    '#2196F3',
    '#03A9F4',
    '#00BCD4',
    '#009688',
    '#4CAF50',
    '#8BC34A',
    '#CDDC39',
    '#FFEB3B',
    '#FFC107',
    '#FF9800',
    '#FF5722',
    '#795548',
    '#607D8B',
  ]
},

Vue.prototype.$formatDate = function(val, format = 'dd.mm.yyyy') {
  const d = new Date(val);
  if (d === 'Invalid Date') {return ''}
  if (format == 'dd.mm.yyyy') {return d.toLocaleDateString('ro-RO');}
  if (format == 'dd.mm') {return (d.toLocaleDateString('ro-RO')).slice(0,5);}
},

Vue.prototype.$formatNumber = function(val, digits) {
  if (val == null){
    val = 0
  }  
  if (Number.isNaN(val)) {
    val = 0
  }
  digits === 'undefined' ? 0 : digits
  // https://blog.abelotech.com/posts/number-currency-formatting-javascript/
  return (
    val
      .toFixed(digits) // always two decimal digits
      .replace('.', ',') // replace decimal point character with ,
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  ) // use . as a separator 
},

new Vue({
  vuetify,
  router,
  store,
  // watch: { navitems(newValue, oldValue) { console.log(newValue, oldValue); }},
  created: function() {
      // console.log(store.state.socket.isConnected);
      // console.log(store.state.isLoggedIn);
      // var self = this;
      // setTimeout(function(){ self.$store.commit("SET_LOGGEDIN")}, 3000);
      //store.watch((state) => {this.$store.getters.isLoggedIn})
      
      // Will fire on page reload
      if (this.$store.state.loggedIn) {
        this.$connect() 
      } 
  },
  render: h => h(App)
}).$mount('#app')
