var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"dense":"","headers":_vm.options,"items":_vm.data,"items-per-page":-1,"search":_vm.search,"hide-default-footer":"","group-by":_vm.groupby,"show-group-by":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('tr',_vm._l((props.headers),function(header,index){return _c('th',{key:index,staticClass:"text-right",staticStyle:{"border-bottom":"double rgba(0, 0, 0, 0.12)","border-left":"1px solid #EEEEEE"}},[(header.math == 'sum')?_c('span',[_c('span',{staticClass:"subtitle-2",staticStyle:{"height":"36px","color":"blue"}},[_vm._v(_vm._s(_vm.$formatNumber(_vm.summit(header.value))))])]):_vm._e()])}),0)]}},{key:"item.revenueam",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$formatNumber(item.revenueam)))])]}},{key:"item.pam",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$formatNumber(item.pam)))])]}},{key:"item.ram",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$formatNumber(item.ram)))])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }