<template>
  <div>

      <v-navigation-drawer 
        style="background: rgba(255, 255, 255, 0.5);" 
        v-model="drawer" :mini-variant.sync="mini" app floating expand-on-hover permanent>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-icon color="pink darken-3">mdi-flare</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Prosjekt</v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense nav>
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >          
          <v-list-item v-for="item in tabs" :key="item.title" link @click="getsection(item.ref)">
            <v-list-item-icon>
              <v-icon :color="item.color">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

    <v-main style="height: 100%; padding: 0px">
      <v-container elevation="1" fluid class="pt-2 pr-0" style="height: 100%">

        <v-sheet width="100%" height="100%" rounded class="pb-2">
          <!-- Top toolbar -->

          <v-toolbar rounded dense flat>
            <h3 class="float-left font-weight-medium grey--text text--darken-2 pt-n2">
                {{ sectionTitle }}
            </h3>
            <v-spacer></v-spacer>

          </v-toolbar>
          <v-divider></v-divider>

          <!-- Vertikale tabs -->
          <v-tabs vertical v-model="tab" dark background-color="purple lighten-2" active-class="purple">

            <!-- Tab elementer -->
            <v-tabs-items v-model="tab">

              <v-tab-item value="overview"><v-card><v-card-text>Oppsummering av det viktigste</v-card-text></v-card></v-tab-item>
              <v-tab-item value="daylog"><v-card><v-card-text>Daylog</v-card-text></v-card></v-tab-item>
              <v-tab-item value="handover"><v-card><v-card-text>Handover</v-card-text></v-card></v-tab-item>
              <v-tab-item value="calculus"><v-card><v-card-text>calculus</v-card-text></v-card></v-tab-item>
              <v-tab-item value="invoice"><v-card><v-card-text>invoice</v-card-text></v-card></v-tab-item>
              <v-tab-item value="tidsbanken"><v-card><v-card-text>tidsbanken</v-card-text></v-card></v-tab-item>
              <v-tab-item value="mobilization"><v-card><v-card-text>mobilization</v-card-text></v-card></v-tab-item>
              <v-tab-item value="equipment"><v-card><v-card-text>equipment</v-card-text></v-card></v-tab-item>
              <v-tab-item value="map"><v-card><v-card-text>map</v-card-text></v-card></v-tab-item>
              <v-tab-item value="requisition"><v-card><v-card-text>map</v-card-text></v-card></v-tab-item>
              <v-tab-item value="plan"><v-card><v-card-text>map</v-card-text></v-card></v-tab-item>

            </v-tabs-items>
          </v-tabs>

        </v-sheet>
      </v-container>
    </v-main>
  </div>
</template>

<script>
/*eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import requestData from "@/mixins/requestData.js";

// https://github.com/beatrizsmerino/vue-dinner-calculator/blob/master/js/app.js
export default {
  mixins: [requestData],
  components: {
  },
  created: function() {
  },
  mounted: function() {
  },
  data() {
    return {
      drawer: true,
      mini: true,
      dialog: false,
      panel: [],
      tab: null,
      selectedItem: undefined,
      sectionTitle: "Ingen seksjon valgt",      
      tabs: [
        {
          ref: "#overview",
          icon: "mdi-currency-eur",
          title: "Oversikt",
          color: "teal"
        },
        {
          ref: "#daylog",
          icon: "mdi-book-outline",
          title: "Daglig logg, fremdrift",
          color: "red",
        },
        {
          ref: "#handover",
          icon: "mdi-head-question-outline",
          title: "Overleveringsrapport",
          color: "green",
        },
        {
          ref: "#calculus",
          icon: "mdi-run",
          title: "Kalkyle og regnskap",
          color: "green",
        },
        {
          ref: "#invoice",
          icon: "mdi-ladder",
          title: "Kontrakt og VOR"
        },
        {
          ref: "#tidsbanken",
          icon: "mdi-account-hard-hat",
          title: "Tidsbanken - i dag og hist"
        },
        {
          ref: "#mobilization",
          icon: "mdi-hammer",
          title: "Mob og de-mob"
        },
        {
          ref: "#equipment",
          icon: "mdi-cog-outline",
          title: "Utstyrsplassering"
        },
        {
          ref: "#map",
          icon: "mdi-cog-outline",
          title: "Kart"
        },
        {
          ref: "#requisition",
          icon: "mdi-truck-outline",
          title: "Rekvisisjon - bestilling"
        },
        {
          ref: "#plan",
          icon: "mdi-progress-download",
          title: "Gantt - detaljert plan",
          color: "teal"
        },
      ],
    };
  },
  computed: {
  },
  methods: {
    getsection(ref) {
      this.tab = ref.substring(1);
      for (let i = 0; i < this.tabs.length; i++) {
        if (this.tabs[i].ref == ref) {
          this.sectionTitle = this.tabs[i].title;
          break;
        }
      }
    },
    closeDialog: function() {
      this.dialog = false;
      this.panel = undefined;
    },
  },
};
</script>