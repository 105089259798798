<template>
    <v-card>
      <v-toolbar flat color="pink darken-2" dark>
        <v-toolbar-title>One adm to rule them all</v-toolbar-title>
      </v-toolbar>
      <v-tabs background-color="pink lighten-4">

        <v-tab :href="`#Brukere`">
          <v-icon left>mdi-account-group-outline</v-icon>Brukere
        </v-tab>
        <v-tab :href="`#Leggtilbruker`">
          <v-icon left>mdi-account-outline</v-icon>Legg til bruker
        </v-tab>
        <v-tab :href="`#Tilganger`">
          <v-icon left>mdi-file-cabinet</v-icon>Tilganger
        </v-tab>
        <v-tab :href="`#FirmaNew`">
          <v-icon left>mdi-file-cabinet</v-icon>Opprett firma
        </v-tab>
        <v-tab :href="`#FirmaUpdate`">
          <v-icon left>mdi-file-cabinet</v-icon>Mine firma
        </v-tab>

        <v-tab-item value="Leggtilbruker" class="ma-2">
          <v-card class="mx-auto" max-width="500" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline mb-4">Legg til bruker</div>
                <v-list-item-subtitle>En bruker kan allerede være opprettet - for et annet firma. Søk først etter bruker, opprett ny om bruker ikke ble funnet.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-actions>
              <v-text-field v-model="searchUserAdd" :rules="[rules.required, rules.email]" label="Email adresse" filled dense></v-text-field>
              <v-btn class="ml-4 mb-4" @click="searchUser">Søk</v-btn>
            </v-card-actions>
            <v-list-item v-if="searchUserFound === 1" three-line>
              <v-list-item-content>
                <div class="overline mb-4">Bruker ikke funnet</div>
                <v-text-field filled dense single-line label="Fullt navn" v-model="userFullname" :rules="[rules.required, rules.counter]"></v-text-field>
                <v-text-field filled dense single-line label="Passord" v-model="userPassword" :rules="[rules.required, rules.counter]"></v-text-field>
                <v-list-item-subtitle>
                  <v-btn class="float-right mb-2" @click="createUser">Opprett bruker med tilgang til dette firma</v-btn>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="searchUserFound === 2" three-line>
              <v-list-item-content>
                <div class="overline mb-4">Bruker funnet, men uten tilgang til firmaet</div>
                <v-list-item-subtitle>{{ searchUserFoundUser.Fullname }}<v-btn class="float-right mb-2" @click="addExistingUserToCompany">Gi tilgang til dette firma</v-btn>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>

        </v-tab-item>

        <v-tab-item value="Brukere" class="ma-2">
          <v-row>
            <v-col>
              <v-btn @click="getUsers">Hent brukere</v-btn>
              <v-btn class="float-right" color="primary" @click="showSnackbar">Show snackbar</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card class="mt-4" outlined>
                <div class="overline ml-4">BRUKERE i <span class="font-weight-black red--text pl-1">{{ this.$store.state.activecompanyname }}</span></div>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <v-data-table :headers="usersHeaders" :items="users" :items-per-page="-1" hide-default-footer dense></v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item value="Tilganger" class="ma-2">
          <v-btn @click="getUserspermission">Hent tilganger</v-btn>
          <v-divider class="my-2"></v-divider>

          <v-row>
            <v-col cols="2">
              <v-card tile>
                <v-subheader>BRUKERE</v-subheader>
                <v-divider class="mx-2"></v-divider>
                <v-list dense>
                  <v-list-item-group v-if="permissions !== null" v-model="userSelected" color="primary">
                    <v-list-item v-for="(item, i) in permissions" :key="i">
                      <v-list-item-icon class="mr-2">
                        <v-icon left>mdi-account-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.Username"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>

            <v-col cols="6">
              <v-card tile>
                <v-subheader>TILGANGER</v-subheader>
                <v-divider class="mx-2"></v-divider>
                <v-card-text class="px-2">
                  <v-form v-if="permissions !== null && permissions[userSelected] !== undefined">
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Path</th>
                            <th class="text-left" style="width: 100px">Action</th>
                            <th class="text-left" style="width: 60px">Remove</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, i) in permissions[userSelected]
                              .Permissions" :key="i">
                            <td>
                              <v-text-field hide-details="auto" filled dense single-line :value="item.Path" v-model="item.Path" :id="`path-${i}`"></v-text-field>
                            </td>
                            <td>
                              <v-text-field hide-details="auto" filled dense single-line :value="item.Action" v-model="item.Action" :id="`action-${i}`"></v-text-field>
                            </td>
                            <td>
                              <v-btn @click="removePermission(i)" icon color="pink">
                                <v-icon>mdi-trash-can-outline</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-divider class="my-4"></v-divider>
                    <v-btn @click="newUserPermissionRow">Legg til rad</v-btn>
                    <v-btn color="primary" class="float-right" @click="saveUserPermissions">Lagre</v-btn>
                    <v-divider class="my-4"></v-divider>
                    <v-btn color="warning" @click="removeAllPermissions">Fjern all tilgang</v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="4">
              <v-card tile>
                <v-subheader>ACTIONS</v-subheader>
                <v-card-text>
                  <li>Read</li>
                  <li>Write</li>
                  <li>Lag som på "Sett inn linjer i tilbud" for hvert område med tilgang</li>
                </v-card-text>
              </v-card>
              <v-card tile>
                <v-subheader>PATHS</v-subheader>
                <v-card-text>
                  <li v-for="(page, ip) in pages" :key="ip">
                    {{ page.link }} || {{ page.title }}
                  </li>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item value="FirmaNew" class="ma-2">
          <v-card class="mx-auto" max-width="500" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline mb-4">Nytt firma</div>
                <v-text-field filled dense single-line label="Firmanavn" v-model="companyName" :rules="[rules.required, rules.counter]"></v-text-field>
                <v-list-item-subtitle>
                  <v-btn class="float-right mb-2" @click="createCompany">Opprett firma</v-btn>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-tab-item>

        <v-tab-item value="FirmaUpdate" class="ma-2">
          <v-btn @click="getCompanies">Hent firma</v-btn>
          <v-divider class="my-2"></v-divider>

          <v-row>
            <v-col cols="2">
              <v-card tile>
                <v-subheader>FIRMA</v-subheader>
                <v-divider class="mx-2"></v-divider>
                <v-list dense>
                  <v-list-item-group v-if="permissions !== null" v-model="userSelected" color="primary">
                    <v-list-item v-for="(item, i) in permissions" :key="i">
                      <v-list-item-icon class="mr-2">
                        <v-icon left>mdi-account-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.Username"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>

      </v-tabs>
    </v-card>
</template>

<style scoped>
::v-deep th {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
::v-deep td {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
</style>

<script>
/*eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import requestData from "@/mixins/requestData.js";

export default {
  mixins: [requestData],
  components: {},
  data() {
    return {
      pages: [],
      permissions: [],
      companies: [],
      users: [],
      usersHeaders: [
          { text: 'ID', value: 'ID' },
          { text: 'Fullt navn', value: 'Fullname' },
          { text: 'Email', value: 'Username' },
          { text: 'Siste login', value: 'LoginAt' },
      ],
      userSelected: undefined,
      userFullname: "",
      userPassword: "",
      searchUserAdd: "",
      searchUserFound: 0,
      searchUserFoundUser: {},
      companyName: "",
      rules: {
        required: value => !!value || "Obligatorisk.",
        counter: value => value.length <= 50 || "Maks 50 tegn",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Ugyldig email.";
        }
      }
    };
  },
  computed: {},
  methods: {
    showSnackbar() {
      this.$showMessage({ content: "Hello", color: "red" });
    },
    addExistingUserToCompany() {
      const params = new URLSearchParams();
      params.append("username", this.searchUserAdd);
      params.append("company", this.$store.state.activecompany);
      this.axios.put("/api/admin/user", params).then(
        response => {
          if (response.status == 201) {
            this.searchUserAdd = "";
            this.searchUserFound = false;
            this.searchUserFoundUser = {};
            this.$showMessage({
              content: "OK bruker gitt tilgang",
              color: "info"
            });
          }
        },
        error => {
          console.log(error);
          // success, info, warning, and error
          this.$showMessage({ content: error, color: "error" });
        }
      );
    },
    createUser() {
      const params = new URLSearchParams();
      params.append("company", this.$store.state.activecompany);
      params.append("username", this.searchUserAdd);
      params.append("fullname", this.userFullname);
      params.append("password", this.userPassword);
      this.axios.post("/api/admin/user", params).then(
        response => {
          console.log(response.status);
          this.$showMessage({ content: "Opprettet", color: "info" });
        },
        error => {
          this.$showMessage({ content: error, color: "error" });
        }
      );
    },
    createCompany() {
      const params = new URLSearchParams();
      /*
      params.append('company', this.$store.state.activecompany);      
      params.append('username', this.searchUserAdd);
      params.append('fullname', this.userFullname);
      params.append('password', this.userPassword);
      */
      params.append("companyname", this.companyName);
      this.axios.post("/api/admin/company", params).then(
        response => {
          console.log(response.status);
          this.$showMessage({ content: "Opprettet", color: "info" });
        },
        error => {
          this.$showMessage({ content: error, color: "error" });
        }
      );
    },
    searchUser() {
      this.searchUserFound = 0;
      this.searchUserFoundUser = {};
      this.axios
        .get("/api/admin/user", {
          params: {
            username: this.searchUserAdd,
            company: this.$store.state.activecompany
          }
        })
        .then(
          response => {
            // User not found at all - add ?
            if (response.status == 200) {
              this.searchUserFound = 1;
              this.$showMessage({ content: response.data, color: "success" });
            }
            // User found - give access ?
            if (response.status == 206 && response.data.length > 0) {
              this.searchUserFound = 2;
              this.searchUserFoundUser = response.data[0];
            }
            // User has access already
            if (response.status == 208) {
              this.$showMessage({ content: response.data, color: "info" });
            }
          },
          error => {
            this.$showMessage({ content: error, color: "error" });
          }
        );
    },
    getUsers() {
      var self = this;
      this.requestJSON2("/api/admin/users", {}).then(function(e) {
        self.users = e || [];
      });
    },
    getUserspermission() {
      var self = this;
      this.requestJSON2("/api/admin/permissions", {}).then(function(e) {
         self.permissions = e.sort((a, b) => a.Username.localeCompare(b.Username)) || [];
      });
      this.requestJSON2("/api/admin/pages", {}).then(function(e) {
        self.pages = e || [];
      });
    },
    getCompanies() {
      var self = this;
      this.requestJSON2("/api/admin/companies", {}).then(function(e) {
        self.companies = e || [];
      });
      this.requestJSON2("/api/admin/pages", {}).then(function(e) {
        self.pages = e || [];
      });
    },
    newUserPermissionRow() {
      if (
        this.permissions[this.userSelected].Permissions === undefined ||
        this.permissions[this.userSelected].Permissions === null
      ) {
        this.$set(this.permissions[this.userSelected], "Permissions", []);
      }
      this.permissions[this.userSelected].Permissions.push({
        Action: "",
        Path: ""
      });
    },
    removeAllPermissions() {
      const params = new URLSearchParams();
      params.append("company", this.$store.state.activecompany);
      params.append("permission", JSON.stringify(this.permissions[this.userSelected]));
      this.axios
        .post("/api/admin/userpermissionremoveall", params)
        .then(
          response => {
            console.log(response.status);
            this.$showMessage({ content: "Lagret", color: "info" });
          },
          error => {
            this.$showMessage({ content: error, color: "error" });
          }
        );
    },
    saveUserPermissions() {
      const params = new URLSearchParams();
      params.append("company", this.$store.state.activecompany);
      params.append(
        "permission",
        JSON.stringify(this.permissions[this.userSelected])
      );
      this.axios
        //.post("/api/admin/userpermission", this.permissions[this.userSelected])
        .post("/api/admin/userpermission", params)
        .then(
          response => {
            console.log(response.status);
            this.$showMessage({ content: "Lagret", color: "info" });
          },
          error => {
            this.$showMessage({ content: error, color: "error" });
          }
        );
    },
    removePermission(i) {
      this.permissions[this.userSelected].Permissions.splice(i, 1);
    }
  }
};
</script>