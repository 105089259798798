<template>
  <v-sheet :color="colors[model]" style="height: 100%">
  <v-container fluid ref="containerelement">
    <v-switch style="position: absolute; top: 5px; left: 50%; z-index: 100" v-model="cycle" label="Cycle" inset></v-switch>
    <v-carousel
      :cycle="cycle"
      interval="30000"
      hide-delimiters
      height="100%"
      v-if="gridready"
      v-model="model"
      show-arrows-on-hover
    >
      <v-carousel-item v-for="(item) in layout" :key="item.i" transition="fade-transition">
        <div style="background-color: #FAFAFA"><ToolbarLeftNavTitle :title="data[item.i].title" /></div>

        <graphic-kamishibai
          v-if="item.wtype === 'kamishibai'"
          :options="data[item.i].options"
          :data="data[item.i].data"
        ></graphic-kamishibai>

        <timeline-reservation-chart
          v-if="item.wtype === 'timelinebar'"
          :initheight="height"
          :initwidth="width"
          :options="timelineReservationOptions"
          :data="data[item.i].data"
        />

        <div v-if="item.wtype === 'datatable'">
          <graphic-datatable class="pa-2 pt-4" :options="data[item.i].options" :data="data[item.i].data" :groupby="data[item.i].parameters.groupby"></graphic-datatable>          
        </div>

      </v-carousel-item>
    </v-carousel>
  </v-container>
  </v-sheet>
</template>

<style scoped>
::v-deep .v-window__prev, ::v-deep .v-window__next {
    top: 85px;
    z-index: 101;
}
</style>

<script>
import requestData from "@/mixins/requestData.js";
import GraphicKamishibai from "@/components/GraphicKamishibai.vue";
import TimelineReservationChart from "@/components/GraphicTimelineReservation2.vue";
import GraphicDatatable from "@/components/GraphicDatatable.vue";

export default {
  mixins: [requestData],
  components: {
    "graphic-kamishibai": GraphicKamishibai,
    "timeline-reservation-chart": TimelineReservationChart,
    "graphic-datatable": GraphicDatatable,
  },
  created: function () {
    this.layout =
      JSON.parse(
        JSON.stringify(this.$store.getters.navbylink(this.$route.path).layout)
      ) || [];
  },  
  mounted: function () {
    var self = this;
    this.timer = setInterval(function(){
        //console.log (((Math.random() * 100).toFixed(2))+'%')
        self.requestData();
    }, 300000);      

    this.requestData();
    this.width = this.$refs.containerelement.clientWidth - 20;
    //this.height = window.innerHeight-50;
    this.timelineReservationOptions = {
        sorter: "startdate",
        chart: {
          xOffset: 180,
        },
        plot: {
          reservation: {
            rectangleHeight: 21,
            rectangleGap: 7,
          },
        },
        font: "15px Roboto",
      };
  },
  beforeDestroy () {
    this.cancelAutoUpdate();
  },  
  methods: {
    cancelAutoUpdate () {
        clearInterval(this.timer);
    },    
    requestData: function () {
      var self = this;
      const req = {
        id: self.$route.params.page,
        company: this.$store.state.activecompany,
        media: "json",
        format: "datatable",
      };
      this.requestJSON2("/api/" + this.$route.name, req)
        .then(function (e) {
          self.data = e;

          // Check if layout should be extended, by splitting pages
          for (const property in e) {

            // Has splitpages ?
            if (e[property].parameters.splitpages) {
              
              // Find key to split by
              const splitBy = e[property].parameters.splitpages

              // Get unique split-by values
              let unique = [...new Set(e[property].data.map(item => item[splitBy] ))];
              unique = unique.filter(function(x) {
                  return x !== undefined;
              });

              // Loop each split by value
              for (let i = 0; i < unique.length; i++) {

                // Extend layout if not already extended
                var found = false
                for (let j = 0; j < self.layout.length; j++) {
                  if (self.layout[j].i == property + '_' + i ) {
                    found = true
                    break
                  }
                }                
                if (!found) {self.layout.push({i: property + '_' + i, wtype: e[property].wtype})}

                // Clone widget object
                var cloneObj = Object.assign({}, e[property]);

                // Clone widget data
                const cloneData = [...cloneObj.data];

                // Extract data equals split-value
                let filtered = cloneData.filter(function(el){ 
                    return el[splitBy] == unique[i];
                });

                // Reset data in cloneobj
                cloneObj.data = []

                // Assign data in cloneobj
                cloneObj.data = filtered

                // Update title
                cloneObj.title = cloneObj.title + ' - ' + unique[i].substring(unique[i].indexOf('\\') + 1) 

                // Assign data to vue widget
                self.data[property + '_' + i] = cloneObj

              }
              // POP fisioned object from layout
              for (let j = 0; j < self.layout.length; j++) {
                if (self.layout[j].i == property) {
                  self.layout.splice([j], 1)
                  break
                }
              }                
              // POP fisioned object from widget data
              delete self.data[property];
            }
          }          
        })
        .then(function () {
          self.gridready = true;
        });
    },
  },
  data() {
    return {
      timer: '',
      width: 1000,
      height: 0,
      gridready: false,
      layout: [],
      data: {},
      timelineReservationOptions: {},
      model: 0,
      cycle: true,
    colors: [
      "purple lighten-4",
      "blue lighten-4",
      "pink lighten-4",
      "indigo lighten-4",
      "teal lighten-4",
      "purple lighten-4",
      "blue lighten-4",
      "pink lighten-4",
      "indigo lighten-4",
      "teal lighten-4",
      "purple lighten-4",
      "blue lighten-4",
      "pink lighten-4",
      "indigo lighten-4",
      "teal lighten-4",
    ],
    };
  },
};
</script>