<template>
  <v-app>
    <component v-bind:is="layout"></component>
  </v-app>
</template>

<script>
// Dynamic layout based on store
// https://itnext.io/anyway-heres-how-to-create-a-multiple-layout-system-with-vue-and-vue-router-b379baa91a05
// Not used: https://levelup.gitconnected.com/multiple-layouts-for-vue-spa-app-fafda6b2bfc7
const default_layout = "default-layout";

export default {
  computed: {
    layout() {
      return this.$route.meta.layout || default_layout;
    }
  },
  beforeMount: function() {
    /*
      let self = this;
			this.$options.sockets.onopen = function() {
				console.log("ws::open : connection established "+self.status);	
      };
      this.$options.sockets.onmessage = function(msg) {
        console.log("received: ", msg.data)
      }            
			this.$options.sockets.onerror = function (errorEvent) {
				console.log("WebSocket ERROR: " + JSON.stringify(errorEvent, null, 4));
      };
      */
  }
};
</script>
