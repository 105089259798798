var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pt-2",attrs:{"color":"white","rounded":""}},[_c('div',{staticClass:"float-right pr-2"},[(_vm.readat)?_c('span',{staticClass:"caption purple--text text--lighten-2"},[_vm._v("Data lest "+_vm._s(new Date(_vm.readat*1000).toLocaleDateString("nb-NO"))+" "+_vm._s(new Date(_vm.readat*1000).toLocaleTimeString("nb-NO")))]):_vm._e(),(_vm.refresher)?_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","small":"","color":"purple"},on:{"click":function($event){return _vm.refresh()}}},[_vm._v("Oppdater"),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-cloud-refresh")])],1):_vm._e()],1),_c('dropdown-filters',{attrs:{"data":_vm.widget.data},on:{"dropdownFiltersChanged":_vm.newFiltersFromChild}}),_c('v-data-table',{attrs:{"headers":_vm.widget.options,"items":_vm.filteredItems,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.project",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.project)),_c('span',{staticClass:"float-right"},[_c('v-chip',{attrs:{"small":"","label":"","color":"blue lighten-5"}},[_vm._v(_vm._s(item.segm))]),_vm._v(" "),_c('v-chip',{attrs:{"small":"","label":"","color":"blue lighten-5"}},[_vm._v(_vm._s(item.pm))])],1),_c('v-progress-linear',{attrs:{"background-color":"blue lighten-4","color":"blue lighten-2","value":item.progr}})]}},{key:"item.calcint",fn:function(ref){
var item = ref.item;
return [(item.calcint > 0)?_c('span',[_c('v-badge',{attrs:{"offset-y":"12","color":"lime","content":item.calcint}},[_c('v-icon',{attrs:{"color":"light-green darken-1"}},[_vm._v("mdi-emoticon-cool-outline")])],1)],1):_c('span',[_c('v-icon',{attrs:{"color":"pink lighten-2"}},[_vm._v("mdi-emoticon-frown-outline")])],1)]}},{key:"item.equpint",fn:function(ref){
var item = ref.item;
return [(item.equpint > 0)?_c('span',[_c('v-badge',{attrs:{"offset-y":"12","color":"lime","content":item.equpint}},[_c('v-icon',{attrs:{"color":"light-green darken-1"}},[_vm._v("mdi-emoticon-cool-outline")])],1)],1):_c('span',[_c('v-icon',{attrs:{"color":"pink lighten-2"}},[_vm._v("mdi-emoticon-frown-outline")])],1)]}},{key:"item.handint",fn:function(ref){
var item = ref.item;
return [(item.handint > 0)?_c('span',[_c('v-badge',{attrs:{"offset-y":"12","color":"lime","content":item.handint}},[_c('v-icon',{attrs:{"color":"light-green darken-1"}},[_vm._v("mdi-emoticon-cool-outline")])],1)],1):_c('span',[_c('v-icon',{attrs:{"color":"pink lighten-2"}},[_vm._v("mdi-emoticon-frown-outline")])],1)]}},{key:"item.ordlnint",fn:function(ref){
var item = ref.item;
return [(item.ordlnint > 0)?_c('span',[_c('v-badge',{attrs:{"offset-y":"12","color":"lime","content":item.ordlnint}},[_c('v-icon',{attrs:{"color":"light-green darken-1"}},[_vm._v("mdi-emoticon-cool-outline")])],1)],1):_c('span',[_c('v-icon',{attrs:{"color":"pink lighten-2"}},[_vm._v("mdi-emoticon-frown-outline")])],1)]}},{key:"item.evalint",fn:function(ref){
var item = ref.item;
return [(item.evalint > 0)?_c('span',[_c('v-badge',{attrs:{"offset-y":"12","color":"lime","content":item.evalint}},[_c('v-icon',{attrs:{"color":"light-green darken-1"}},[_vm._v("mdi-emoticon-cool-outline")])],1)],1):_c('span',[_c('v-icon',{attrs:{"color":"pink lighten-2"}},[_vm._v("mdi-emoticon-frown-outline")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }