<template>
   <v-container>
      <v-sheet class="ma-5" color="orange lighten-2">
         <input style = "width: 99%" accept=".csv" label="Velg og last opp CSV fil." type="file" ref="file" v-on:change="handleFileUpload()"/>
      </v-sheet>
    <v-btn :disabled="disabled" color="blue-grey" class="ma-2 white--text" v-on:click="submitFile()">
      Upload
      <v-icon right dark>mdi-cloud-upload</v-icon>
    </v-btn>


   </v-container>
</template>

<script>
  export default {
    data(){
      return {
        file: '',
        disabled: true
      }
    },
    methods: {
      /* Submits the file to the server */
      submitFile(){
            var self = this;
            let formData = new FormData();
            formData.append('file', this.file);
            formData.append('requester', this.$route.name);
            formData.append('company', this.$store.state.activecompany);
            this.axios.post(
               '/api/equipment/fileupload', formData, { headers: { 'Content-Type': 'multipart/form-data' } }
            ).then(function(){
               self.$showMessage({ content: "Fil lastet opp!", color: "info"})
            })
            .catch(function(){
               self.$showMessage({ content: "Noe gikk galt", color: "error"})
            });
      },
      /* Handles a change on the file upload */
      handleFileUpload(){
         this.file = this.$refs.file.files[0];
         if (this.file != null) {
            this.disabled = false
         } else {
            this.disabled = true
         }
      }
    }
  }
</script>