<template>
        <v-row>
          <!-- Kundeliste -->
          <v-col cols="3">
            <v-card flat>
              <v-card-title class="red--text text--darken-3"> {{ customer.val }} </v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Søk kunde"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-text>
              <v-card-text>
                <v-data-table
                  dense
                  height="70vh"
                  :headers="customersHeaders"
                  :items="customers"
                  :items-per-page="-1"
                  :search="search"
                  hide-default-footer
                  @click:row="clickCustomer"
                ></v-data-table>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="5">
            <!-- Kontakter -->
            <v-card flat>
              <v-card-title> Kontaktinfo </v-card-title>

              <v-list dense three-line>
                <v-list-item v-for="(contact, i) in contacts" :key="i">
                  <v-list-item-content>
                    <v-list-item-title>{{ contact.val }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ contact.email }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ contact.phone }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-subtitle>
                      {{ contact.position }}
                    </v-list-item-subtitle>

                    <v-row align="center" class="pt-3 pr-2">
                      <v-btn
                        icon
                        color="primary"
                        :href="`mailto:${contact.email}`"
                        target="_blank"
                      >
                        <v-icon>mdi-email-outline</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        color="primary"
                        :href="`https://teams.microsoft.com/l/chat/0/0?users=${contact.email}`"
                        target="_blank"
                      >
                        <v-icon>mdi-microsoft-teams</v-icon>
                      </v-btn>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-card-title> Enhetsregisteret </v-card-title>
              <v-list dense>
                <!-- Adresse -->
                <v-list-item
                  v-for="(value, propertyName, index) in offAdr"
                  :key="index"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{
                      camelCaseTransform(propertyName)
                    }}</v-list-item-title>
                    <v-list-item-subtitle> {{ value }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <!-- Regnskap -->
              <v-divider></v-divider>
              <v-card-title> Regnskapsregisteret<v-spacer></v-spacer><v-btn v-if="hasOrgnr" small outlined color="indigo" @click="requestCreditreport">Kredittrapport</v-btn></v-card-title>
                <v-list-item v-for="(value, key) in offReg" :key="key">
                  <v-list-item-content>
                    <v-list-item-title>{{key}}</v-list-item-title>
                    <v-list-item-subtitle> {{ $formatNumber(value) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>                

              </v-list>
              <!--Styret, Regnskapstall, Eiere, Kunngjøringer, Kart - adresse-->
            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card flat>
              <v-card-title>Aktivitet</v-card-title>
              <v-card-text>
                <!--
                Prosjekter med graf størrelse og tidslinje Tap/vunnet i % av
                forespørsler, Tilbud -
                <v-data-table
                  style="background: rgba(255, 255, 255, 0.5)"
                  dense
                  :headers="headers"
                  :items="bids"
                  :items-per-page="-1"
                  :search="search"
                  hide-default-footer
                ></v-data-table>
                -->

                <v-timeline align-middle dense>
                  <v-timeline-item v-for="(item, index) in bids" :key="index" :color="item.color" small>
                    <v-row class="pt-1">
                      <v-col cols="3">
                        <div class="subtitle-2">{{ item.yrint }}</div>
                        <div>{{ item.bidstatus }}</div>
                      </v-col>
                      <v-col>
                        <div class="subtitle-2">{{ item.description }}</div>
                        <div>{{ $formatNumber(item.revenueam) }}</div>
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                </v-timeline>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
</template>



<script>
/*
Prosjekter, vunnet, tapt, forespørsler etc (kalkulert margin vs oppnådd margin) (tags for valg)
Kontaktpersoner
Notater
Kartposisjon (basert på org nr)
Adresse (basert på org nr)
Lønnsomhet / brreg
Eiere
Styre
Logg
*/
import requestData from "@/mixins/requestData.js";

export default {
  mixins: [requestData],
  mounted: function () {
    //http://localhost:8090/api/bid/editable?id=bq9njvjipt33qs9297dg&company=bqf0kajipt37o10v29jg&format=editable&media=json
    this.requestCustomers();
  },
  methods: {
    reset: function () {
      this.bids = [];
      this.contacts = [];
      this.customer = {};
      this.offAdr = {};
      this.offSign = {};
      this.offReg = {};
      this.hasOrgnr = false;
    },
    camelCaseTransform: function (str) {
      return this.$func.CamelcaseToSentence(str);
      //return str
    },
    requestCustomers: function () {
      var self = this;
      this.requestJSON2("/api/bid/component", {
        id: "bq9nhlbipt33palvu690",
      }).then(function (e) {
        const resp = e[Object.keys(e)[0]];
        self.customers = resp || [];
        //console.log(resp);
      });
    },
    requestCreditreport: function () {
      this.requestJSON2("/api/info/kreditt/" + this.customer.key, {})
        .then(function (e) {
          window.open(e["kreditt"], '_blank', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no')
      });
    },
    requestCustomer: function () {
      // Kontakter
      var self = this;
      this.requestJSON2("/api/bid/component", {
        id: "bqirj0ript31tabngf00",
        scope: "customer",
        contactid: this.customer.xid,
      }).then(function (e) {
        const resp = e[Object.keys(e)[0]];
        self.contacts = resp || [];
      });

      // Tilbud
      this.requestJSON2("/api/bid/component", {
        id: "c41bj7g6n88p8g2ckr40",
        contactid: this.customer.xid,
      }).then(function (e) {
        const resp = e[Object.keys(e)[0]];
        resp.forEach(function (item, index) {
          if (item["bidstatus"] == "7. Salg") {
            resp[index]["color"] ="green"
          }
          else if (item["bidstatus"] == "8. Tapt" || item["bidstatus"] == "9. Utgått") {
            resp[index]["color"] ="red"
          } else {
            resp[index]["color"] ="yellow";
          }
        });        
        self.bids = resp || [];
      });

      // Regnskapsregisteret
      if (this.customer.key.length == 9) {        
        this.requestJSON2("/api/info/brreg/" + this.customer.key, {})
          .then(function (response) {
            var data = {}
            //if (response[0].hasOwnProperty("regnskapsperiode")) {data["Periode"] = response[0]["regnskapsperiode"]["fraDato"] + ' - ' + response[0]["regnskapsperiode"]["tilDato"];}
            if (response[0].hasOwnProperty("resultatregnskapResultat")) {data["Omsetning"] = response[0]["resultatregnskapResultat"]["driftsresultat"]["driftsinntekter"]["sumDriftsinntekter"];}
            if (response[0].hasOwnProperty("resultatregnskapResultat")) {data["Driftsresultat"] = response[0]["resultatregnskapResultat"]["driftsresultat"]["driftsresultat"];}
            if (response[0].hasOwnProperty("resultatregnskapResultat")) {data["Årsresultat"] = response[0]["resultatregnskapResultat"]["aarsresultat"];}
            if (response[0].hasOwnProperty("eiendeler")) {data["Eiendeler"] = response[0]["eiendeler"]["sumEiendeler"];}
            if (response[0].hasOwnProperty("egenkapitalGjeld")) {data["Egenkapital"] = response[0]["egenkapitalGjeld"]["egenkapital"]["sumEgenkapital"];}
            if (response[0].hasOwnProperty("egenkapitalGjeld")) {data["Gjeld"] = response[0]["egenkapitalGjeld"]["gjeldOversikt"]["sumGjeld"];}
            //["journalnr", "id", "oppstillingsplan", "virksomhet", "valuta", "avviklingsregnskap", "regnkapsprinsipper", "regnskapstype", "revisjon"].forEach((e) => delete response[0][e])
            self.offReg = data
          });

      // Enhetsregisteret
        this.axios
          .get(
            "https://data.brreg.no/enhetsregisteret/api/enheter/" +
              this.customer.key
          )
          .then(function (response) {
            response.data["Adresse"] = response.data.hasOwnProperty(
              "forretningsadresse"
            )
              ? response.data["forretningsadresse"]["adresse"].join(" ") +
                ", " +
                response.data["forretningsadresse"]["postnummer"] +
                " " +
                response.data["forretningsadresse"]["poststed"]
              : "";
            response.data["Næringskode"] = response.data.hasOwnProperty(
              "naeringskode1"
            )
              ? response.data["naeringskode1"]["beskrivelse"] + ". "
              : "";
            response.data["Næringskode"] += response.data.hasOwnProperty(
              "naeringskode2"
            )
              ? response.data["naeringskode2"]["beskrivelse"] + ". "
              : "";
            response.data["Næringskode"] += response.data.hasOwnProperty(
              "naeringskode3"
            )
              ? response.data["naeringskode3"]["beskrivelse"] + ". "
              : "";
            [
              "postadresse",
              "frivilligMvaRegistrertBeskrivelser",
              "institusjonellSektorkode",
              "maalform",
              "organisasjonsform",
              "registrertIForetaksregisteret",
              "registrertIFrivillighetsregisteret",
              "registrertIMvaregisteret",
              "registrertIStiftelsesregisteret",
              "_links",
              "naeringskode1",
              "naeringskode2",
              "naeringskode3",
              "forretningsadresse",
              "underTvangsavviklingEllerTvangsopplosning",
              "konkurs",
              "underAvvikling",
            ].forEach((e) => delete response.data[e]);
            self.offAdr = response.data;
            self.hasOrgnr = true;
          });
      }
      // Signatur
      /*
      if (this.customer.key.length == 9) {
        this.axios
          .get(
            'https://data.brreg.no/fullmakt/enheter/'+this.customer.key+'/signatur', {
              headers: {'Accept': 'application/json;charset=UTF-8'}             
            }
          )
          .then(function (response) {
          self.offSign = response.data;
          });
      }
      */
    },
    clickCustomer(value) {
      this.reset();
      this.customer = value;
      this.requestCustomer();
    },
  },
  data() {
    return {
      widgets: {},

      search: "",
      offAdr: {},
      offReg: {},
      offSign: {},
      hasOrgnr: false,
      customer: {},
      customers: [],
      contacts: [],
      bids: [],
      customersHeaders: [{ text: "Kunde", value: "val" }],
      headers: [
        { text: "År", value: "yrint" },
        { text: "Status", value: "bidstatus" },
        { text: "Beskrivelse", width: "200", value: "description" },
        { align: "end", text: "Omsetning", value: "revenueam" },
      ],
    };
  },
};
</script>
