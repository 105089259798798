<template>
      <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" width="290px" persistent>
        <template v-slot:activator="{ on }">
          <v-text-field dense outlined v-model="date" label="År og måned" readonly v-on="on"></v-text-field>
        </template>
        <v-date-picker v-model="date" type="month">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
          <v-btn text color="primary" @click="$refs.dialog.save(date)">OK</v-btn>
        </v-date-picker>
      </v-dialog>
</template>

<script>
export default {
  methods: {
    SET_YRPR(val) {
      this.$store.commit('SET_YRPR', val)
    }
  },   
  data: () => ({
    // date: new Date().toISOString().substr(0, 7),
    modal: false
  }),
 computed: {
        date:{
          get:function(){
            return this.$store.state.active.yrpr;  
          }, 
          set:function(newValue){
            this.$store.commit('SET_YRPR',newValue);
          },
        }
    },  
};
</script>